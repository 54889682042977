import React, { memo, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { FilePond, registerPlugin } from "react-filepond";
import FileUploadValidate from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
//components
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//icons
import { BsExclamationCircleFill } from "react-icons/bs";
//redux
import { useDispatch } from "react-redux";
import { baseURL } from "service/HttpService";
import { getEquipmentTypeList, onGetExcelMappingColumns, onGetFetchedData, onGetImportEquipmentDatafields, onGetWorksheets, setExcelMappingColumn, setUploadedExcels, setWorksheets } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { useAppSelector } from "redux/hooks";


interface ImportEquipmentPropType {
	showImport: boolean;
	onHideModal?: () => void;
	onFetchedData?: (excelDetails, bodyInfo) => void;
	selectedEuipmentType?: any;
	selectedEquipmentGroup?: any;
	selectedTask?: any;
}
const ImportEquipment: React.FC<ImportEquipmentPropType> = (props) => {
	registerPlugin(FileUploadValidate);
	const { showImport, onHideModal = () => { }, selectedEuipmentType, selectedEquipmentGroup, selectedTask, onFetchedData = () => { } } = props;
	const importForm = useRef<any>(null);
	const [excelDetails, setExcelDetails] = useState<any>({ uploadedId: "", sheetIndex: "", classId: "", sheetName: "" });
	const [equipmentDBColumns, setEquipmentDBColumns] = useState<any>([]);
	const [datafields, setDatafields] = useState<any>([]);
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [message, setMessage] = useState<string>("");

	const dispatch = useDispatch<any>();
	const workSheets = useAppSelector((state) => state.equipment.worksheets);
	const equipmentTypeList = useAppSelector((state) => state.equipment.equipmentSpecificLists);
	const equipmentDatafields = useAppSelector((state) => state.equipment.importEquipmentDatafields);
	const excelMappingColumns = useAppSelector((state) => state.equipment.excelMappingColumns);

	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			workSheet: "",
			equipementSpecific: "",
		},
	});

	const formSubmitHandler = (data: any) => {
		importForm.current.requestSubmit();
	};

	useEffect(() => {
		if (excelDetails?.uploadedId) {
			dispatch(onGetWorksheets(excelDetails?.uploadedId));
		}
	}, [excelDetails?.uploadedId]);

	useEffect(() => {
		dispatch(getEquipmentTypeList(false));
	}, [])

	useEffect(() => {
		if (equipmentTypeList) {
			let type = "";
			if (selectedEuipmentType && selectedEuipmentType !== "All") type = selectedEuipmentType;
			setValue("equipementSpecific", type);
			setExcelDetails((dt) => ({ ...dt, classId: type }));
		}
	}, [selectedEuipmentType, equipmentTypeList]);

	useEffect(() => {
		if (excelDetails.classId && excelDetails.sheetIndex) {
			const body = {
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				UniqueEquipmentClassId: excelDetails.classId,
				EquipmentId: 0,
				UniquePanelId: selectedEquipmentGroup,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			};
			dispatch(onGetImportEquipmentDatafields(body, excelDetails.classId));
			dispatch(onGetExcelMappingColumns(excelDetails.classId, excelDetails.uploadedId, excelDetails.sheetIndex,setMessage,setDisplayInfoModal));
		}
	}, [excelDetails.sheetIndex, excelDetails.classId]);

	useEffect(() => {
		if (excelMappingColumns) {
			let columns = JSON.parse(JSON.stringify(excelMappingColumns));
			columns?.forEach(element => {
				if (element?.uniqueDataFieldId)
					element.checked = true;
				else {
					element.checked = false;
					element.uniqueDataFieldId = "";
				}
			});
			const excelColumns = [...columns].sort((a, b) => a.excelColumnIndex - b.excelColumnIndex);
			setEquipmentDBColumns([...excelColumns]);
		}
	}, [excelMappingColumns])

	useEffect(() => {
		if (equipmentDatafields?.length) {
			let fields = JSON.parse(JSON.stringify(equipmentDatafields));
			fields.splice(0, 0, { uniqueDataFieldId: "", dataFieldName: "Not Selected" });
			setDatafields([...fields])
		}
	}, [equipmentDatafields])

	const onExcelChecked = (checked, index) => {
		equipmentDBColumns[index].checked = checked;
		if (!checked)
			equipmentDBColumns[index].uniqueDataFieldId = "";
		setEquipmentDBColumns([...equipmentDBColumns]);
	};

	const onTechAdvanceSelect = (value, index) => {
		equipmentDBColumns[index].uniqueDataFieldId = value;
		setEquipmentDBColumns([...equipmentDBColumns]);
	};

	const onFetchData = () => {
		const checkedColumn = equipmentDBColumns.filter((item) => item.checked && item.uniqueDataFieldId);
		if (checkedColumn?.length) {
			const removedDataSets = checkedColumn?.map(({ checked, excelColumnIndex, mappings, ...rest }: any) => {
				return rest;
			});

			let duplicates;
			for (let i = 0; i < removedDataSets?.length; i++) {
				const items = removedDataSets?.filter((item) => item?.uniqueDataFieldId === removedDataSets[i].uniqueDataFieldId);
				if (items?.length > 1) {
					const filter = equipmentDatafields?.filter((item) => item?.uniqueDataFieldId === items[0]?.uniqueDataFieldId);
					duplicates = filter[0]?.dataFieldName;
					break;
				}
			}
			if (duplicates) {
				setDisplayInfoModal(true);
				setMessage(`${duplicates} selected more than once`);
				return;
			}

			const mandatoryFields = equipmentDatafields?.filter((item) => item.isMandatory);
			const results = mandatoryFields?.filter(({ uniqueDataFieldId: id1 }) => !removedDataSets?.some(({ uniqueDataFieldId: id2 }) => id2 === id1))
			
			if (results?.length) {
				setDisplayInfoModal(true);
				if (results?.length > 1)
					setMessage(`Mandatory field(s) ${results.map((item) => { return "'" + item.dataFieldName + "'" }).join(', ')} are not mapped`);
				else
					setMessage(`Mandatory field '${results[0]?.dataFieldName}' is not mapped`);
				return;
			}

			let body = {
				columnDetails: removedDataSets,
				uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				WorksheetId: excelDetails?.sheetName,
			}

			dispatch(onGetFetchedData(excelDetails.classId, excelDetails.uploadedId, excelDetails.sheetIndex, body,setMessage,setDisplayInfoModal));
			onFetchedData(excelDetails, body);
		} else {
			setDisplayInfoModal(true);
			setMessage(`No Field (s) are selected`);
		}
	}

	const onRemoveFile = () => {
		onDataReset();
	}

	const onDataReset = () => {
		setEquipmentDBColumns([]);
		setExcelDetails({ uploadedId: "", sheetIndex: "", classId: "", sheetName: "" });
		dispatch(setUploadedExcels(""));
		dispatch(setWorksheets(""));
		dispatch(setExcelMappingColumn(""));
		reset();
	}

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && (
				<span className="tooltip-text">
					<BsExclamationCircleFill />
					{errors[name].message}
				</span>
			)
		);
	};

	const ExcelFieldsTemplate = (rowData, options) => {

		return (
			<div className="grid-item-col select">
				<div className="field-checkbox">
					<Checkbox inputId="binary" checked={rowData.checked} onChange={(e) => onExcelChecked(e.checked, options?.rowIndex)} />
					<label htmlFor="binary">{rowData.excelFieldName}</label>
				</div>
			</div>
		);
	};

	const TechAdvanceFields = (rowData, options) => {

		return (
			<div className="field mb-0">
				<div className="p-float-label grid-item-col select">
					<Dropdown
						inputId="workSheet"
						value={rowData.uniqueDataFieldId}
						onChange={(e) => onTechAdvanceSelect(e.value, options?.rowIndex)}
						options={datafields}
						optionLabel="dataFieldName"
						optionValue="uniqueDataFieldId"
						className={classNames('w-100',)}
					/>
				</div>
			</div>

		);
	};

	const renderFooter = () => {
		return (
			<div>
				<div>
				<Button label="Cancel" className="cancel-btn" onClick={() => { onHideModal(); onRemoveFile() }} />
					<Button
						label="Fetch Data"
						className="save-btn"
						onClick={formSubmitHandler}
					/>
				</div>
			</div>
		);
	};

	return (
		<Dialog
			header={"Import Equipment"}
			visible={showImport}
			onHide={() => { onHideModal(); onRemoveFile() }}
			breakpoints={{ "960px": "75vw" }}
			// style={style}
			footer={renderFooter()}
			className="add-overlay import-equipment"
		>
			<form onSubmit={handleSubmit(onFetchData)} ref={importForm}>
				<div className="row px-2 pt-1">
					<div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
						<div className="left-sider-label upload-left">
							<div className="p-float-label">
								<p className="label">Excel File</p>
							</div>
						</div>
						<div className="right-side-field">
							<FilePond
								allowMultiple={false}
								maxFiles={1}
								server={{
									process: {
										url: `${baseURL}api/v1/common/files/upload`,
										method: 'POST',
										withCredentials: false,
										onload: (response) => {
											const res = JSON.parse(response);
											if (res.status === "done") {
												setExcelDetails((dt) => ({ ...dt, uploadedId: res.data.uniqueDirectoryId[0] }));
											}
											return response.key
										},
										onerror: (response) => response.data,
										ondata: (formData) => formData
									},
								}}
								name="files"
								credits={false}
								labelIdle="Click to choose a file or drag and drop a file"
								labelFileLoading=""
								labelTapToUndo=""
								labelFileProcessing=""
								labelFileProcessingComplete=""
								labelFileProcessingAborted=""
								labelFileProcessingError=""
								labelButtonAbortItemProcessing=""
								labelButtonUndoItemProcessing=""
								labelButtonRetryItemProcessing=""
								labelButtonProcessItem=""
								labelTapToCancel=""
								labelFileRemoveError=""
								labelTapToRetry=""
								allowFileTypeValidation={true}
								onremovefile={onRemoveFile}
								acceptedFileTypes={["text/xml", "application/xlsx", "text/plain", "text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
							/>
						</div>
					</div>
				</div>

				<div>
					<div className="field col-12 md:col-4">
						<span className="p-float-label">
							<Controller
								name="workSheet"
								control={control}
								rules={{ required: "Select a Worksheet." }}
								render={({ field, fieldState }) => (
									<Dropdown
										inputId="workSheet"
										className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
										{...field}
										value={field.value}
										onChange={(e) => {
											field.onChange(e.value);
											const filtered = workSheets?.filter((item) => item.sheetIndex === e.value);
											setExcelDetails((dt) => ({ ...dt, sheetIndex: e.value, sheetName: filtered?.[0]?.sheetName }));
										}}
										options={workSheets}
										optionLabel="sheetName"
										optionValue="sheetIndex"
									/>
								)}
							/>
							<label className="mandatory" htmlFor="inputType">
								Worksheet
							</label>
							{getFormErrorMessage("workSheet")}
						</span>
					</div>
					<div className="field col-12 md:col-4">
						<span className="p-float-label">
							<Controller
								name="equipementSpecific"
								control={control}
								rules={{ required: "Select an Equipment Type." }}
								render={({ field, fieldState }) => (
									<Dropdown
										inputId="equipementSpecific"
										className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
										{...field}
										value={field.value}
										onChange={(e) => {
											field.onChange(e.value);
											setExcelDetails((dt) => ({ ...dt, classId: e.value }));
										}}
										options={equipmentTypeList}
										optionLabel="equipmentClassName"
										optionValue="uniqueEquipmentClassId"
									/>
								)}
							/>
							<label className="mandatory" htmlFor="equipementSpecific">
								Equipment Type
							</label>
							{getFormErrorMessage("equipementSpecific")}
						</span>
					</div>
					<div className="custom-table">
						<DataTable dataKey="excelColumnIndex" className="datagrid-template" value={equipmentDBColumns}>
							<Column header="Excel Fields" body={ExcelFieldsTemplate}></Column>
							<Column header="Tech Advance + Fields" body={TechAdvanceFields}></Column>
						</DataTable>
					</div>
				</div>
			</form>
			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={ <p className="content">
                {message.includes(':')
                    ? message.split(':').map((line, index) => (
                        <p key={index}>
                            {line}
                            <br />
                        </p>
                    ))
                    : message}
            </p>}
			/>
		</Dialog>
	);
};

export default memo(ImportEquipment);
