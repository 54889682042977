import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { ListGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { BsExclamationCircleFill } from "react-icons/bs";

import { ScrollPanel } from "primereact/scrollpanel";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { showUI } from "service/GeneralUtility";

import { UploadPlugin } from "./ManageLinks/ManagePlugin/UploadPlugin";
import InformationBox from "../Message/InformationBox/InformationBox";

import { getPluginFilePath, getPluginList, getQrCode, onPluginSendMail, setPluginFilePath, setUploadedPlugin } from "redux/common/pluginSlice";
import { onDownloadPluginFile, setIsDownloadInProgress } from "redux/common/commonSlice";
import { ProgressSpinner } from "primereact/progressspinner";
import { OverlayPanel } from "primereact/overlaypanel";

interface IPlugin {
  showModal: boolean,
  setShowModal: (state: boolean) => void,
}

export const Plugins: React.FC<IPlugin> = ({
  showModal = true,
  setShowModal = () => { },
}) => {

  const pluginList = useAppSelector((state) => state.plugin.pluginList);
  const pluginFilePath = useAppSelector((state) => state.plugin.pluginFilePath);
  const uploadedPluginData = useAppSelector((state) => state.plugin.uploadedPlugin);
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
  const isDownloadInProgress = useAppSelector((state) => state.common.isDownloadInProgress);
  const [pluginData, setPluginData] = useState([]);
  const [showUploadPlugin, setShowUploadPlugin] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPlugin, setCurrentPlugin] = useState<any>();
  const sendMailForm = useRef<any>(null);
  const qrcode = useAppSelector((state) => state.plugin.qrcode);
  const [copySuccess, setCopySuccess] = useState<any>('');
  const [iosImage, setIosImage] = useState<any>("");
  const [androidImage, setAndroidImage] = useState<any>("");
  const [checked, setChecked] = useState<boolean>(true);

  const iosQrCoderef = useRef<any>(null);
  const androidQrCoderef = useRef<any>(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      email: ""
    },
  });


  useEffect(() => {
    dispatch(getPluginList())
  }, [])

  useEffect(() => {
    if (pluginList) {
      setPluginData(pluginList)
      dispatch(getQrCode(setMessage,setDisplayInfoModal));
    }
  }, [pluginList]) 

  useEffect(() => {
    if (qrcode?.qrCodes?.length > 0) {
      qrcode?.qrCodes?.map((item: any) => {
        if (item?.platForm == "iOS") {
          setIosImage(item)
        }
        else if (item?.platForm == "Android") {
          setAndroidImage(item)
        }
      })
    }
  }, [qrcode])

  const copyToClipBoard =  (platform) => {
    try {
      if (platform == "android") {
         navigator.clipboard.writeText(androidImage?.link);
        setCopySuccess('Copied!');
        
      }
      else {
         navigator.clipboard.writeText(iosImage?.link);
        setCopySuccess('Copied!');
      }
      setChecked(false)
      setTimeout(() => {
          setChecked(true)    
        }, 1500);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
    return "copy";
  };

  useEffect(() => {
    if (uploadedPluginData) {
      let pluginInfo = JSON.parse(JSON.stringify(pluginData))
      let currentIndex = pluginInfo.findIndex((item: any) =>item.id === currentPlugin.id);
      const newPluginlist: any = pluginInfo.filter((item: any) =>item.id !== currentPlugin.id);
      newPluginlist.splice(currentIndex, 0, uploadedPluginData);
      setPluginData(newPluginlist)
      dispatch(setUploadedPlugin(""))
    }
  }, [uploadedPluginData])

  useEffect(() => {
    if (pluginFilePath) {
      dispatch(setIsDownloadInProgress(true));
      dispatch(onDownloadPluginFile(pluginFilePath?.id, pluginFilePath?.fileName, pluginFilePath?.filePath, setMessage, setDisplayInfoModal));
      dispatch(setPluginFilePath(""));
    }
  }, [pluginFilePath])

  const onCloseClick = () => {
    setShowModal(false)
  }

  const onPluginUploadClick = (item) => {
    setCurrentPlugin(item)
    setShowUploadPlugin(true)
  }

  const onPluginDownloadClick = (item) => {
    if(!isDownloadInProgress){
      
      let isTapBridge:any;
      let isTapAppPro:any;
      if(item.plugin.toLowerCase() === 'tap bridge'){
        isTapBridge=true;
        isTapAppPro = false;
      }
     /* else if(item.plugin.toLowerCase() === 'tap app'){
        isTapBridge=false;
        isTapAppPro = false;
      }*/
      else if(item.plugin.toLowerCase() === 'tap pro'){
        isTapBridge=false;
        isTapAppPro = true;
      }
      
      dispatch(getPluginFilePath(item.id,item.countryId,isTapBridge,isTapAppPro, setMessage, setDisplayInfoModal))
    } else {
      setMessage("Download is in progress, Please wait");
      setDisplayInfoModal(true);
     }

  }


  const sendMailFormSubmitHandler = () => {
    sendMailForm?.current?.requestSubmit();
  };

  const onSubmit = (data) => {
    let isTapAppPro:any;
      if(currentPlugin.plugin.toLowerCase() === 'tap pro'){
        isTapAppPro=true;
      }
      else
      isTapAppPro= false;

    dispatch(onPluginSendMail(data,currentPlugin.countryId,isTapAppPro,setMessage, setDisplayInfoModal, setShowSendMailModal, reset));
    setCurrentPlugin(null);
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const renderHeader = () => {
    return(
        <>
            Tech Advance<sup>+</sup>
        </>
    )
}

  const renderFooter = () => {
    return (
      <>
        <Button label="Cancel" onClick={() => setShowSendMailModal(false)} className="cancel btn" />
        <Button label="Send" className="update btn" autoFocus onClick={sendMailFormSubmitHandler} />
      </>
    );
  };

  const customizedContent1 = (item) => {
    return (
      <Card title={item.plugin}>
        {item.buildNumber ? (<p>{item.fileName} | {item.version} | {item.buildNumber}</p>) : (<p>{item.fileName} | {item.version} </p>)}

        <ListGroup as="ol">
          <ListGroup.Item as="li">
            {item.description}
          </ListGroup.Item>
        </ListGroup>
        <div className="icon-plugin pt-3">
          {(item.plugin.toLowerCase() === 'tap bridge') && (
            <>
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_BRIDGE_UPLOAD') && 
                <span className="mr-4">
                  <i className="pi pi-upload" onClick={() => onPluginUploadClick(item)} title="Upload"></i>
                </span>
              }
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_BRIDGE_DOWNLOAD') && 
                <span className="mr-4">
                  <i className="pi pi-download" onClick={() => onPluginDownloadClick(item)} title="Download"></i>
                </span>
              }
              {/* {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_BRIDGE_SEND_MAIL') && 
                <span className="mr-4">
                  <i className="pi pi-envelope" onClick={() => onPluginSendMailClick(item)}></i>
                </span>
              } */}
            </>)
          }
          {/* {(item.plugin.toLowerCase() === 'tap app') && (
            <>
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_UPLOAD') && 
                <span className="mr-4">
                  <i className="pi pi-upload" onClick={() => onPluginUploadClick(item)} title="Upload"></i>
                </span>
              }
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_DOWNLOAD') && 
                <span className="mr-4">
                  <i className="pi pi-download" onClick={() => onPluginDownloadClick(item)} title="Download"></i>
                </span>
              }
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_SEND_MAIL') && 
                <span className="mr-4">
                  <i className="pi pi-envelope" onClick={() => onPluginSendMailClick(item)} title="Send Mail"></i>
                </span>
              }
            </>)
          } */}
               {(item.plugin.toLowerCase() === 'tap pro') && (
            <>
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_UPLOAD') && 
                <span className="mr-4">
                  <i className="pi pi-upload" onClick={() => onPluginUploadClick(item)} title="Upload"></i>
                </span>
              }
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_DOWNLOAD') && 
                <span className="mr-4">
                  <i className="pi pi-download" onClick={() => onPluginDownloadClick(item)} title="Download"></i>
                </span>
              }
              {/* {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_SEND_MAIL') && 
                <span className="mr-4">
                  <i className="pi pi-envelope" onClick={() => onPluginSendMailClick(item)} title="Send Mail"></i>
                </span>
              } */}
              {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_QRCODE') &&
                <span className="mr-4">
                  <i className="pi pi-android" onClick={(e) => iosQrCoderef.current.toggle(e)} title="QR-Code"></i>
                  <OverlayPanel
                    ref={iosQrCoderef}
                    dismissable={true}
                  >
                    <div className="qr-code">
                      <div className="qr-code">
                        <img src={"data:image/png;base64," + androidImage?.qrcode} width="250" alt={'name'}></img>
                      </div>
                      <span className="p-input-icon-right" >
                        <span className="switch-left-icon" onClick={() => copyToClipBoard("android")} >
                          {!checked && <span><i className="pi pi-check-square" title="copied" /> </span>}
                          {checked && <span><i className="pi pi-copy" title="Copy to clipboard" /></span>}
                        </span>
                      </span>
                      <span><i className="pi pi-android" /> Android</span>
                    </div>
                  </OverlayPanel>                
                </span>
                }
                 {showUI(userPrivileges, 'SIETAP_PLUGINS_TAP_APP_QRCODE') &&
                <span className="mr-4">
                  <i className="pi pi-apple" onClick={(e) => androidQrCoderef.current.toggle(e)} title="QR-Code"></i>
                  <OverlayPanel
                    ref={androidQrCoderef}
                    dismissable={true}
                  >
                    <div className="qr-code">
                      <div className="qr-code">
                        <img src={"data:image/png;base64," + iosImage?.qrcode} width="250" alt={'name'}></img>
                      </div>
                      <span className="p-input-icon-right" >
                        <span className="switch-left-icon" onClick={() => copyToClipBoard("ios")} >
                        {!checked && <span><i className="pi pi-check-square"  title="copied" /> </span>}
                          {checked && <span><i className="pi pi-copy"  title="Copy to clipboard"/></span>}
                        </span>
                      </span>
                      <span><i className="pi pi-apple" /> iOS</span>
                    </div>
                  </OverlayPanel>
                </span>
                }
            </>)
          }
        </div>
      </Card>
    );
  };

  return (
    <>
      <div className="release-note plugin" style={{ width: "60vw" }}>
       <div className="d-flex pb-2 justify-content-between">
       <div className="title">Plugins</div>
       <div className="close-icon text-right" onClick={onCloseClick}>
          <i className="pi pi-times"></i>
        </div>
       </div>
        <ScrollPanel style={{ width: "100%", height: "300px" }}>
        {isDownloadInProgress && 
                <div className='pre-loader'>
                  <ProgressSpinner />
                  <h6>Download is in progress</h6>
                </div>}
          {pluginData ? (<Timeline
            value={pluginData}
            align="left"
            className="customized-timeline"
            content={customizedContent1}
          />) : "No Data Found"}
        </ScrollPanel>
      </div>

      <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
        />

      {showSendMailModal && <Dialog header={renderHeader}
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => setShowSendMailModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid" ref={sendMailForm}>
          <div className="field col-12 md:col-4 mt-2 pb-5 pt-3">
            <span className="p-float-label">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^$|^\S+@\S+\.\S+$/,
                    message: "Enter a valid Email",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id="email"
                    className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                    {...field}
                  />
                )}
              />
              <label className="mandatory" htmlFor="email">
                Enter Email
              </label>
              {getFormErrorMessage("email")}
            </span>
          </div>
        </form></Dialog>}
       

      {showUploadPlugin ? (<UploadPlugin
        showModal={showUploadPlugin}
        setShowModal={setShowUploadPlugin}
        currentPlugin={currentPlugin}
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
        countryId ={currentPlugin.countryId}
        setMessage={setMessage}
      />) : null}
      
    </>
  )

};

export default Plugins;