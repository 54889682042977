import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { BsExclamationCircleFill } from "react-icons/bs";
import InformationBox from 'components/common/Message/InformationBox/InformationBox';

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    objOpenDrawing: Function | any;

    cancelAction?:()=>void,
}
type FormData = {
    export: any;
    selectedFile:any;
};
export const SaveDrawing: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => {},
    objOpenDrawing,
    cancelAction=()=>{},


}) => {
  
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            export: null,
            selectedFile:null,
        },
    });
    const addForm: any = useRef();
    const FILE_LIST = [ 
        { name: "AutoCAD 2018", code: "0" },
        { name: "AutoCAD 2013", code: "1" },
        { name: "AutoCAD 2010", code: "2" },
        { name: "AutoCAD 2007", code: "3" },
        { name: "AutoCAD 2004", code: "4" },
        { name: "AutoCAD 2000", code: "5" },
        { name: "Release 14",  code: "6" },
        { name: "Release 13", code: "7" },
        { name: "Release 12", code: "8" },
      ];
    const [selectedFile, setSelectedFile] = useState<any>(
        );
    const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() =>{cancelAction(); setShowModal(false)} }className="cancel btn" />
                <Button label="OK" className="update btn" autoFocus onClick={() =>{onOKSave();}}/>
            </>
        );
    };
    const onFileFormatChange = (e: { value: any }) => {
        setSelectedFile(e.value);
      };
      const onOKSave = () => {
        if(selectedFile){
          objOpenDrawing.saveAs(true, selectedFile.code, (returnCode:any)=> { });
          setMessage("Drawing saved");
		  setDisplayInfoModal(true);
        }
    }

    const onOkClick = () => { 
         setShowModal(false)   
    }

    const getFormErrorMessage = (name) => {
        return (
          errors[name] && (
            <span className="tooltip-text">
              <BsExclamationCircleFill />
              {errors[name].message}
            </span>
          )
        );
      };


      const onFormSubmit = (data: any) => {
     };
    
      const onError = (errors: any, e: any) => {};


    return (
        <>
            <Dialog
                header="Save"
                visible={showModal}
                style={{ width: "40vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings selectsite"
            >
               <p className="pl-2">Do you want to save this Drawing?</p>
                <form
                 onSubmit={handleSubmit(onFormSubmit, onError)}
                 ref={addForm}
                >
            
                        <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller
                                name="selectedFile"
                                control={control}
                                rules={{ required: "Country Name is required." }}
                                render={({ field, fieldState }) => (

                                    
                                    <Dropdown
                                        inputId="dropdown"
                                        className={classNames("w-100 error-tooltip", {
                                            error: fieldState.invalid,
                                        })}
                                        {...field}
                                        value={selectedFile}
                                        options={FILE_LIST}
                                        onChange={onFileFormatChange}
                                        optionLabel="name"
                                        placeholder="-Select-"
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="dropdown">
                            -Select-
                            </label>
                            {getFormErrorMessage("exportDrawing")}
                        </span>
                    </div>

                </form>
            </Dialog>
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
                okOnClick={onOkClick}
            />

        </>
    );
};

export default SaveDrawing;
