import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

import { BsExclamationCircleFill } from "react-icons/bs";
import { getPersonalSettings, onUpdatePassword } from "redux/pages/Dashboard/UserMenu/personalSettingsSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";

interface IChangePassword {
  showModal: boolean;
  loginName: string;
  setShowModal: (state: boolean) => void;
}

type FormData = {
  LoginName: string;
  CurrentPassword: string;
  NewPassword: string;
  ConfirmNewPassword: string;
};

export const ChangePassword: React.FC<IChangePassword> = ({
  showModal = false,
  loginName = "",
  setShowModal = () => {},
}) => {
  const userPasswordForm: any = useRef();

  const dispatch = useDispatch<any>();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      LoginName: "",
      CurrentPassword: "",
      NewPassword: "",
      ConfirmNewPassword: "",
    },
  });

  const [message, setMessage] = useState<boolean>(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const newPassword = watch("NewPassword");
  const confirmNewPassword = watch("ConfirmNewPassword");
  const [passwordLength, setPasswordLength] = useState<any>()

  const personalSettings = useAppSelector(
    (state) => state.personalSettings.personalSettings
  );

  const formSubmitHandler = (data: any) => {
    userPasswordForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  useEffect(() => {
    dispatch(getPersonalSettings());
  }, [dispatch])

  useEffect(() => {
    if (personalSettings?.length !== 0) {
      setPasswordLength(personalSettings?.passWordLength)
    }
  }, [personalSettings])

  const onPasswordFormSubmit = (submitData: any) => {
    let body = {
      currentPassword: submitData.CurrentPassword,
      newPassword: submitData.NewPassword,
      confirmNewPassword: submitData.ConfirmNewPassword,
    };

    dispatch(
      onUpdatePassword(body, setMessage, setDisplayInfoModal, setShowModal)
    );
  };
  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button
          label="Save Changes"
          className="update btn"
          autoFocus
          onClick={formSubmitHandler}
        />
      </>
    );
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <Dialog
        header="Change Password"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onPasswordFormSubmit, onError)}
          ref={userPasswordForm}
        >
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0 py-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Login Name</label>
                <Controller
                  name="LoginName"
                  control={control}
                  render={() => <span className="text-value">{loginName}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="CurrentPassword"
                  control={control}
                  rules={{
                    required: "This field is required.",

                  }}
                  render={({ field, fieldState }) => (
                    <Password
                      id="CurrentPassword"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      feedback={false}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Current Password
                </label>
                {getFormErrorMessage("CurrentPassword")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="NewPassword"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    minLength: {
                      value: passwordLength,
                      message: `Minimum ${passwordLength} characters`,
                    },
                    validate: (value) => {
                      return value !== confirmNewPassword
                        ? "New Password and Confirm Password must be same"
                        : undefined;
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Password
                      id="NewPassword"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  New Password
                </label>
                {getFormErrorMessage("NewPassword")}
              </span>
              <small   >(Minimum {passwordLength} characters)</small>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 pb-0">
              <span className="p-float-label">
                <Controller
                  name="ConfirmNewPassword"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    validate: (value) => {
                      return value !== newPassword
                        ? "New Password and Confirm Password must be same"
                        : undefined;
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Password
                      id="ConfirmNewPassword"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Confirm New Password
                </label>
                {getFormErrorMessage("ConfirmNewPassword")}
              </span>
            </div>
          </div>
        </form>
        <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
          okOnClick={() => setShowModal(false)}
        />
      </Dialog>
    </>
  );
};

export default ChangePassword;
