import { Button } from "primereact/button";
import React from "react";

const PluginsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Plugins
            </h4>
            <p></p>
            <p>
                Plugins tab provides options to upload, download and Send Mail the necessary setup file for 
                installing or updating the TAP Bridge and TAP App. Plugins options are restricted to User roles.
            </p>

            <p>
                Super Administrators have all the options whereas for Branch Users, the Plugin Menu will not be available. 
                For other User Roles, the options are available as per the User privileges.
            </p>

            <p>
                The Super Administrator can upload the latest plugins used in Tech Advance+. When a version change occurs,
                 the administrator can upload the new file through the Upload option. This can later be downloaded to Branch 
                 Administrator/Technician's laptop whenever necessary. The latest TAP Bridge and TAP App files are the plugins
                  available to download through this option.
            </p>
            <h5 className="doc-title mb-2">TAP Bridge</h5>
        
            <p>
                This option is used to upload the latest TAP Bridge file to Tech Advance+ by Super Administrator using
                <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon"> <i className="pi pi-upload icon" title="Upload" /> </Button>{" "}
                 button. This can then be downloaded to Branch 
                Administrator/Technician's laptop through Download button.
            </p>
           
            <p>
                The Super Administrator can update the latest version of the TAP Bridge file by selecting the Upload button 
                displayed against it. On selecting <span className="doc-subtitle">Upload </span>
                <Button className="doc-button-icon"> <i className="pi pi-upload icon" title="Upload" /> </Button>{" "}
                button, TAP Bridge window will 
                be displayed with Tap Bridge File, Version and Minimum Compatible Version fields loaded by default. Browse for 
                the zip file to be uploaded by selecting Browse button, enter description if necessary and click Upload button to
                 upload the file. The details: 'Selected File'| 'Version' of the uploaded file (if any) will be displayed as read only.
            </p>
           
            <p>
                The updated file can be downloaded by the Technician or Branch Administrator by selecting the 
                <span className="doc-subtitle"> Download </span> 
                <Button className="doc-button-icon"> <i className="pi pi-download icon" title="Download" /> </Button>{" "}
                button. Technicians and Branch Administrators can download the TAP 
                Bridge whenever the TAP Bridge file version in their laptop gets expired or is of an older version. The Version and
                Minimum Compatible Version of the application is verified while downloading the file and warning messages will be
                 displayed.
            </p>

            <h5  className="doc-title mb-2">TAP App</h5>
            <p></p>
            <p>
                This option is used to upload the APK file for the TAP Application to Tech Advance+ by Super Administrator using 
                <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon"> <i className="pi pi-upload icon" title="Upload" /> </Button>{" "}
                button. This can then be downloaded to Branch Administrator/Technician's
                 phone/tab through <span className="doc-subtitle"> Send Mail </span> 
                 <Button className="doc-button-icon"><i className="pi pi-envelope icon" title="Mail"/></Button>{" "}
                 button. Send Mail option is provided so that the 
                 user can access the link to download the APK and download the APK file to any other device as per requirement via mail. 
                 Only the Super Administrator can access the <span className="doc-subtitle">Download </span>
                 <Button className="doc-button-icon"> <i className="pi pi-download icon" title="Download" /> </Button>{" "}
                 button.
            </p>
            
            <p>
                The Super Administrator can update the latest version of the APK file by selecting the
                <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon"> <i className="pi pi-upload icon" title="Upload" /> </Button>{" "}
                button displayed against it. On selecting 
                <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon"> <i className="pi pi-upload icon" title="Upload" /> </Button>{" "}
                button, TAP APP window will be displayed with the options to browse for
                 a valid (.apk) file, enter the Build Number and Version Number. Browse for a valid file, enter the details and click 
                 <span className="doc-subtitle"> Upload </span>
                 <Button className="doc-button-icon"> <i className="pi pi-upload icon" title="Upload" /> </Button>{" "}
                 button to upload the file to Tech Advance+. The details: 
                 'Selected File'| 'Version' of the uploaded file (if any) will be displayed as read only.
            </p>
           
            <p>
                The updated file can be downloaded by the Super Administrator by selecting the 
                <span className="doc-subtitle"> Download </span>
                <Button className="doc-button-icon"> <i className="pi pi-download icon" title="Download" /> </Button>{" "}
                button. User can download the APK file whenever the application gets
                expired or is of an older version.
            </p>
            
            <p>
                The option Send Mail is provided so that the Technicians and Branch Administrators can access the link to download 
                the APK file directly through the user's phone/tab. Click <span className="doc-subtitle"> Send Mail </span>
                <Button className="doc-button-icon"><i className="pi pi-envelope icon" title="Mail"/></Button>{" "}
                button tosend the APK download link to the user's mail. User can login to the mail 
                account through mobile phone/tab and click on link to access the APK file. 
                On selecting this link the APK file to install the TAP App will be downloaded 
                to the user's phone or tab.
            </p>
        </>
    );
}

export default PluginsDocument;