import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";

import AddModal from "components/Table/AddModal/AddModal";
import {
  getCountryList,
  getStateList,
  getTimeZoneList,
} from "redux/pages/Administration/administrationSlice";
import { USAPattern} from "../ManageSite/SiteNumberPattern";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddSiteClick: (data: any) => void;
}

type FormData = {
  countryName: string;
  siteNumber: string;
  siteName: string;
  state: string;
  timeZone: string;
  Description: string;
};

export const AddSite: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onAddSiteClick = () => {},
}) => {
  const countryList = useAppSelector(
    (state) => state.administration.countryList
  );
  const stateList = useAppSelector((state) => state.administration.stateList);

  const TimeZoneList = useAppSelector(
    (state) => state.administration.TimeZoneList
  );
  const [countryLookup, setCountryLookup] = useState([{ name: "", code: "" }]);

  const [stateLookup, setStateLookup] = useState([{ name: "", code: "" }]);

  const [TimeZoneLookup, setTimeZoneLookup] = useState([
    { name: "", code: "" },
  ]);

  const [showPatternModal, setShowPatternModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [pattern, setPattern] = useState<any>([]);
  const [siteNumberPattern, setSiteNumberPattern] = useState<any>();
  const addForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      countryName: "",
      siteNumber: "",
      siteName: "",
      state: "",
      timeZone: "",
      Description: "",
    },
  });

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getStateList());
    dispatch(getTimeZoneList());
  }, [dispatch]);

  useEffect(() => {
    if (countryList?.countries && countryList?.countries.length) {
      let country: { name: string; code: string }[] = [];
      countryList?.countries.map((item: any) => {
        return country.push({ name: item.countryName, code: item.countryCode });
      });
      setCountryLookup(country);
      setValue("countryName", countryList?.countries[0].countryName);
      setSelectedCountry(country[0].name.trim());
    }
  }, [countryList, setValue]);

  useEffect(() => {
    let selectedCountryId:any;
    countryList?.countries?.map((item: any) => {
      if (item.countryName.trim() === selectedCountry.trim()) {
        selectedCountryId = item.countryId;
      }
    });
    if (stateList?.statesOfCountries && stateList?.statesOfCountries?.length) {
      let state: { name: string; code: string }[] = [];
      state.push({ name: "--Select--", code: "--Select--"});
      stateList?.statesOfCountries?.map((item: any) => {
        if (selectedCountryId === item.countryId) {
          state.push({
            name: item.statesOfCountryName,
            code: item.statesOfCountryCode,
          });
        }
        return null;
      });
      setValue("state",state[0].name)
      setStateLookup(state);
    }
  }, [stateList, countryList, selectedCountry, setValue]);
  useEffect(() => {
    setValue("state", stateLookup[0].name);
  }, [stateLookup]);
  useEffect(() => {
    if (TimeZoneList?.timeZones && TimeZoneList?.timeZones.length) {
      let timeZone: { name: string; code: string }[] = [];
      timeZone.push({ name: "--Select--", code: "--Select--"});
      TimeZoneList?.timeZones.map((item: any) => {
        return timeZone.push({
          name: item.timeZoneName,
          code: item.timeZoneId,
        });
      });
      setTimeZoneLookup(timeZone);
      setValue("timeZone", timeZone[0].name);
    }
  }, [TimeZoneList, setValue]);

  useEffect(() => {
    if (selectedCountry.trim() === "United States") {
      setPattern(USAPattern);
      setSiteNumberPattern(/^44OC[-][0-9]{7}$|44OP[-][0-9]{6}$|4433[-][0-9]{5}[-][0-9]{5}$|4433[-][0-9]{5}[-][0-9]{4}$/);
    } 
  }, [selectedCountry]);

  const onAddFormSubmit = (data: any) => {
    data.siteName=data.siteName?.trim();
    //Purposely commented based on requirement
    //data.Description=data.Description?.trim();
    onAddSiteClick && onAddSiteClick(data);
  };

  const formSubmitHandler = (data: any) => {
    addForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  //handle form validation errors

  return (
    <AddModal
      header="New Site"
      showModal={showModal}
      setShowModal={setShowModal}
      OnSaveClick={formSubmitHandler}
      isAdd={true}
      style={{ width: "35vw", maxHeight: "95%" }}
    >
      <form onSubmit={handleSubmit(onAddFormSubmit)} ref={addForm}>
        <Dialog
          header="Site Number"
          visible={showPatternModal}
          onHide={() => setShowPatternModal(false)}
          className="pattern-overlay"
          style={{ width: "32vw" }}
        >
          <DataTable showGridlines={false} value={pattern}>
            <Column field="pattern" header="Pattern" />
            <Column field="format" header="Format" />
            <Column field="description" header="Description" />
          </DataTable>
        </Dialog>

        <div className="field col-12 md:col-4 pt-4">
          <span className="p-float-label">
            <Controller
              name="countryName"
              control={control}
              rules={{ required: "Country Name is required." }}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setSelectedCountry(e.value);
                  }}
                  options={countryLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="dropdown">
              Country
            </label>
            {getFormErrorMessage("countryName")}
          </span>
        </div>
        <div className="field col-12 md:col-4 mb-0">
          <span className="p-float-label">
            <Controller
              name="siteNumber"
              control={control}
              rules={{
                required: "Site Number is required.",
                pattern: {
                  value: siteNumberPattern,
                  message: "Enter a valid Site Number",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Site Number
            </label>
            {getFormErrorMessage("siteNumber")}
          </span>
        </div>
        <div className="field col-12 md:col-4 pt-0">
          <span
            className="pattern-view d-flex justify-content-end pb-1"
            role="button"
            onClick={() => setShowPatternModal(true)}
          >
            View Pattern
          </span>
          <span className="p-float-label">
            <Controller
              name="siteName"
              control={control}
              rules={{ required: "Site Name is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Site Name
            </label>
            {getFormErrorMessage("siteName")}
          </span>
        </div>

        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="state"
              control={control}
              rules={{ required: "State is required.",
              validate:(value)=>{
                return value==="--Select--"?"Select a State":undefined;
              }}}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="state"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={stateLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="dropdown">
              State
            </label>
            {getFormErrorMessage("state")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="timeZone"
              control={control}
              rules={{ required: "Time Zone is required." ,
              validate:(value)=>{
                return value==="--Select--"?"Select a Time Zone":undefined;
              }}}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={TimeZoneLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="dropdown">
              Time Zone
            </label>
            {getFormErrorMessage("timeZone")}
          </span>
        </div>
        {/* Purposely commented based on requirement  */}
        {/* <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="Description"
              control={control}
              rules={{ required: "Description is required." ,
              validate: (value) => {
                return value.trim().length<=0
                  ?"This field is required":true
              },}}
              render={({ field, fieldState }) => (
                <InputTextarea
                  id="Textarea"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Description
            </label>
            {getFormErrorMessage("Description")}
          </span>
        </div> */}

      </form>
    </AddModal>
  );
};

export default AddSite;
