import React, {useCallback, useEffect, useState } from "react";

//components
import DataTableComponent from "components/Table/DataTable";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { getMarkupList, setDeletedMarkupData, setmarkupList, setUpdatedMarkup } from "redux/pages/AsBuilts/AsBuiltsSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import AsbuiltsDrawing from "./AsbuiltsDrawing";

interface Props {
    showModal?: boolean;
    setShowModal?: (state: boolean) => void;
    selectedRowData?: (data: any) => void;
    selectedBuilding?: any;
    getSelectedData?: (data: any) => void;
    settingsRef: any;
    fromRevision?: boolean;
    revisionRow?: any;
    showDrawing?: boolean;
    setShowDrawingModal?: (state: boolean) => void;
   
}


export const ListMarkups: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRowData = [],
    selectedBuilding = [],
    getSelectedData = () => { },
    settingsRef = null,
    fromRevision = false,
    revisionRow = [],
    showDrawing = false,
    setShowDrawingModal = () => { },
}) => {

    const [pageIndex, setPageIndex] = useState(0);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [markupData, setMarkupData] = useState<any>([]);
    const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState('')
    const [sortOrder, setSortOrder] = useState('');

    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
    const markupList = useAppSelector((state) => state.asbuilts.markupList);
    const markupPageDetails = useAppSelector((state) => state.asbuilts.markupPageDetails);
    const updatedMarkup = useAppSelector((state) => state.asbuilts.updatedMarkup);
    const deletedMarkupData = useAppSelector((state) => state.asbuilts.deletedMarkupData);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        let body = {
            revisionNo: fromRevision? revisionRow[0].revisions : selectedRowData[0]?.revisions,
            markupId: 0
        };
        dispatch(getMarkupList(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, body, 1,setDisplayInfoModal,setMessage))
    }, [selectedRowData])

    useEffect(() => {
        if (markupList) {
            setMarkupData(markupList);
            dispatch(setmarkupList(""))
        }

    }, [markupList])
   
    const onPageChanged = (options: any) => {
        let currentStart;
        setPageIndex(options);
        currentStart = options * markupPageDetails?.currentLength + 1;
        setCurrentStart(currentStart)
        let body = {
            revisionNo: selectedRowData[0]?.revisions,
            markupId: 0
        };
        dispatch(getMarkupList(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, body, currentStart,setDisplayInfoModal,setMessage, "", sortField, sortOrder))
    }

    const onSort = (event) => {
        if (event && markupData && markupData.length) {
            let sortDirection: string;
            if (event.sortOrder === 1)
                sortDirection = "ASC"
            else
                sortDirection = "DESC"
            setSortField(event.sortField)
            setSortOrder(sortDirection)
            let body = {
                revisionNo: selectedRowData[0]?.revisions,
                markupId: 0
            };
            dispatch(getMarkupList(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, body, currentStart, setDisplayInfoModal, setMessage, "", event?.sortField, sortDirection))
        }
    }
    const handleRowSelect = useCallback((data: any) => {
        setSelectedRow([...data]);
        getSelectedData([...data])
    }, []);
    const onCloseClick = () => {
        setShowDrawingModal(false);
     }
    useEffect(() => {
        if (updatedMarkup) {
            let updatedData = JSON.parse(JSON.stringify(updatedMarkup[0]));
            let markupInfo = JSON.parse(JSON.stringify(markupData));
            let currentIndex = markupInfo.findIndex((item: any) => item.markupId === updatedData.markupId);
            const newMarkupList: any = markupInfo.filter((item: any) => item.markupId !== updatedData.markupId);
            newMarkupList.splice(currentIndex, 0, updatedData);
            setMarkupData(newMarkupList);
            setSelectedRow([updatedData]);
            dispatch(setUpdatedMarkup(""));
          }
    }, [updatedMarkup])

    useEffect(() => {
        if (deletedMarkupData) {
            let deletedData = JSON.parse(JSON.stringify(deletedMarkupData));
            let markupInfo = JSON.parse(JSON.stringify(markupData));
            const newMarkupList: any = markupInfo.filter((item: any) => item.markupId !== deletedData);
            setMarkupData(newMarkupList);
            dispatch(setDeletedMarkupData(""));
          }
    }, [deletedMarkupData])


    return (
       <> 
       {showDrawing ?
       <div className="asBuild-parent">
            <div className={`asBuild-wrapper ${ "drawing-f"} `}>
                <div className="as-build-drawing__wrapper">
                    <div className="asBuild-drawing">
                       
                        <div className="type-list">
                            <div className="item">
                                <h6>Site</h6> <span>{selectedRowData[0]?.siteName}</span>
                            </div>
                            <div className="item">
                                <h6>Building</h6>
                                <span>{selectedRowData[0]?.buildingName}</span>
                            </div>
                            <div className="item">
                                <h6>Floor</h6>
                                <span>{selectedRowData[0]?.floorName}</span>
                            </div>
                            <div className="item">
                                <h6>Category</h6>
                                <span>{selectedRowData[0]?.category}</span>
                            </div>
                            <div className="item">
                                <h6>Revision Number </h6>
                                <span>{selectedRowData[0]?.revisions}</span>
                            </div>
                            <div className="item">
                                <h6>File Name</h6>
                                <span>{selectedRowData[0]?.dwgFileName}</span>
                            </div>
                            <div className="item">
                                <h6>Description</h6>
                                <span>{selectedRowData[0]?.description}</span>
                            </div>
                        </div>
                     
                              <AsbuiltsDrawing
                                    selectedMarkupRow={selectedRow}
                                    selectedDrawing={selectedRowData[0]}
                                     bIsResizeDrawing={false}
                                     selectedBuilding={selectedBuilding}
                                     selectedSite={loggedInUserData?.userDefaults}
                                     closeDrawingView={onCloseClick}
                                     showMarkupTools={false}
                                />
                      
                    </div>
                </div>
                </div></div>
                :               
                <DataTableComponent
                rows={markupData}
                cols={MARKUP_COL}
                paginator={true}
                dataKeyId="markupId"
                title={``}
                pageDetails={markupPageDetails}
                pageIndex={pageIndex}
                onPageChanged={onPageChanged}
                onSortData={onSort}
                showCustomSearchCmp
                getSelectedRowData={handleRowSelect}
                settingRef={settingsRef}
                settingListItems={[]}
             />}
            
            <InformationBox
            showInfoModal={displayInfoModal}
            setShowInfoModal={setDisplayInfoModal}
            message={message} />
            
        
        </>
    );
};

export default ListMarkups;

const MARKUP_COL = [
	{
		field: "uploadedBy",
		header: "Uploaded By",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "uploadedOn",
		header: "Uploaded On",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "description",
		header: "Description",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "markupFileName",
		header: "Markup File Name",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},

];


function disableEditor(options: any) {
    throw new Error("Function not implemented.");
}