import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { baseURL } from "../../service/HttpService";
import { FilePond, registerPlugin } from "react-filepond";
import FileUploadValidate from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";

import UploadModal from "components/Table/UploadModal/UploadModal";
import moment from "moment";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onUploadFinalReportClick?: (data: any, setLoader: any) => void;
  acceptedFileTypes?: string[];
  errorMessage?:string;
  title: string;
}

type FormData = {
  uploaddate: string;
};

export const UploadFinalReport: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onUploadFinalReportClick = () => {},
  acceptedFileTypes =["application/pdf"],
  errorMessage = "Select a PDF.",
  title = "Upload Final Report",
}) => {
  registerPlugin(FileUploadValidate);
  const editForm: any = useRef();
  const [enableUpload, setEnableUpload] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [fileName, setFileName] = useState("");
  const [uploadedDate, setUploadedDate] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const {
    control,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      uploaddate: "",
    },
  });

  const onEditFormSubmit = (data: any) => {};

  const UploadClick = () => {
    let response = JSON.parse(JSON.parse(JSON.stringify(files.serverId)));
    if (response?.status === "done") {
      setEnableUpload(true);
      let body = {
        TempStorageDirectory: response.data?.uniqueDirectoryId[0],
      };
      onUploadFinalReportClick && onUploadFinalReportClick(body, setEnableUpload);
    }
  };

  const onprocessfile = (error: any, file: any) => {
    setEnableUpload(false);
    setFiles(file);
    let uploadedFile = JSON.stringify(file.filename);
    let date = moment(new Date()).format("YYYY-MM-DD").toString();
    setFileName(uploadedFile);
    setUploadedDate(date);
  };

  const onRemoveFile = () =>{
    setEnableUpload(true);
    setFileName("");
    setUploadedDate("");
  }

  const onError = (error) => {
    setMessage(error && error.sub ? error.sub : `Final report upload failed due to Network Error. Select the file to be uploaded.` );
    setDisplayInfoModal(true);
  }

  return (
    <UploadModal
      header={title}
      showModal={showModal}
      setShowModal={setShowModal}
      OnSaveClick={UploadClick}
      isAdd={false}
      style={{ width: "40vw", maxHeight: "95%" }}
      buttonDisable={enableUpload}
    >
      <form onSubmit={handleSubmit(onEditFormSubmit)} ref={editForm}>
        <div className="row px-2 pt-1">
          <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
            <div className="left-sider-label upload-left">
              <div className="p-float-label">
                <p className="label">Attachment</p>
              </div>
            </div>
            <div className="right-side-field">
              <FilePond
                allowMultiple={false}
                maxFiles={1}
                server={`${baseURL}api/v1/common/files/upload`}
                onprocessfile={onprocessfile}
                onremovefile={onRemoveFile}
                onerror={onError}
                name="files"
                credits={false}
                labelIdle="Click to choose a file or drag and drop a file"
                labelFileLoading=""
                labelTapToUndo=""
                labelFileProcessing=""
                labelFileProcessingComplete=""
                labelFileProcessingAborted=""
                labelFileProcessingError=""
                labelButtonAbortItemProcessing=""
                labelButtonUndoItemProcessing=""
                labelButtonRetryItemProcessing=""
                labelButtonProcessItem=""
                labelTapToCancel=""
                labelFileRemoveError=""
                labelTapToRetry=""
                allowFileTypeValidation={true}
                acceptedFileTypes={acceptedFileTypes}
                labelFileLoadError={errorMessage}
                fileValidateTypeLabelExpectedTypes={errorMessage}
              />
            </div>
          </div>
        </div>
        <div className="row px-2 align-items-center">
          <div className="field col-11 md:col-2 mb-0 d-flex">
            <div className="left-sider-label upload-left">
              <div className="p-float-label">
                <p className="label">Uploaded File Name</p>
              </div>
            </div>
            <div className="right-side-field">
              <span className="p-float-label text-container">
                <Controller
                  name="uploaddate"
                  control={control}
                  render={() => (
                    <span className="text-value ml-0">
                      {fileName}
                    </span>
                  )}
                />
              </span>
            </div>
          </div>
          <div className="field col-1 md:col-2 mb-0 p-0">
          </div>
        </div>
        <div className="field col-12 md:col-2 mb-0 d-flex">
            <div className="left-sider-label upload-left">
              <div className="p-float-label">
                <p className="label">Uploaded Date</p>
              </div>
            </div>
            <div className="right-side-field">
              <span className="p-float-label text-container">
                <Controller
                  name="uploaddate"
                  control={control}
                  render={() => (
                    <span className="text-value ml-0">
                      {uploadedDate}
                    </span>
                  )}
                />
              </span>
            </div>
          </div>
        
      </form>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message} />
    </UploadModal>
    
  );
};

export default UploadFinalReport;
