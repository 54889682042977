import React, { memo, useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { AutoComplete } from "primereact/autocomplete";
import { Checkbox } from "primereact/checkbox";

import { BsPlusLg } from "react-icons/bs";
import {
  IoLocationOutline,
  IoPersonOutline,
  IoReaderOutline,
} from "react-icons/io5";

import { MdOutlineSort } from "react-icons/md";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import {
  getCustomerList,
  getContractList,
  setDefaultValues,
  setIsCustomerAll,
  setIsContractAll,
  setIsContractAllFalse,
  setSelectedSite,
  setSelectedCustomerId,
  setSelectedCustomerDetails,
  clearSelectedCustomerId,
  clearSelectedContractId,
  setSelectedContractId,
  setSelectedContractDetails,
  getCustomerSearchDetails,
  getContractSearchDetails,
  onMapCustomer,
  mapInspectionContract,
  setCustomerListData,
  setContractListData,
} from "../../redux/pages/Inspection/InspectionTask/inspectionTaskFilterSlice";
import { setInspectionSearchId, setNewInspectionData } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { setAsActiveSiteForSite } from "../../redux/pages/Administration/Site/siteSlice";
import { onAddCustomer } from "../../redux/pages/Administration/administrationSlice";
import { getSiteSearchDetails } from "../../redux/pages/Administration/Search/searchSlice";
import AddContract from "pages/Contracts/ManageContract/AddContract";
import { onAddContract } from "redux/pages/Inspection/Contracts/contractSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import AddCustomer from "pages/Customers/ManageCustomer/AddCustomer";
import InspectionMapCustomer from "./MapCustomer";
import InspectionMapContract from "./MapContract";
import { showUI } from "service/GeneralUtility";

interface Props {
  setFilterExpanded: (data: boolean) => void;
  filterExpanded: boolean;
  setShowAddInspectionTaskModal: (data: boolean) => void;
}
export const InspectionTasksFilter: React.FC<Props> = ({
  setFilterExpanded = () => { },
  filterExpanded = false,
  setShowAddInspectionTaskModal = () => { },
}) => {
  const [inputSite, setInputSite] = useState<any>(null);
  const [filteredSite, setFilteredSite] = useState<any>(null);
  const [filteredCustomer, setFilteredCustomer] = useState<any>(null);
  const [filteredContract, setFilteredContract] = useState<any>(null);
  const [inputCustomer, setInputCustomer] = useState("");
  const [inputContract, setInputContract] = useState("");
  const [showInspectionMapContract, setShowInspectionMapContract] = useState(false);
  const [showSiteMapCustomer, setShowSiteMapCustomer] = useState(false);
  const [checked, setChecked] = useState(true);
  const [siteName, setSiteName] = useState("");
  const [searchCustomerList, setSearchCustomerList] = useState<any>([]);
  const [searchContractList, setSearchContractList] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
  const siteDetails = useAppSelector((state) => state.search.siteDetails);
  const customerSearchDetails = useAppSelector((state) => state.inspectionTaskFilter.customerSearchDetails);
  const contractSearchDetails = useAppSelector((state) => state.inspectionTaskFilter.contractSearchDetails);

  const customerListDatas = useAppSelector((state) => state.inspectionTaskFilter.customerList);
  const contractListDatas = useAppSelector((state) => state.inspectionTaskFilter.contractList);
  const selectedCustomerId = useAppSelector((state) => state.inspectionTaskFilter.selectedCustomerId);
  const selectedContractId = useAppSelector((state) => state.inspectionTaskFilter.selectedContractId);
  const selectedSite = useAppSelector((state) => state.site.activeSite);
  const isCustomerAll = useAppSelector((state) => state.inspectionTaskFilter.isCustomerAll);
  const isContractAll = useAppSelector((state) => state.inspectionTaskFilter.isContractAll);
  const selectedCustomerDetails = useAppSelector((state) => state.inspectionTaskFilter.selectedCustomerDetails);
  const newContractData = useAppSelector((state) => state.contract.newContractData);
  const newCustomerData = useAppSelector((state) => state.administration.newCustomerData);
  const activeSite = useAppSelector((state) => state.administration.loggedInUserDetails.userDefaults);
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

  const [customerList, setCustomerList] = useState<any>([]);
  const [contractList, setContractList] = useState<any>([]);
  const [customerAllChecked, setCustomerAllChecked] = useState<boolean>();
  const [contractAllChecked, setContractAllChecked] = useState<boolean>();
  const [showAddContract, setShowAddContract] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isMapCustomer, setIsMapCustomer] = useState(false);
  const [isMapContract, setIsMapContract] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showGrid, setShowGrid] = useState(false);
  const [countryId, setCountryId] = useState<any>(220);
  const [displayConfirmModal, setDisplayConfirmModal] = useState<boolean>(false);
  const [isAddContract, setIsAddContract] = useState(false);

  const siteSearchRef: any = useRef([]);


  useEffect(() => {
    setCustomerAllChecked(isCustomerAll);
  }, [isCustomerAll]);

  useEffect(() => {
    setContractAllChecked(isContractAll);
  }, [isContractAll]);

  useEffect(() => {
    if (selectedSite) {
      setCustomerList([]);
      setContractList([]);
      dispatch(getCustomerList());
    }
  }, [selectedSite]);

  useEffect(() => {
    if (newContractData) {
      let reviseContractData: any = {
        uniqueContractId: newContractData.uniqueContractId,
        contractName: `${newContractData.contractName} (${newContractData.contractNumber})`,
        active: false,
        disabled: false,
        status: newContractData.status,
        uniqueCustomerId: newContractData.uniqueCustomerId,
        customerName: newContractData.customerName
      };
      contractList.push(reviseContractData);
      setContractList(contractList);
      handleContractClick(selectedCustomerId, newContractData?.uniqueContractId);
      if (isAddContract) {
        setDisplayConfirmModal(true);
        setMessage("Do you want to add Inspection Task to this Contract?");
        setIsAddContract(false);
      }
    }
  }, [newContractData]);

  useEffect(() => {
    if (newCustomerData) {
      customerList.push(newCustomerData);
      setCustomerList(customerList);
    }
  }, [newCustomerData]);

  useEffect(() => {
    if (isMapCustomer) {
      dispatch(getCustomerList())
    }
  }, [isMapCustomer]);

  useEffect(() => {
    if (isMapContract) {
      dispatch(getContractList(selectedCustomerDetails.uniqueCustomerId))
      setIsMapContract(false);
    }
  }, [isMapContract]);

  useEffect(() => {
    if(isMapCustomer) {
      if(customerListDatas?.length === 0) {
        dispatch(setSelectedContractDetails(""))
      }
      setIsMapCustomer(false)
    }
    if(customerListDatas.length === 0) {
      dispatch(setSelectedContractDetails("")) // -- to clear contractDetails when switching to empty site
    }
    if (customerListDatas) {
      setContractList([]);
      var selectedData: boolean = false;
      customerListDatas.map((element: any) => {
        if (element.isActive === true) {
          if(selectedCustomerId !== element.uniqueCustomerId){
            dispatch(setSelectedCustomerId(element.uniqueCustomerId));                    
          }
          selectedData = true;      
          dispatch(setSelectedCustomerDetails(element));
          dispatch(getContractList(element.uniqueCustomerId));
        } 
        return null;
      });

      if (customerListDatas?.length > 0 && !selectedData) {
        if(selectedCustomerId !== customerListDatas[0].uniqueCustomerId ){
          dispatch(setSelectedCustomerId(customerListDatas[0].uniqueCustomerId));    
        }
        dispatch(setSelectedCustomerDetails(customerListDatas[0]));
        let body = {
          ActiveCustomerId: customerListDatas[0].uniqueCustomerId,
          ActtiveContractId: "",
        };
        dispatch(setDefaultValues(body));  
        dispatch(getContractList(customerListDatas[0].uniqueCustomerId));
      } else if(customerListDatas?.length === 0){
        dispatch(clearSelectedCustomerId(null));
      }
      let ReviseCustomerData: any = [];
      if (!isCustomerAll) {
        let isActiveElementExists = false;
        customerListDatas.map((element: any, key: any) => {
          ReviseCustomerData = [
            ...ReviseCustomerData,
            {
              uniqueCustomerId: element.uniqueCustomerId,
              customerName: element.customerName,
              customerNumber: element.customerNumber,
              active: element.isActive,
              disabled: false,
            },
          ];
          return null;
        });
        ReviseCustomerData.map((element:any, key:any) => {
          if(element.active) {
            isActiveElementExists = true;
          }
        })
        if(!isActiveElementExists) {
          if(ReviseCustomerData?.length){
            ReviseCustomerData[0].active = true;
          }
        }
      } else {
        customerListDatas.map((element: any, key: any) => {
          ReviseCustomerData = [
            ...ReviseCustomerData,
            {
              uniqueCustomerId: element.uniqueCustomerId,
              customerName: element.customerName,
              customerNumber: element.customerNumber,
              active: false,
              disabled: true,
            },
          ];
          return null;
        });
        dispatch(clearSelectedCustomerId(null));
        dispatch(clearSelectedContractId(null));
      }
      setCustomerList(ReviseCustomerData);
      return (() => {
        dispatch(setCustomerListData(""));
      })
    } 
  }, [customerListDatas, isCustomerAll]);

  useEffect(() => {
    if( contractListDatas){
    if (contractListDatas?.length > 0 ) {
      let reviseContractData: any = [];
      let selectedData: boolean = false;
      let contractId = "";
      if (!isCustomerAll && !isContractAll) {
        contractListDatas.map((contractElement: any, contractKey: any) => {
          if (contractElement.isActive === true) {
            let contractName = contractElement.contractName + " (" + contractElement.contractNumber + ")"
            if (selectedContractId !== contractElement.uniqueContractId) {
              dispatch(setSelectedContractId(contractElement.uniqueContractId));
              contractId = contractElement.uniqueContractId;
            }
            dispatch(setSelectedContractDetails({ ...contractElement, contractName: contractName, contractNumber: contractElement.contractNumber }));
            selectedData = true;
          }
          reviseContractData = [...reviseContractData, {
            uniqueContractId: contractElement.uniqueContractId,
            contractName: `${contractElement.contractName} (${contractElement.contractNumber})`,
            contractNumber: contractElement.contractNumber,
            active: contractElement.isActive,
            disabled: false,
            status: contractElement.status,
            uniqueCustomerId : contractElement.uniqueCustomerId,
            customerName: contractElement.customerName
          }];
          return null;
        });
      } else {
        contractListDatas.map((contractElement: any, contractKey: any) => {
          reviseContractData = [...reviseContractData, {
            uniqueContractId: contractElement.uniqueContractId,
            contractName: `${contractElement.contractName} (${contractElement.contractNumber})`,
            active: false,
            disabled: true,
            status: contractElement.status,
            uniqueCustomerId : contractElement.uniqueCustomerId,
            customerName: contractElement.customerName
          }];
          return null;
        });
        dispatch(clearSelectedContractId(null));
        dispatch(setSelectedContractDetails(null));
      }
      if (reviseContractData?.length > 0 && !selectedData && !isContractAll && !isCustomerAll) {
        reviseContractData.map((item) => {
          if (item.active === true) {
            selectedData = true;           
          }
        })
        if (!selectedData) {        
          if(selectedContractId !== reviseContractData[0].uniqueContractId){
            reviseContractData[0].active = true;
            dispatch(setSelectedContractId(reviseContractData[0].uniqueContractId));
            contractId=reviseContractData[0].uniqueContractId;
          }
          dispatch(setSelectedContractDetails(reviseContractData[0]));
        }
      }
      setContractList(reviseContractData);
      let Defaultbody :any
      if (selectedContractId || contractId) {
         Defaultbody = {
          ActiveCustomerId: selectedCustomerId,
          ActiveContractId: contractId,
        };     
        dispatch(setDefaultValues(Defaultbody));
      } 
    } else {
      dispatch(setSelectedContractId(null));
      dispatch(setSelectedContractDetails(null));
    }
        setShowGrid(true);
      return(()=>{
        dispatch(setContractListData(""));
      })
  }
  
  }, [contractListDatas]);

  const handleCustomerClick = (selectedItem) => {
    setContractList([]);
    setContractAllChecked(false);
    let customerListParsed = JSON.parse(JSON.stringify(customerList));
    const modifiedList = customerListParsed.map((item) => {
      if (item.uniqueCustomerId === selectedItem.uniqueCustomerId) {
        item.active = !item.active;
      } else {
        item.active = false;
      }
      return item;
    });
    setCustomerList(modifiedList);
    if(selectedCustomerId !== selectedItem.uniqueCustomerId){
      dispatch(setSelectedCustomerId(selectedItem.uniqueCustomerId));
    }
    dispatch(setSelectedCustomerDetails(selectedItem));
    dispatch(setIsContractAllFalse());
    dispatch(getContractList(selectedItem.uniqueCustomerId));
    dispatch(setInspectionSearchId(""));
  };

  const handleContractClick = (parentId, itemId) => {
    dispatch(setInspectionSearchId(""));
    if(selectedContractId !== itemId){
      let contractListParsed = JSON.parse(JSON.stringify(contractList));
      const modifiedContractList = contractListParsed.map((contractItem) => {
        if (contractItem.uniqueContractId === itemId) {
          contractItem.active = !contractItem.active;
          dispatch(setSelectedContractDetails(contractItem));
        } else {
          contractItem.active = false;
        }
        return contractItem;
      });
      let body = {
        ActiveCustomerId: parentId,
        ActiveContractId: itemId,
      };
      setContractList(modifiedContractList);
      dispatch(setSelectedContractId(itemId));
      dispatch(setDefaultValues(body));
    }
  };

  const handleCustomerAllChecked = (e) => {

    let customerListParsed = JSON.parse(JSON.stringify(customerList));
    let contractListParsed = JSON.parse(JSON.stringify(contractList));
    let modifiedCustomerList = [...customerListParsed];
    let modifiedContractList = [...contractListParsed];
    if (e.checked) {
      modifiedCustomerList.map((item) => {
        item.active = false;
        item.disabled = true;
        return item;
      });
      modifiedContractList.map((contractItem) => {
        contractItem.active = false;
        contractItem.disabled = true;

        return contractItem;
      });
      dispatch(clearSelectedCustomerId(null));
      dispatch(clearSelectedContractId(null));
    } else {
      dispatch(clearSelectedCustomerId(null));
      dispatch(clearSelectedContractId(null));
      setContractList([]);
      dispatch(getCustomerList());
      modifiedCustomerList.map((item) => {
        item.disabled = false;


        return item;
      });

    }
    dispatch(setIsCustomerAll());
    dispatch(setIsContractAllFalse());
    setCustomerList(modifiedCustomerList);
    setContractList(modifiedContractList);
    setCustomerAllChecked(e.checked);
    dispatch(setInspectionSearchId(""));


  };

  const handleContractAllChecked = (e, parentId) => {
    let contractListParsed = JSON.parse(JSON.stringify(contractList));

    let modifiedContractList = [...contractListParsed];
    if (e.checked) {
      modifiedContractList.map((contractItem) => {
        contractItem.active = false;
        contractItem.disabled = true;
        dispatch(clearSelectedContractId(null));
        return contractItem;
      });
    } else {
      modifiedContractList.map((contractItem) => {
        contractItem.disabled = false;
        return contractItem;
      });
      dispatch(getContractList(parentId));
    }

    setContractList(modifiedContractList);
    dispatch(setIsContractAll());
    dispatch(setInspectionSearchId(""));
  };

  useEffect(() => {
    if (inputSite?.length >= 1) {
      dispatch(getSiteSearchDetails(inputSite));
    }
  }, [inputSite, dispatch]);
  useEffect(() => {
    if (inputCustomer?.length >= 1) {
      dispatch(getCustomerSearchDetails(inputCustomer));
    }
  }, [inputCustomer]);
  useEffect(() => {
    if (inputContract?.length >= 1) {
      dispatch(getContractSearchDetails(inputContract));
    }
  }, [inputContract]);

  useEffect(() => {
    if (loggedInUserData) {
      setSiteName(loggedInUserData.userDefaults?.siteName);
      setCountryId(loggedInUserData.userDefaults?.countryId);
    }
  }, [loggedInUserData]);
  useEffect(() => {
    if (Object.keys(siteDetails).length !== 0) {
      let site: { name: string; siteId: string }[] = [];
      siteDetails?.map((item: any) => {
        return site.push({ name: item.siteName, siteId: item.uniqueSiteId });
      });
      siteSearchRef.current = site;
    } else {
      siteSearchRef.current = [];
    }
  }, [siteDetails]);
  useEffect(() => {
    if (Object.keys(customerSearchDetails).length !== 0) {
      let customer: {
        customerName: string;
        uniqueCustomerId: string;
        active: boolean;
        customerNumber: string;
        customerNameNumber: string;
        disabled: boolean;
      }[] = [];
      customerSearchDetails?.map((item: any) => {
        return customer.push({
          customerName: item.customerName,
          uniqueCustomerId: item.uniqueCustomerId,
          active: item.isActive,
          customerNumber: item.customerNumber,
          customerNameNumber:
            item.customerName + " ( " + item.customerNumber + " )",
          disabled: false,
        });
      });
      setSearchCustomerList(customer);
    }
  }, [customerSearchDetails]);
  useEffect(() => {
    if (Object.keys(contractSearchDetails).length !== 0) {
      let contract: {
        contractName: string;
        uniqueCustomerId: string;
        customerName: string;
        uniqueContractId: string;
        active: boolean;
        contractNumber: string;
        disabled: boolean;
        contractCustomerName: string;
        contractNameNumber: string;
        customerNumber: string;
      }[] = [];
      contractSearchDetails?.map((item: any) => {
        return contract.push({
          contractName: item.contractName,
          uniqueCustomerId: item.uniqueCustomerId,
          customerName: item.customerName,
          uniqueContractId: item.uniqueContractId,
          active: item.isActive,
          contractNumber: item.contractNumber,
          disabled: false,
          contractCustomerName:
            item.contractName + " " + item.contractNumber,
          contractNameNumber: item.contractName + " ( " + item.contractNumber + " )",
          customerNumber: item.customerNumber,
        });
      });
      setSearchContractList(contract);
    }
  }, [contractSearchDetails]);
  const searchSites = (event: { query: string }) => {

    setTimeout(() => {
      let _filteredSearches;
      if (!event.query.trim().length) {
        _filteredSearches = [...siteSearchRef.current];
      } else {
        _filteredSearches = siteSearchRef.current.filter((search) => {
          return search.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }
      setFilteredSite(_filteredSearches);
    }, 500);
  };
  const searchCustomer = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredSearches;
      if (!event.query.trim().length) {
        _filteredSearches = [...searchCustomerList];
      } else {
        _filteredSearches = searchCustomerList.filter((search) => {
          return search.customerNameNumber
            .toLowerCase()
            .includes(event.query.toLowerCase());
        });
      }
      setFilteredCustomer(_filteredSearches);
    }, 250);
  };
  const searchContract = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredSearches;
      if (!event.query.trim().length) {
        _filteredSearches = [...searchContractList];
      } else {
        _filteredSearches = searchContractList.filter((search) => {
          return search.contractNameNumber
            .toLowerCase()
            .includes(event.query.toLowerCase());
        });
      }
      setFilteredContract(_filteredSearches);
    }, 250);
  };


  const handleCustomerContractSearchSelect = (value) => {
    if (checked) {
      if (value.active !== true) {
        handleCustomerClick(value);
        setTimeout(() => {
          if (customerAllChecked) {
            let e = {
              checked: false,
            }
            handleCustomerAllChecked(e)
          }
        }, 200)
      }
    } else {
      contractCustomerSearchClick(value);
    }
    dispatch(setInspectionSearchId(""));
  };
  const contractCustomerSearchClick = (selectedItem) => {
    let customerData = {
      uniqueCustomerId: selectedItem.uniqueCustomerId,
      customerName: selectedItem.customerName,
      active: selectedItem.active,
      customerNumber: selectedItem.customerNumber,
    };
    let contractData = {
      uniqueContractId: selectedItem.uniqueContractId,
      contractName: selectedItem.contractName,
      active: selectedItem.active,
      contractNumber: selectedItem.contractNumber,
    };
    let body = {
      ActiveCustomerId: customerData.uniqueCustomerId,
      ActiveContractId: contractData.uniqueContractId,
    };
    dispatch(setDefaultValues(body));
    setContractList([]);
    dispatch(setInspectionSearchId(""));
    setTimeout(() => {
      dispatch(getContractList(customerData.uniqueCustomerId));
      setContractAllChecked(false);
      let customerListParsed = JSON.parse(JSON.stringify(customerList));
      const modifiedList = customerListParsed.map((item) => {
        if (item.uniqueCustomerId === customerData.uniqueCustomerId) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      setCustomerList(modifiedList);
      dispatch(setSelectedCustomerId(customerData.uniqueCustomerId));
      dispatch(setSelectedContractId(contractData.uniqueContractId));
      dispatch(setIsContractAllFalse());
      dispatch(setSelectedCustomerDetails(customerData));


      let contractListParsed = JSON.parse(JSON.stringify(contractList));
      const modifiedContractList = contractListParsed.map((contractItem) => {
        if (contractItem.uniqueContractId === contractData.uniqueContractId) {
          contractItem.active = true;
          dispatch(setSelectedContractDetails(contractData));
        } else {
          contractItem.active = false;
        }
        return contractItem;
      });
      setContractList(modifiedContractList);
    }, 100);
  };
  const itemTemplate = (item: any) => {
    return (
      <div className="data-item">
        <div>{item.name}</div>
      </div>
    );
  };
  const setSelectedSiteAsActive = (data: any) => {
    dispatch(setSelectedCustomerId(""))
    dispatch(setSelectedContractId(""))
    dispatch(setSelectedCustomerDetails(null))
    dispatch(setSelectedContractDetails(null))
    dispatch(setAsActiveSiteForSite(data.siteId, "", ""));
    dispatch(setSelectedSite(data.siteId));
    setSiteName(data.name);
    dispatch(setInspectionSearchId(""))
    setFilteredSite(null);
    setFilterExpanded(false);
  };
  const onAddContractClick = (submitData: any) => {
    setIsAddContract(true);
    dispatch(
      onAddContract(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowAddContract,
        setShowGrid,
        true
      )
    );
  };

  const onAddCustomerClick = (submitData: any) => {
    dispatch(
      onAddCustomer(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowAddCustomer,
        setShowGrid,
        true
      )
    );
  };

  const mapCustomer = (submitData: any) => {
    dispatch(
      onMapCustomer(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowSiteMapCustomer
      ));
    setCustomerList([])
    setTimeout(() => {
      setIsMapCustomer(true)
    }, 500);
  };

  const onMapContract = (submitData: any) => {
    if (selectedCustomerDetails && selectedCustomerDetails.uniqueCustomerId) {
      const uniqueCustomerId = selectedCustomerDetails.uniqueCustomerId;
      setContractList([])
      dispatch(
        mapInspectionContract(
          uniqueCustomerId,
          submitData,
          setMessage,
          setDisplayInfoModal,
          setIsMapContract,
          setShowInspectionMapContract
        ));
    }
  }

  const onConfirmAction = () => {
    setDisplayConfirmModal(false);
    setShowAddContract(false);
    dispatch(setNewInspectionData(""));
    setShowAddInspectionTaskModal(true);
  };

  const onCancelAction = () => {
    setShowAddContract(false);
    setDisplayConfirmModal(false);
    setMessage("Contract added");
    setDisplayInfoModal(true);
  };

  return (
    <div className="inspection-filter-container">
      <div className="site-search">
        <MdOutlineSort onClick={() => setFilterExpanded(!filterExpanded)} />
        <AutoComplete
          className="search-site-filter m-0"
          value={inputSite}
          suggestions={filteredSite}
          completeMethod={searchSites}
          field="name"
          forceSelection
          itemTemplate={itemTemplate}
          onChange={(e) => setInputSite(e.value)}
          onSelect={(e) => setSelectedSiteAsActive(e.value)}
          aria-label="Search Site"
          placeholder="Search Site"
          minLength={3}
        />
      </div>

      <div className="selected-site">
        <IoLocationOutline />
        <span>{siteName}</span>
      </div>

      <div className="customer-search">
        <span className="p-input-icon-right">
          {!checked && <IoReaderOutline className="switch-left-icon" />}
          <InputSwitch
            checked={checked}
            onChange={(e) => setChecked(e.value)}
          />
          {checked && <IoPersonOutline className="switch-right-icon" />}
          <AutoComplete
            className="search-site-filter m-0"
            value={checked ? inputCustomer : inputContract}
            suggestions={checked ? filteredCustomer : filteredContract}
            completeMethod={checked ? searchCustomer : searchContract}
            field={checked ? "customerNameNumber" : "contractCustomerName"}
            onChange={(e) => {
              checked
                ? setInputCustomer(e.target.value)
                : setInputContract(e.target.value);
            }}
            placeholder={checked ? "Filter Customer" : "Filter Contract"}
            forceSelection
            onSelect={(e) => {
              handleCustomerContractSearchSelect(e.value);
            }}
            minLength={3}
          />
        </span>
      </div>

      {/* customer */}

      <div className="customer-list-container">
        <div className="add-section d-flex align-items-center justify-content-between mt-2">
          {showUI(userPrivileges, "SIETAP_INSPECTIONS_MAPCUSTOMER") ? (<span className="title" onClick={() => setShowSiteMapCustomer(true)}>
            Map Customer
          </span>) : (<span className="filterLabel" >Select Customer</span>)
          }
          <div className={`${showUI(userPrivileges, "SIETAP_INSPECTIONS_MAPCUSTOMER") ? "field-checkbox" : "field-checkbox filterCheckBox"}`}>
            <Checkbox
              inputId="customerAllChecked"
              checked={customerAllChecked}
              onChange={handleCustomerAllChecked}
            />
            <label htmlFor="customerAllChecked">All</label>
          </div>
          {(loggedInUserData?.userDefaults?.siteStatus === 'Active') && showUI(userPrivileges, "SIETAP_INSPECTIONS_ADDCUSTOMER") && <Button className="add-btn" onClick={() => setShowAddCustomer(true)} tooltip="Add Customer">
            <BsPlusLg className="icon" />
          </Button>
          }
        </div>
        {true ? (
          <div className="list-section my-2">
            <ul className="customer-list">
              {customerList.map((item: any) => {
                return (
                  <div key={item.uniqueCustomerId}>
                    <li
                      className={`customer-item ${item.disabled ? "disabled" : ""
                        }`}
                      role="button"
                      onClick={() => handleCustomerClick(item)}
                    >
                      {item.customerName}
                      <br />
                      ({item.customerNumber})
                      <span className="icon-group">
                        <i className="pi pi-angle-up" />
                        <i className="pi pi-angle-down" />
                      </span>
                    </li>
                    {item.active && (
                      //    contract

                      <Card className="contract-list-container my-2">
                        <div className="add-section d-flex align-items-center justify-content-between">
                          {showUI(userPrivileges, "SIETAP_INSPECTIONS_MAPCONTRACT") ? (<span
                            className="title"
                            onClick={() => setShowInspectionMapContract(true)}
                          >
                            Map Contract
                          </span>) : <span className="filterLabel">Select Contract</span>
                          }
                          <div className={`${showUI(userPrivileges, "SIETAP_INSPECTIONS_MAPCONTRACT") ? "field-checkbox" : "field-checkbox filterCheckBox"}`}>
                            <Checkbox
                              inputId="contractAllChecked"
                              checked={contractAllChecked}
                              onChange={(e) =>
                                handleContractAllChecked(
                                  e,
                                  item.uniqueCustomerId
                                )
                              }
                            />
                            <label htmlFor="contractAllChecked">All</label>
                          </div>
                          {(loggedInUserData?.userDefaults?.siteStatus === 'Active') && showUI(userPrivileges, "SIETAP_INSPECTIONS_ADDCONTRACT") &&
                            <Button
                              className="add-btn"
                              onClick={() => setShowAddContract(true)}
                              tooltip="Add Contract"
                            >
                              <BsPlusLg className="icon" />
                            </Button>
                          }
                        </div>
                        <ul className="contract-list mt-2">
                          {showGrid &&
                            contractList.map((contractItem: any) => (
                              item.uniqueCustomerId === contractItem.uniqueCustomerId &&
                              (<li className ={`${contractItem.status=== "Closed" ? "contract-item-closed": "contract-item"} ${contractItem.active ? "active" : ""
                                  } ${contractItem.disabled ? "disabled" : ""}`}
                                key={contractItem.uniqueContractId}
                                role="button"
                                onClick={() =>
                                  handleContractClick(
                                    item.uniqueCustomerId,
                                    contractItem.uniqueContractId
                                  )
                                }
                              >
                                {contractItem.contractName}
                              </li>)
                            ))}
                        </ul>
                      </Card>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
        ) : (
          <p>No Customer Exist</p>
        )}
      </div>
      {showAddContract && (
        <AddContract
          showModal={showAddContract}
          selectedCustomerId={selectedCustomerId}
          setShowModal={setShowAddContract}
          onAddContractClick={(data) => onAddContractClick(data)}
          isFromInspection={true}
        />
      )}
      {showAddCustomer && (
        <AddCustomer
          showModal={showAddCustomer}
          setShowModal={setShowAddCustomer}
          onAddCustomerClick={(data) => onAddCustomerClick(data)}
          isAddContract={true}
          userCountryId={countryId}
        />
      )}
      {showSiteMapCustomer && (
        <InspectionMapCustomer
          showModal={showSiteMapCustomer}
          setShowModal={setShowSiteMapCustomer}
          activeSite={activeSite}
          mapCustomer={mapCustomer}
        />
      )}
      {showInspectionMapContract && (
        <InspectionMapContract
          showModal={showInspectionMapContract}
          setShowModal={setShowInspectionMapContract}
          selectedCustomerDetails={selectedCustomerDetails}
          mapContract={(data) => onMapContract(data)}
        />
      )}
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />

      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={() => onCancelAction()}
        confirmAction={onConfirmAction}
        message={message}
      />
    </div>
  );
};

export default memo(InspectionTasksFilter);

