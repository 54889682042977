
import { Button } from 'primereact/button';
import React, { memo, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
//components
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Contract from './Contract/Contract';
import Question from './Question/Question';
import System from './System/System';
import { APP_ROUTES } from "constants/AppRoutes";
//redux
//icon
import { BsArrowRight } from "react-icons/bs";

interface InspectionFieldsPropTypes { }
const InspectionFields: React.FC<InspectionFieldsPropTypes> = (props) => {
    const { } = props;
    const questionRef = useRef<any>(null);
    const navigate = useNavigate();
    const { state } = useLocation() as any;
    const from = state?.from || 'contract'; 


    const setSelectedTab = (selectedItem) =>
    {
        navigate(`/${APP_ROUTES.INSPECTION_FIELDS}`, {
            state: {
                from: selectedItem
            }
        })

    }


    return (

        <div className='inspection-fields'>
            <section className="d-flex align-items-center">
                <Breadcrumb title="Inspection Settings" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>Inspection Fields</span>
                </div>
            </section>
            {/* header start */}
            <div className="header-wrapper">
                <div className="btn-wrapper-left">                   
                    <Button className={`box-btn ${from === "contract" && "active"}`} onClick={() => setSelectedTab('contract')} >Contract</Button>
                    <Button className={`box-btn ${from === "system" && "active"}`} onClick={() => setSelectedTab('system')}>System</Button>
                    <Button className={`box-btn ${from === "question" && "active"}`} onClick={() => setSelectedTab('question')}>Questions</Button>
                </div>
                <div className="btn-wrapper-right">
                    {from === "question" &&
                        <Button className="box-btn" onClick={() => questionRef?.current?.requestSubmit()}>Set as Default</Button>
                    }
                </div>
            </div>
            <>
                {from === 'contract' ? (
                    <Contract />
                ) : from === 'system' ? (
                    <System />
                ) : (
                    <Question ref={questionRef}/>
                )}
            </>
        </div>
    );
};

export default memo(InspectionFields);
