import React, { useCallback, useEffect, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { OverlayPanel } from "primereact/overlaypanel";
import { RadioButton } from "primereact/radiobutton";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FilePond, registerPlugin } from "react-filepond";
import { Controller, useForm } from "react-hook-form";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// redux
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../redux/hooks";
import Table from "components/Table/Table";
import UploadModal from "components/Table/UploadModal/UploadModal";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import PopOver from "components/common/PopOver/PopOver";
import { ATTACHMENT_LIST_COLS } from "./AttachmentListData";
import {  getAttachmentList, getAttachmentPath, onEditAttachment, onUploadBuildingAttachment, onDownloadAttachment, setAttachmentList, setAttachmentPageDetails } from "redux/pages/Administration/Building/buildingSlice";
import { baseURL } from "service/HttpService";
import ReportModal from "components/common/ReportModal/ReportModal";
import ViewFileModal from "components/common/ViewFileModal/ViewFileModal";
import { onExportReport } from "redux/common/report/customizeReportSlice";
import { getPermittedFiles } from "redux/common/commonSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  selectedSite: any;
}

export const BuildingAttachmentList: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedRows = [],
  selectedSite =[],
}) => {

  type FormData = {
    description: string;
    currentFileName:string;
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm<FormData>({
    defaultValues: {
      description: "",
      currentFileName:""
    },
  });

  const dispatch = useDispatch<any>();
  const downloadRef = React.createRef<OverlayPanel>();
  const attachmentList = useAppSelector((state) => state.building.attachmentList);
  const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
  const permittedFiles = useAppSelector((state) => state.common.permittedFiles);
  registerPlugin(FilePondPluginFileValidateType);

  const addForm: any = useRef();
  const editForm: any = useRef();
  const addFileRef: any = useRef();
  const editFileRef: any = useRef();

  const [pageIndex] = useState(0)
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false)
  const [replaceAttacment, setReplaceAttachment] = useState<boolean>(true);
  const [selectedRow, setSelectedRows] = useState<any>([]);
  const [attachmentListdetails] = useState({
    currentLength: 100,
    currentStart: 1,
    totalCount: 500,
  });
  const [enableUpload, setEnableUpload] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [isReportAll, setIsReportAll] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [streamData, setStreamData] = useState(null);
  const [reportParams, setReportParams] = useState({});
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [acceptedFileTypes, setAcceptedFileTypes] = useState(["allowed/extension"]);

  useEffect(() => {
    if (isDelete === true) {
      setMessage("Are you sure you want to delete the selected Attachment?");
      setDisplayConfirmModal(true);
      setIsDelete(false)
    }
  }, [isDelete])

  useEffect(() => {
    dispatch(getAttachmentList(selectedSite?.uniqueSiteId, selectedRows[0]?.uniqueBuildingId))

    return () => {
      dispatch(setAttachmentList([]));
      dispatch(setAttachmentPageDetails([]));
    };
  }, [dispatch])

  useEffect(() => {
    dispatch(getPermittedFiles())
  }, [dispatch])

  useEffect(() => {
    let fileTypes: any = ["allowed/extension"];
    permittedFiles.forEach(item => {
      if (item.extension1 !== null) {
        fileTypes.push(item.extension1)
      }
      if (item.extension2 !== null) {
        fileTypes.push(item.extension2)
      }
    });
    setAcceptedFileTypes(fileTypes);
  }, [permittedFiles]);

  useEffect(() => {
    setValue('description', selectedRow[0]?.description);
  }, [showEditModal])

  useEffect(() => {
    if (!showAddModal) {
      reset();
      resetFileUploadForm();
    }
  }, [showAddModal, setShowAddModal]);

  useEffect(() => {
    if (!showEditModal) {
      reset();
      resetFileEditForm();
    }
  }, [showEditModal, setShowEditModal]);

  const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1)
      sortDirection = "ASC"
    else
      sortDirection = "DESC"
      setSortField(event?.sortField);
      setSortOrder(sortDirection);
    dispatch(getAttachmentList(selectedSite?.uniqueSiteId, selectedRows[0]?.uniqueBuildingId, event?.sortField, sortDirection)) 
  }


  const editIconClick = () => {
    if (selectedRow.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRow.length === 1) {
      setShowEditModal(true)

    }
    else if (selectedRow.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }

  };

  const deleteIconClick = () => {
    if (selectedRow.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRow.length === 1) {
      setIsDelete(true);
    }
    else if (selectedRow.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }

  };


  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const handleRowSelect = useCallback((data: any) => {
    setSelectedRows([...data]);
  }, []);

  const onError = (errors: any, e: any) => {};

  const addFormSubmitHandler = (data: any) => {
    addForm.current.requestSubmit();
  };

  const resetFileUploadForm = () => {
    addFileRef?.current?.removeFiles();
    reset();
    setFiles([]);
  };

  const resetFileEditForm = () => {
    editFileRef?.current?.removeFiles();
    setFiles([]);
  };

  const onprocessfile = (error: any, file: any) => {
    setFiles([file]);
    if(error) {
      setEnableUpload(true);
    } else {
      setEnableUpload(false);
    }
  };

  const onprocessfiles = () => {
    setEnableUpload(false);
  };

  const beforeAddFile = (file) => {
    let data = addFileRef.current.props.files;
    if (data?.length > 0) {
      data.map((item: any) => {
        if (file?.filename === item.filename && file?.fileSize === item.fileSize) {
          addFileRef.current.removeFiles(item.id)
        }
      })
    }
    return true
  }

  const fileTypeValidation = (source: File, type: string) => new Promise<string>((resolve, reject) => {
    const uploadedFileExtension = source.name.split('.').pop();
    const isAllowed = acceptedFileTypes.find(fileType => fileType.split('.').pop() === uploadedFileExtension) !== undefined;
    if (isAllowed) {
      resolve('allowed/extension');
    } else {
      reject('.' + uploadedFileExtension);
    }
  });

  const onWarning = (error: any) => {
    if (error && error.body === 'Max files') {
      setDisplayInfoModal(true);
      setMessage('Maximum of 5 files can only be uploaded at a time.');
    }
  };

  const onRemoveFiles = (error: any, file: any) => {
    if (file?.status === 8 || file?.status === 6) {
      const reaminingFiles = addFileRef.current.getFiles();
      if (reaminingFiles.length) {
        let fileCount: number = 0;
        reaminingFiles.map((item: any) => {
          if (item.status !== 5) {
            fileCount += fileCount + 1;
          }
        });

        if (fileCount > 0) {
          setEnableUpload(true);
        } else {
          setEnableUpload(false);
        }
      } else {
        setEnableUpload(true);
      }
    }
  };

  const onFileError = (error: any, file?: any, status?: any) => {
    if(error) {
      setEnableUpload(true);
    }
  };

  const onProcessFileStart = (file: any) => {
    setEnableUpload(true);
  };

  useEffect(() => {
    if (!files?.length) setEnableUpload(true);
  }, [files]);

  const onAddFormSubmit = (data: any) => {
    if (files.length) {
      let tempFileIds: string = "";
      files.map((item: any) => {
        let response = JSON.parse(JSON.parse(JSON.stringify(item.serverId)));
        if (response?.status === "done") {
          tempFileIds += response.data?.uniqueDirectoryId[0] + ","
        }
      })
      tempFileIds = tempFileIds.slice(0, -1);
      let body = {
        "extension2": "",
        "description": data.description,
        TempStorageDirectory: tempFileIds
      };
      setEnableUpload(true);
      dispatch(onUploadBuildingAttachment(selectedRows[0]?.uniqueBuildingId,selectedRows[0],body, setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload));
    } else {
      setDisplayInfoModal(true);
      setMessage('Select a File to upload');
    }
  };

  const editFormSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const onEditFormSubmit = (data: any) => {
    if(!replaceAttacment){
      let body = {
        "description": data.description,
      };
      setEnableUpload(true);
      dispatch(onEditAttachment(selectedRows[0]?.uniqueBuildingId,selectedRow[0]?.attachmentId,body, setShowEditModal,setMessage,setDisplayInfoModal, setEnableUpload));
    }else{
      if (files.length && files[0]?.serverId) {
        let response = JSON.parse(JSON.parse(JSON.stringify(files[0]?.serverId)));
        if (response?.status === "done") {
          let body = {
            "description": data.description,
            "extension2": "",
            TempStorageDirectory: response.data?.uniqueDirectoryId[0],
          };
          setEnableUpload(true);
          dispatch(onEditAttachment(selectedRows[0]?.uniqueBuildingId, selectedRow[0]?.attachmentId, body, setShowEditModal, setMessage, setDisplayInfoModal, setEnableUpload));
        }
      } else {
        setDisplayInfoModal(true);
        setMessage('Select a File to upload');
      }
    }

  };

  const onDeleteConfirm=()=>{
    dispatch(getAttachmentPath(selectedSite?.uniqueSiteId, selectedRows[0]?.uniqueBuildingId,selectedRow[0]?.attachmentId,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows[0]))
  }

  const onDownload = () => {
    if (selectedRow.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRow.length === 1) {
      const viewFlag = false;
      dispatch(onDownloadAttachment(selectedSite?.uniqueSiteId, selectedRows[0]?.uniqueBuildingId, selectedRow[0]?.attachmentId, setDisplayInfoModal, setMessage, viewFlag, null, selectedRow[0]?.fileName))
    }
    else if (selectedRow.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
  }

  const onReportHighlighted=()=>{
    let reportParameters = {
      reportParameters: {
        UniqueSiteId: selectedSite?.uniqueSiteId,
        UniqueBuildingId: selectedRows[0]?.uniqueBuildingId,
        "UniqueFileIds":selectedRow.map(item=>item.attachmentId)
      },
      reportType: "Building Attachment Report",
    };
    setReportParams(reportParameters)
    setIsReportAll(false)
    setShowReportModal(true)
  }

  const onReportAll=()=>{
    let reportParameters = {
      reportParameters: {
        UniqueSiteId: selectedSite?.uniqueSiteId,
        UniqueBuildingId: selectedRows[0]?.uniqueBuildingId
      },
      reportType: "Building Attachment Report",
    };
    setReportParams(reportParameters)
    setIsReportAll(true)
    setShowReportModal(true)
  }

  const getSelectedAttachmentIds = () => {
    let params: any = [];
    selectedRow.map(item => {
      params.push(item.attachmentId);
    });
    return params;
  };

  const onExportAll = () => {
    const reportParameters = {
      exportParameters: {
        UniqueSiteId: selectedSite?.uniqueSiteId,
        UniqueBuildingId: selectedRows[0]?.uniqueBuildingId,
        UniqueFileIds: "",
        sortColumn: sortField,
        sortOrder: sortOrder
      },
      exportType: "Building Attachment Report"
    };
    dispatch(onExportReport(reportParameters, "Building Attachments"));
  };

  const onExportHighlightedClick = () => {
    const reportParameters = {
      exportParameters: {
        UniqueSiteId: selectedSite?.uniqueSiteId,
        UniqueBuildingId: selectedRows[0]?.uniqueBuildingId,
        sortColumn: sortField,
        sortOrder: sortOrder,
        UniqueFileIds: getSelectedAttachmentIds(),
      },
      exportType: "Building Attachment Report"
    };
    dispatch(onExportReport(reportParameters, "Building Attachments"));
  };

  const openViewModal = () => {
    if (selectedRow.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRow.length === 1) {
      const viewFlag = true;
      dispatch(onDownloadAttachment(selectedSite?.uniqueSiteId, selectedRows[0]?.uniqueBuildingId, selectedRow[0]?.attachmentId, setDisplayInfoModal, setMessage, viewFlag, setStreamData, selectedRow[0]?.fileName, setShowViewModal))
    }
    else if (selectedRow.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
  }

  return (
    <>
      <Dialog
        header="Attachment List"
        visible={showModal}
        style={{ width: "50vw" }}
        onHide={() => setShowModal(false)}
        className="display-settings personal selectsite"
      >
        <div className="header d-flex align-items-center">
          <span className="title">Building:</span>
          <span className="value">{selectedRows[0]?.buildingName}</span>
        </div>
        <Table
          rows={attachmentList}
          cols={ATTACHMENT_LIST_COLS.LIST_COLS}
          dataKeyId="attachmentId"
          title={``}
          pageDetails={attachmentListdetails}
          pageIndex={pageIndex}
          showDownloadIcon={true}
          showSettingIcon={true}
          onAddClick={() => setShowAddModal(true)}
          showHamIcon={false}
          onEditClick={editIconClick}
          onDeleteClick={deleteIconClick}
          showAddIcon
          showEditIcon
          showDeleteIcon
          searchboxfield={false}
          showroweditor={false}
          onSortData={onSort}
          getSelectedRowData={handleRowSelect}
          onDownloadClick={(e) => downloadRef.current?.toggle(e)}
          defaultSelectedRows = {attachmentList.length > 0 ? [attachmentList[0]] : []}
          settingListItems={[
            {
              id: 1,
              name: 'View',
              onClick: openViewModal
            },

          ]}
        />
        {showAddModal &&
          <UploadModal
            header="Upload Attachment"
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            OnSaveClick={addFormSubmitHandler}
            isAdd={false}
            style={{ width: "35vw", maxHeight: "95%" }}
            buttonDisable={enableUpload}
          >
            <form  onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <div className="flex align-items-center flex-column upload-file-section">
                    <div className="upload-detail" style={{ width: "100%", height: "50%" }}>
                      <FilePond
                        ref={addFileRef}
                        allowMultiple={true}
                        maxFiles={5}
                        files={files}
                        server={`${baseURL}api/v1/common/files/upload`}
                        onprocessfiles={onprocessfiles}
                        onupdatefiles={setFiles}
                        beforeAddFile={beforeAddFile}
                        onwarning={onWarning}
                        onremovefile={onRemoveFiles}
                        onerror={onFileError}
                        onprocessfilestart={onProcessFileStart}
                        name="files"
                        credits={false}
                        labelIdle='Click to choose a file or drag and drop a file'
                        labelFileLoading=""
                        labelTapToUndo=""
                        labelFileProcessing=""
                        labelFileProcessingComplete=""
                        labelFileProcessingAborted=""
                        labelFileProcessingError=""
                        labelButtonAbortItemProcessing=""
                        labelButtonUndoItemProcessing=""
                        labelButtonRetryItemProcessing=""
                        labelButtonProcessItem=""
                        labelTapToCancel=""
                        labelFileRemoveError=""
                        labelTapToRetry=""
                        allowFileTypeValidation={true}
                        acceptedFileTypes={acceptedFileTypes}
                        fileValidateTypeDetectType={fileTypeValidation}
                        fileValidateTypeLabelExpectedTypes={'You do not have privilege to add attachment of this File Type'}
                      />
                    </div>
                  </div>
                </span>
              </div>

              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Controller name="description" control={control} rules={{
                    maxLength: 4000
                  }} render={({ field, fieldState }) => (
                    <InputTextarea id="Textarea" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                  )} />
                  <label htmlFor="inputtext">Description</label>
                  {getFormErrorMessage('description')}
                  <span className='textarea-txt-count'>Max 4000 Chars</span>
                </span>
              </div>
            </form>
          </UploadModal>
        }
        {showEditModal &&
          <UploadModal
            header="Upload Attachment"
            showModal={showEditModal}
            setShowModal={setShowEditModal}
            OnSaveClick={editFormSubmitHandler}
            isAdd={false}
            style={{ width: "35vw", maxHeight: "95%" }}
            buttonDisable={replaceAttacment?enableUpload:false}
          >
            <form  onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
              <div className="field col-12 md:col-4 mb-0 pb-0">
                <label htmlFor="radiobutton">
                  Do you want to replace the current Attachment ?</label>
                <div className='radio-section d-flex align-items-center pt-2'>
                  <div className="field-radiobutton">
                    <RadioButton disabled={false} inputId="replaceYes" name="replace" value={true} onChange={(e) => setReplaceAttachment(e.value)} checked={replaceAttacment} />
                    <label htmlFor="replace">Yes</label>
                  </div>
                  <div className="field-radiobutton pl-2">
                    <RadioButton disabled={false}
                      inputId="replaceNo"
                      name="replace"
                      value={false}
                      onChange={(e) => {
                        setReplaceAttachment(e.value);
                        setFiles([]);
                      }}
                      checked={!replaceAttacment} />
                    <label htmlFor="replace">No</label>
                  </div>
                </div>
              </div>
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label text-container">
                  <label htmlFor="inputtext">Current File Name</label>
                  <span className="text-value">
                    <b>{selectedRow[0]?.fileName}</b>
                  </span>
                </span>
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <div className="flex align-items-center flex-column upload-file-section">
                    <div className="upload-detail" style={{ width: "100%", height: "50%" }}>
                      {replaceAttacment?<FilePond
                        ref={editFileRef}
                        allowMultiple={false}
                        maxFiles={1}
                        server={`${baseURL}api/v1/common/files/upload`}
                        onprocessfile={onprocessfile}
                        onupdatefiles={setFiles}
                        onerror={onFileError}
                        onprocessfilestart={onProcessFileStart}
                        name="files"
                        credits={false}
                        labelIdle='Click to choose a file or drag and drop a file'
                        labelFileLoading=""
                        labelTapToUndo=""
                        labelFileProcessing=""
                        labelFileProcessingComplete=""
                        labelFileProcessingAborted=""
                        labelFileProcessingError=""
                        labelButtonAbortItemProcessing=""
                        labelButtonUndoItemProcessing=""
                        labelButtonRetryItemProcessing=""
                        labelButtonProcessItem=""
                        labelTapToCancel=""
                        labelFileRemoveError=""
                        labelTapToRetry=""
                        allowFileTypeValidation={true}
                        acceptedFileTypes={acceptedFileTypes}
                        fileValidateTypeDetectType={fileTypeValidation}
                        fileValidateTypeLabelExpectedTypes={'You do not have privilege to add attachment of this File Type'}
                      />:null}
                    </div>
                  </div>
                </span>
              </div>

              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Controller name="description" control={control} rules={{
                    maxLength: 4000
                  }} render={({ field, fieldState }) => (
                    <InputTextarea id="Textarea" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                  )} />
                  <label htmlFor="inputtext">Description</label>
                  {getFormErrorMessage('description')}
                  <span className='textarea-txt-count'>Max 4000 Chars</span>
                </span>
              </div>
            </form>
          </UploadModal>
        }
        <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
        />
        <ConfirmationBox
          showConfirmModal={displayConfirmModal}
          setShowConfirmModal={setDisplayConfirmModal}
          confirmAction={() =>{onDeleteConfirm()}}
          message={message}
        />
        <PopOver ref={downloadRef}>
          <ul>
            <li onClick={onReportAll}>Report All</li>
            <li onClick={onReportHighlighted}>Report Highlighted</li>
            <li onClick={onExportAll}>Export All</li>
					  <li onClick={onExportHighlightedClick}>Export Highlighted</li>
            <li onClick={onDownload}>Download</li>
          </ul>
        </PopOver>
      </Dialog>
      {showReportModal&&
        <ReportModal
          showModal={showReportModal}
          setShowModal={setShowReportModal}
          selectedRow={selectedRow}
          isReportAll={isReportAll}
          reportPreviewSession={reportPreviewSession}
          reportType={"Building Attachment Report"}
          title={"Attachment Report"}
          siteId={selectedSite?.uniqueSiteId}
          reportParams={reportParams}/>
      }
      {showViewModal&&streamData&&
        <ViewFileModal showModal={showViewModal} setStreamData={setStreamData} setShowModal={setShowViewModal} streamData={streamData} fileName={selectedRow[0]?.fileName}/>
      }
    </>
  );
};

export default BuildingAttachmentList;
