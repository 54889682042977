import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../service/HttpService";
import { AppThunk } from "../../store";
import { getActiveInspectionTasks } from "../Inspection/InspectionTask/inspectionTaskSlice";

export interface dashboardState {
  errors: any;
  reportSummary: any;
  equipmentSummary: any;
  failedEquipments: any;
  inspectionDetails: any;
  contractDetails: any;
  systemTypes: any;
  selectedFailedEquipment: any;
}

let initialState: dashboardState = {
  errors: "",
  reportSummary: [],
  equipmentSummary: [],
  failedEquipments: {},
  inspectionDetails: [],
  contractDetails: [],
  systemTypes: [],
  selectedFailedEquipment: ""
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setReportSummary: (state, { payload }: PayloadAction<any>) => {
      state.reportSummary = payload;
    },
    setEquipmentSummary: (state, { payload }: PayloadAction<any>) => {
      state.equipmentSummary = payload;
    },
    setFailedEquipments: (state, { payload }: PayloadAction<any>) => {
      state.failedEquipments = payload;
    },
    setInspectionDetails: (state, { payload }: PayloadAction<any>) => {
      state.inspectionDetails = payload;
    },
    setContractDetails: (state, { payload }: PayloadAction<any>) => {
      state.contractDetails = payload;
    },
    setSystemTypes: (state, { payload }: PayloadAction<any>) => {
      state.systemTypes = payload;
    },
    setSelectedFailedEquipment: (state, { payload }: PayloadAction<any>) => {
      state.selectedFailedEquipment = payload
    }
  }
});

export const getReportsGenerated = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/dashboard/reportsgenerated`);
    if (res.status === "done") {
      if (res.data && res.data.reportGeneratedSummary) {
        if (res.data.reportGeneratedSummary && res.data.reportGeneratedSummary.length) {
          res.data.reportGeneratedSummary.forEach(element => {
            element['Completed'] = element.completed ? element.completed : '0.00';
            element['Drafted'] = element.drafted ? element.drafted : '0.00';
            element['No Actions'] = element.noActions ? element.noActions : '0.00';
            element['Percent Completed'] = element.completed ? (element.completed + "%") : '0.00%';
            element['Percent Drafted'] = element.drafted ? (element.drafted + "%") : '0.00%';
            element['Percent No Actions'] = element.noActions ? (element.noActions + "%") : '0.00%';
          });
        }
        dispatch(setReportSummary(res.data.reportGeneratedSummary));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentSummary = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/dashboard/equipmentsummary`);
    if (res.status === "done") {
      if (res.data && res.data.equipmentSummary) {
        if (res.data.equipmentSummary && res.data.equipmentSummary.length) {
          res.data.equipmentSummary.forEach(element => {
            element['Passed'] = element.passed ? element.passed : '0.00';
            element['Failed'] = element.failed ? element.failed : '0.00';
            element['Untested'] = element.untested ? element.untested : '0.00';
            element['Percent Passed'] = element.passed ? (element.passed + "%") : '0.00%';
            element['Percent Failed'] = element.failed ? (element.failed + "%") : '0.00%';
            element['Percent Untested'] = element.untested ? (element.untested + "%") : '0.00%';
          });
        }
        dispatch(setEquipmentSummary(res.data.equipmentSummary));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const closeInspectionTasks = (
  body: any, 
  currentStart: any, 
  isrequiredquitting: boolean, 
  setSelectedRows: any, 
  setSelectAll: any, 
  setMessage: any, 
  setDisplayConfirmModal: any, 
  setDisplayInfoModal: any, 
  setCloseTaskInProgress: any): AppThunk => async (dispatch) => {
    try {
      let requestUrl = `${baseURL}api/v1/dashboard/inspectiontasks/close`;
      if (body.isChecked) {
        requestUrl += `?isChecked=${body.isChecked}`
      }
      const res = await zlink.post(`${requestUrl}`, body);
      if (res.status === "done") {      
        if (body.isChecked) {
          dispatch(getActiveInspectionTasks(1, '', '', false, false));
        } else {
          setSelectedRows([]);
          setSelectAll(false);
          setCloseTaskInProgress(false);
          dispatch(getActiveInspectionTasks(currentStart, '', '', false, isrequiredquitting));
        }
        setDisplayConfirmModal(false);
        setMessage(res.message ? res.message : 'Inspection Task(s) closed');
        setDisplayInfoModal(true);
      } else {
        if (res.message) {
          setMessage(res.message);
          setDisplayInfoModal(true);
        }
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
};

export const getFailedEquipments = (siteId: any, currentStart: any, sortField: any, sortDirection: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/dashboard/sites/${siteId}/failedequipments`;
    if (currentStart) {
      requestUrl += `?currentstart=${currentStart}`
    }
    if (sortField && sortDirection) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortDirection}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setFailedEquipments(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionDetails = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/dashboard/inspectiondetails`);
    if (res.status === "done") {
      if (res.data && res.data.inspectionDetails) {
        if (res.data.inspectionDetails && res.data.inspectionDetails.length) {
          res.data.inspectionDetails.forEach(element => {
            element['Active'] = element.active ? element.active : '0.00';
            element['Closed'] = element.closed ? element.closed : '0.00';
            element['Completed'] = element.completed ? element.completed : '0.00';
            element['Inactive'] = element.inactive ? element.inactive : '0.00';
            element['activeCount'] = element.activeCount ? element.activeCount : '0';
            element['closeCount'] = element.closeCount ? element.closeCount : '0';
            element['completedCount'] = element.completedCount ? element.completedCount : '0';
            element['inactiveCount'] = element.inactiveCount ? element.inactiveCount : '0';
          });
        }
        dispatch(setInspectionDetails(res.data.inspectionDetails));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getContractDetails = (isProjectContract?:boolean): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/dashboard/contracts?isProjectContracts=${isProjectContract}`);
    if (res.status === "done") {
      if (res.data && res.data.contractDetails) {
        if (res.data.contractDetails && res.data.contractDetails.length) {
          res.data.contractDetails.forEach(element => {
            element['Inspection Contracts (Used)'] = element.inspectionContracts ? element.inspectionContracts : '0.00';
            element['SAP Contracts (Opportunity)'] = element.sapContracts ? element.sapContracts : '0.00';
            element['Percent Inspection Contracts (Used)'] = element.inspectionContracts ? (element.inspectionContracts + "%") : '0.00%';
            element['Percent SAP Contracts (Opportunity)'] = element.sapContracts ? (element.sapContracts + "%") : '0.00%';
          });
        }
        dispatch(setContractDetails(res.data.contractDetails));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getSystemTypes = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/dashboard/systemtypes`);
    if (res.status === "done") {
      if (res.data) {
        if (res.data.panelTypes && res.data.panelTypes.length) {
          res.data.panelTypes.forEach(element => {
            if (typeof element.usage === 'string') {
              element.usage = +element.usage;
            }
          });
        }
        dispatch(setSystemTypes(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setReportSummary,
  setEquipmentSummary,
  setFailedEquipments,
  setInspectionDetails,
  setContractDetails,
  setSystemTypes,
  setSelectedFailedEquipment
} = dashboardSlice.actions;

export default dashboardSlice.reducer;