import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";
import { getEquipmentPanelList, setEquipmentPanelList } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
}

type FormData = {
    sortBy: string;
    equipmentGroup: number;
};

export const EquipmentSummary: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRows = [],
}) => {

    const {
        control,
    } = useForm<FormData>({
        defaultValues: {
            sortBy: "NFPA Classification",
            equipmentGroup: 0
        },
    });

    const [equipmentGroup, setEquipmentGroup] = useState(0);
    const [reportId, setReportId] = useState<any>(null);
    const [sortColumn, setSortColumn] = useState<any>("NFPA Classification");
    const [addAll] = useState<any>(false);

    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const equipmentPanelList = useAppSelector((state) => state.equipment.equipmentPanelList);
    const dispatch = useDispatch<any>();

    const [sortOptions] = useState<any>([
        { title: 'NFPA Classification', value: 'NFPA Classification' },
        { title: 'Health Classification', value: 'Health Classification' }
    ]);

    useEffect(() => {
        dispatch(getEquipmentPanelList(selectedRows[0]?.uniqueInspectionTaskId, addAll));
    }, []);

    useEffect(() => {
        const reportParameters = {
            reportParameters: {
                uniqueCustomerId: selectedRows[0]?.uniqueCustomerId,
                uniqueInspectionTaskId: selectedRows[0]?.uniqueInspectionTaskId,
                classification: sortColumn,
                equipmentGroup: equipmentGroup
            },
            reportType: "INSPECTIONTASK_EQUIPMENT_SUMMARY"
        };
        dispatch(createReportPreviewSession(reportParameters));

        return (() => {
            dispatch(setReportPreviewSession(""));
        })
    }, [sortColumn, equipmentGroup]);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession]);

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                    dispatch(setEquipmentPanelList(""));
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-sort-field pb-0">
                    <div className="report-export-main d-flex mb-2">
                        <div className="row px-2">
                            <div className="report-field col-12 md:col-2">
                                <div className="field  mb-0">
                                    <span className="p-float-label">
                                        <Controller
                                            name="sortBy"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    inputId="classificationDropdown"
                                                    className={classNames("w-100 error-tooltip", {
                                                        error: fieldState.invalid,
                                                    })}
                                                    {...field}
                                                    value={sortColumn}
                                                    onChange={(e) => {
                                                        setSortColumn(e.value);
                                                    }}
                                                    options={sortOptions}
                                                    optionLabel="title"
                                                    optionValue="value"
                                                />
                                            )}
                                        />
                                        <label htmlFor="classificationDropdown">Classification Type</label>
                                    </span>
                                </div>
                            </div>
                            <div className="report-field col-12 md:col-2">
                                <div className="field  mb-0">
                                    <span className="p-float-label">
                                        <Controller
                                            name="equipmentGroup"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    inputId="equipmentDropdown"
                                                    className={classNames("w-100 error-tooltip", {
                                                        error: fieldState.invalid,
                                                    })}
                                                    {...field}
                                                    value={equipmentGroup}
                                                    onChange={(e) => {
                                                        setEquipmentGroup(e.value);
                                                    }}
                                                    options={[{ equipmentId: 0, name: "All" }, ...equipmentPanelList]}
                                                    optionLabel="name"
                                                    optionValue="equipmentId"
                                                />
                                            )}
                                        />
                                        <label htmlFor="equipmentDropdown">Equipment Group</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="report-buttons-main d-flex">
                        </div>
                    </div>
                </div>

                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default EquipmentSummary;
