import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { ListGroup } from 'react-bootstrap';
import ProjectDetails from "./ReportComponents/ProjectDetails";
import Goals from "./ReportComponents/Goals";
import SystemDetails from "./ReportComponents/SystemDetails";
import {Comments} from "./ReportComponents/Comments";
import { getBranches, onGenerateReport, setNewlyAddedData, setProjectDetails,onDependencyCheck, setSystemTaskList, setcheckIsValueChanged, setTabChangeMessageHandler, checkTapPerformaceReportGenerated } from "redux/pages/Reports/ProvenOutcomesSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";


interface ReportWizardProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  isAdd:boolean;
  selectedProject: any;
  isGenerate:boolean;
  setIsGenerate :any;
}

const Report_Tab_List = [
  { name: "Project Details", code: "PD", selected: true },
  { name: "Goals", code: "G", selected: false },
  { name: "System Details", code: "SD", selected: false },
  { name: "Comments", code: "C", selected: false },
];
export const AddReportWizard: React.FC<ReportWizardProps> = ({
  showModal = false,
  setShowModal = () => { },
  isAdd = false,
  selectedProject = "",
  isGenerate = false,
  setIsGenerate = () => { },
}) => {

  const projectDetailsForm = useRef<any>(null);
  const commentDetailsForm = useRef<any>(null);
  const addForm = useRef<any>(null);


  const [reportTabList, setReportTabList] = useState<Array<any>>(Report_Tab_List);
  const [selectedItem, setSelectedItem] = useState<any>("Project Details");
  const [showProjectDetails, setShowProjectDetails] = useState<any>(true)
  const [showGoals, setShowGoals] = useState<any>(false)
  const [showSystemDetails, setShowSystemDetails] = useState<any>(false)
  const [showComments, setShowComments] = useState<any>(false)
  const [buttonName, setbuttonName] = useState<any>("Save")
  const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState<any>(false)
  const [isDisabled, setIsDisabled] = useState(true);
  const [reportGenerateDependencyData, setReportGenerateDependencyData] = useState<any>();
  const dispatch = useDispatch<any>();
  const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
  const newlyAddedData = useAppSelector((state) => state.provenOutcomes.newlyAddedData);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [closeBtnClicked, setCloseBtnClicked] = useState<boolean>(false);
  const checkIsValueChanged = useAppSelector((state) => state.provenOutcomes.checkIsValueChanged);
  const [newSelectedItem, setNewSelectedItem] = useState<any>("Project Details");
  const tabChangeMessageHandler = useAppSelector((state) => state.provenOutcomes.tabChangeMessageHandler);
  const tabChangeMessage = useAppSelector((state) => state.provenOutcomes.tabChangeMessage);

  const handleTabSelect = useCallback((selectedItem) => {
    setSelectedItem(selectedItem.name);
    const modifiedReportTabList = reportTabList.map((item) => {
      if (item.code === selectedItem.code) {
        item["selected"] = true;
      } else {
        item["selected"] = false;
      }
      return item;
    });
    setReportTabList(modifiedReportTabList);

  }, [reportTabList]);

  const changehandleTabSelect =(selectedItem) =>{
    setNewSelectedItem(selectedItem.name);
    handleTabSelect(selectedItem);
  }

  useEffect(() => {
    if(displayInfoModal === true)
    {
      dispatch(setTabChangeMessageHandler(false))
      setDisplayInfoModal(true)
    }
  }, [displayInfoModal])

  useEffect(() => {
   setMessage(tabChangeMessage)
   setDisplayInfoModal(tabChangeMessageHandler)
  }, [tabChangeMessage])

  useEffect(() => {
    if(displayInfoModal === false)
    {
    setMessage(tabChangeMessage)
    setDisplayInfoModal(tabChangeMessageHandler)
    }
   }, [tabChangeMessageHandler])

  useEffect(() => {
    if (displayConfirmModal && !isAdd && !isGenerate ) {
      if (showProjectDetails) {
        let nextTAb = { name: "Project Details", code: "PD", selected: true }
        handleTabSelect(nextTAb)
        setMessage("Do you want to save the Project Details?");
      }
      else if (showGoals) {
        let nextTAb = { name: "Goals", code: "G", selected: false }
        handleTabSelect(nextTAb)
        setMessage("Do you want to save the Goals?");
      }
      else if (showComments) {
        let nextTAb = { name: "Comments", code: "C", selected: false }
        handleTabSelect(nextTAb)
        setMessage("Do you want to save the Comments?");
      }
    }
    else {
      switch (newSelectedItem) {
        case "Project Details":
          let PDTAb = { name: "Project Details", code: "PD", selected: true }
          setSelectedItem(PDTAb)
          handleTabSelect(PDTAb)
          break;
        case "Goals":
          let goalTAb = { name: "Goals", code: "G", selected: false }
          setSelectedItem(goalTAb)
          handleTabSelect(goalTAb)
          break;
        case "System Details":
          let nextTAb = { code: "SD", name: "System Details", selected: false }
          setSelectedItem(nextTAb)
          handleTabSelect(nextTAb)
          break;
        case "Comments":
          let CTAb = { code: "C", name: "Comments", selected: false }
          setSelectedItem(CTAb)
          handleTabSelect(CTAb)
          break;
        default:
          break;
      }
    }
  }, [displayConfirmModal]);
  const onConfirmClick = () => {
    if (showProjectDetails && !isGenerate) {
      setDisplayConfirmModal(false);
      projectFormSubmitHandler();
      setNewSelectedItem(newSelectedItem);
    }
    else if (showGoals && !isGenerate){
      goalFormSubmitHandler();
      setDisplayConfirmModal(false);
      setNewSelectedItem(newSelectedItem);
    }
     
    else if (showComments && !isGenerate)  {
      commentFormSubmitHandler();
      setDisplayConfirmModal(false);
      setNewSelectedItem(newSelectedItem);
    }
    else if(isGenerate){
      if (newlyAddedData) {
        dispatch(
          onDependencyCheck(
            newlyAddedData?.uniqueProjectId,
            setReportGenerateDependencyData,
          ))
          setIsGenerate(false);
			setDisplayConfirmModal(false);
      }
      else {
        dispatch(
          onDependencyCheck(
            selectedProject?.uniqueProjectId,
            setReportGenerateDependencyData,
          ))
          setIsGenerate(false);
			setDisplayConfirmModal(false);
      }
    }
  }
  useEffect(() => {
    if (!displayConfirmModal) {
      dispatch(setcheckIsValueChanged(false))
    }
  }, [displayConfirmModal]);

  useEffect(() => {
    if (reportGenerateDependencyData) {
      if (!reportGenerateDependencyData?.isReadyToGenerate) {
        setDisplayInfoModal(true);
        setMessage(reportGenerateDependencyData?.message);
      }
      else if (reportGenerateDependencyData?.isReadyToGenerate) {
        let uniqueProjectId: any;
    if(isAdd){
      uniqueProjectId = newlyAddedData?.uniqueProjectId;
    }
    else{
      uniqueProjectId = selectedProject?.uniqueProjectId;
    }
    dispatch(onGenerateReport(uniqueProjectId,setMessage,setDisplayInfoModal));	

      }
    }

  }, [reportGenerateDependencyData]);

  useEffect(() => {
    if(!isAdd) {setIsDisabled(false)}
    dispatch(getBranches());
    if(checkIsValueChanged && !isAdd ) {
      setDisplayConfirmModal(true);
  } 
    else{
      switch (selectedItem) {
      case "Project Details":
        setShowComments(false)
        setShowProjectDetails(true)
        setShowSystemDetails(false)
        setShowGoals(false)
        if(newlyAddedData)
        setbuttonName("Next")
        else if(isAdd)
        setbuttonName("Save")
        else
        setbuttonName("Update")
        break;
      case "Goals":
        setShowComments(false)
        setShowProjectDetails(false)
        setShowSystemDetails(false)
        setShowGoals(true)
        setbuttonName("Next")
        break;
      case "System Details":
        setShowComments(false)
        setShowProjectDetails(false)
        setShowSystemDetails(true)
        setShowGoals(false)
        setbuttonName("Next")
        break;
      case "Comments":
        setShowComments(true)
        setShowProjectDetails(false)
        setShowSystemDetails(false)
        setShowGoals(false)
        setbuttonName("Save")
        break;
      default:
        break;
    }}
    
  }, [selectedItem]);

  useEffect(() => {
    if (newlyAddedData && isAdd) {
      setbuttonName("Next")
      setIsDisabled(false)
    }
    else if (newlyAddedData)
    {
      setIsDisabled(false)
    }
  }, [newlyAddedData]);

  const onSave = (selectedItem) => {
    switch (selectedItem) {
      case "Project Details": 
        if (buttonName == "Save" || buttonName == "Update") {
          projectFormSubmitHandler();
        }
        else if (buttonName == "Next") {
          let nextTAb = { name: "Goals", code: "G", selected: false }
          setSelectedItem(nextTAb)
          handleTabSelect(nextTAb)
        }

        break;
      case "Goals":
        let nextTAb = { code: "SD", name: "System Details", selected: false }
        setSelectedItem(nextTAb)
        handleTabSelect(nextTAb)

        break;
      case "System Details":
        let CTAb = { code: "C", name: "Comments", selected: false }
        setSelectedItem(CTAb)
        handleTabSelect(CTAb)

        break;
      case "Comments":
        commentFormSubmitHandler();

        break;
      default:
        break;
    }
    setDisplayConfirmModal(false);
    dispatch(setcheckIsValueChanged(false))
  }

  const projectFormSubmitHandler = () => {
    projectDetailsForm?.current?.requestSubmit();
  }
  const commentFormSubmitHandler = () => {
    commentDetailsForm?.current?.requestSubmit();
  }
  const goalFormSubmitHandler = () => {
    addForm?.current?.requestSubmit();
  }
  const onGenerateReportProvenOutcomes = () => {
    let uniqueProjectId: any;
    if(isAdd){
      uniqueProjectId = newlyAddedData?.uniqueProjectId;
    }
    else{
      uniqueProjectId = selectedProject?.uniqueProjectId;
    }
    setIsGenerate(true);
    dispatch(checkTapPerformaceReportGenerated(uniqueProjectId));

    }
    const OnCancel = () => {
      if(checkIsValueChanged && !isAdd){
        setDisplayConfirmModal(true);
        setCloseBtnClicked(true);
      }else{
        setShowModal(false)
        dispatch(setSystemTaskList(""));
        dispatch(setProjectDetails(""));
        dispatch(setNewlyAddedData(""))
        setbuttonName("Next")
        handleTabSelect({ name: "Project Details", code: "PD", selected: true })
        setDisplayConfirmModal(false);
        dispatch(setcheckIsValueChanged(false))      
      }
    }

  const cancelUpdate = () => {
    if (closeBtnClicked) {
      setDisplayConfirmModal(false);
      dispatch(setcheckIsValueChanged(false))
      hideDialog();
    }
    else{  
      setDisplayConfirmModal(false);
      dispatch(setcheckIsValueChanged(false))}
  
  };
  const hideDialog = () => {
    setShowModal(false);
  }
  const hideConfirmModal = () => {
    setDisplayConfirmModal(false);
    dispatch(setcheckIsValueChanged(false));
  };

  return (
    <>
{showModal &&
     <div className="wizard-modal xl">
        <div className="wizard-header">
          <h5>{loggedInUserData.userDefaults.siteName} </h5>
          <div className="actions">
            <span className="close" onClick={()=> OnCancel()}>
              <i className="pi pi-times" onClick={()=> OnCancel()}></i>
            </span>
          </div>
        </div>
        <div className="wizard-dialog">
          <div className="wizard-form">
            <div className="step-name tab-control">
              <ListGroup>
                {reportTabList.map((item) => (
                  <ListGroup.Item
                    key={item.code}
                    disabled = {isDisabled}
                    className={item.selected ? "active" : ""}
                    onClick={() => changehandleTabSelect(item)}
                  >
                    <span >{item.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
            <div className="step-wrapper">
              <div className="wizard-control">
                  <>
                    <Button
                      label="Generate"
                      className="generate-btn"
                      hidden={isDisabled}
                      onClick={onGenerateReportProvenOutcomes}
                    /> 
                  </>
                </div>
                <div className="steps">
                  {showProjectDetails &&
                    <ProjectDetails
                      ref={projectDetailsForm}
                      isAdd={isAdd}
                      selectedProjectId={selectedProject.uniqueProjectId}
                    />}
                  {showGoals && <Goals selectedProjectId={selectedProject.uniqueProjectId}
                   ref={addForm}/>}
                  {showSystemDetails && <SystemDetails  selectedProjectId={selectedProject.uniqueProjectId} isAdd ={isAdd}/>}
                  {showComments && <Comments  
                                    selectedProjectId={selectedProject.uniqueProjectId}
                                    isAdd={isAdd}
                                    ref={commentDetailsForm}
                  />}
                </div>
              <div className="wizard-footer">
                  <Button label="Cancel" className="cancel-btn" onClick={() => OnCancel()} />
                  <Button label={buttonName} className="submit-btn" onClick={() => onSave(selectedItem)} />
                </div>
                <InformationBox               
                  showInfoModal={displayInfoModal}
                  setShowInfoModal={setDisplayInfoModal}
                  message={message}
                />
                <ConfirmationBox
                    showConfirmModal={displayConfirmModal}
                    setShowConfirmModal={setDisplayConfirmModal}
                    cancelAction={cancelUpdate}
                    onHideAction={hideConfirmModal}
                    confirmAction={onConfirmClick}
                    message={message}
                    />
              </div>
            </div>
        </div>
      </div>
      }
    </>
  );
};

export default AddReportWizard;

