import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "redux/hooks";
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { ListGroup } from 'react-bootstrap';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import CustomizeReport from './CustomizeReport/CustomizeReport';
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";
import { onExportReport, setIsAppliedDone } from 'redux/common/report/customizeReportSlice';
//icons
import { BsArrowRight } from "react-icons/bs";
import { RiFileExcel2Line } from 'react-icons/ri';

const InspectionTaskReport = () => {
	const [reportName, setReportName] = useState<any>(null);
	const [startDate, setStartDate] = useState<any>(new Date().setDate((new Date()).getDate() - 30));
	const [endDate, setEndDate] = useState<any>(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0));
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [showCustomizeReport, setShowCustomizeReport] = useState(false);
	const [customizationType, setCustomizationType] = useState('user');

	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
	const isAppliedDone = useAppSelector((state) => state.customizeReport.isAppliedDone);

	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	const viewInspectionTaskReport = () => {
		const reportParameters = {
			reportParameters: {
				"startDate": moment(startDate).format("yyyy-MM-DD").toString(), 
				"endDate": moment(endDate).format("yyyy-MM-DD").toString(),
				customizationType: customizationType
			},
			reportType: "Inspection Task Details Report"
		};
		dispatch(createReportPreviewSession(reportParameters));
	};

	useEffect(() => {
		viewInspectionTaskReport();
		
		return (() => {
			dispatch(setReportPreviewSession(""));
		})
	}, []);

	useEffect(() => {
		if (isAppliedDone) viewInspectionTaskReport();
	}, [isAppliedDone]);

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	useEffect(() => {
		if (isAppliedDone) {
			dispatch(setIsAppliedDone(false));
			setShowCustomizeReport(false);
			setMessage("Report settings saved");
			setDisplayInfoModal(true);
		}
	}, [isAppliedDone]);

	const onApplyDateFilter = () => {
		if (startDate && endDate) {
			if (new Date(startDate) < new Date(endDate)) {
				if ((moment(startDate).diff(moment(endDate), 'years')) === 0) {
					viewInspectionTaskReport();
				} else {
					setDisplayInfoModal(true);
					setMessage('Selected Date range should not exceed one year');
				}
			} else {
				setDisplayInfoModal(true);
				setMessage('End Date should be greater than Start Date');
			}
		}
	};

	const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: {
				"startDate": moment(startDate).format("yyyy-MM-DD").toString(),
				"endDate": moment(endDate).format("yyyy-MM-DD").toString(),
				customizationType: customizationType
			},
			exportType: "Inspection Task Details Report"
		};
		dispatch(onExportReport(reportParameters, "Inspection Tasks"));
	};

  return (
	  <div className='site-reports site-report-dropdown'>
		  <section className="d-flex align-items-center">
			  <Breadcrumb
				  title="Reports"
				  classNames="report-header"
				  handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
			  />
			  <div className="breadcrumb-right-arrow ml-3 mr-3">
				  <BsArrowRight className="icon" />
			  </div>
			  <div className="breadcrumb-sub-page-title">
				  <span>Inspection Tasks</span>
			  </div>
		  </section>

		  <div className="report-sort-field pt-2 pb-2">
			  <div className="report-export-main d-flex">
				  <div className="custom-float-field d-flex calender-input">
					  <span className="p-float-label ml-2">
						  <Calendar
							  id="startDate"
							  className='w-100'
							  dateFormat="yy-mm-dd"
							  value={new Date(startDate)}
							  onChange={(e) => setStartDate(e.value)} />
						  <label htmlFor="calendar">Start Date</label>
					  </span>
					  <span className="p-float-label ml-2">
						  <Calendar
							  id="endDate"
							  className='w-100'
							  dateFormat="yy-mm-dd"
							  value={new Date(endDate)}
							  onChange={(e) => setEndDate(e.value)} />
						  <label htmlFor="calendar">End Date</label>
					  </span>
					  <Button label="Apply" className="submit-btn ml-2" autoFocus onClick={onApplyDateFilter} />
				  </div>
				  
				  <div className="report-buttons-main d-flex">
					  <div className="report-buttons">
						  <ListGroup horizontal>
							  <ListGroup.Item><Button label="Customize" className="report-btn" onClick={() => setShowCustomizeReport(true)} /></ListGroup.Item>
						  </ListGroup>
					  </div>
					  <div className='export-report-buttons'>
						<ListGroup horizontal>
							<span className='expor-title'>Export to</span>
							<ListGroup.Item>
								<Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
							</ListGroup.Item>
							{/* <ListGroup.Item>
								<Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
							</ListGroup.Item>
							<ListGroup.Item>
								<Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
							</ListGroup.Item>
							<ListGroup.Item>
								<Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
							</ListGroup.Item> */}
						</ListGroup>
					</div>
				  </div>
			  </div>
		  </div>
		  <ReportViewer reportId={reportName} />
		  <InformationBox
			  showInfoModal={displayInfoModal}
			  setShowInfoModal={setDisplayInfoModal}
			  message={message}
		  />
		  {showCustomizeReport &&
			  <CustomizeReport
				  reportType="INSPECTION_TASKS"
				  showModal={showCustomizeReport}
				  onHideModal={() => setShowCustomizeReport(false)}
				  customizationType={customizationType}
				  setCustomizationType={setCustomizationType} />
		  }
	  </div>
  )
}

export default InspectionTaskReport;