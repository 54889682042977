import React from 'react';
import { useEffect } from "react";
import { useAppDispatch } from "redux/hooks";


import { setIsAuthenticated } from 'redux/pages/Login/loginSlice';

const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props

  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(setIsAuthenticated(true));
  }, [dispatch]);

  return  (
    <>{children}</>
  ) 
  
};


export default PrivateRoute;