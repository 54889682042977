import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
//components
import AddModal from '../../../../../../../components/Table/AddModal/AddModal';
import FormElement from '../../../../../../../components/common/Forms/Utility/FormElement';
import ConfirmationBox from '../../../../../../../components/common/Message/ConfirmationBox/ConfirmationBox';
//icons
import { BsExclamationCircleFill } from 'react-icons/bs';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setIsDevReadingChanged, setIsTestedDateChanged } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import { useAppSelector } from 'redux/hooks';

interface MultipleEditEquipmentPropType {
    showModal: boolean;
    datafields?: any;
    onHideModal?: () => void;
    onFormSubmit?: (body, reset, setSelectedDatafield, setModifiedContent) => void;
}

const MultipleEditEquipment: React.FC<MultipleEditEquipmentPropType> = (props) => {

    const { showModal, onHideModal = () => { }, onFormSubmit = () => { }, datafields } = props;
    const { control, formState: { errors }, handleSubmit, watch, setValue, reset } = useForm({mode:'all'});

    const editForm = useRef<any>();

    const [selectedDatafield, setSelectedDatafield] = useState<any>([])
    const [datafieldList, setDatafieldList] = useState([]);
    const [modifiedContent, setModifiedContent] = useState<any>({});
    const [message, setMessage] = useState("");
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [fieldFocus, setFieldFocus] = useState<boolean>(false);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);

    
    const dispatch = useDispatch<any>();

    useEffect(()=>{
        if(datafields) {
            const filterItems = datafields?.filter((item)=> item?.dataFieldName !== "Test Result" && item?.dataFieldName !== "Dev Reading Date" && item?.dataFieldName !== "Test Date");
            setDatafieldList(filterItems);
        }
    },[datafields])

    const formSubmitHandler = () => {
        editForm?.current?.requestSubmit();
    }

    const onSelectDatafield = (val) => {   
        const convField = JSON.parse(JSON.stringify(datafields));
        const filtered = convField?.filter((item) => item.uniqueDataFieldId === val);
        const testDate = convField?.filter((item) => item.dataFieldName === "Test Date");

        if (filtered[0]?.dataFieldName === "Test Method") {
            setValue(filtered[0]?.uniqueDataFieldId, 1);
            const findexes = convField?.findIndex((item) => item?.dataFieldName === "Test Result");
            if (findexes >= 0) {
                filtered.push(convField[findexes]);
                setValue(convField[findexes]?.uniqueDataFieldId, 1);
            }
            const findexdate = convField?.findIndex((item) => item?.dataFieldName === "Test Date");
            setValue(testDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"));
            filtered.push(convField[findexdate]);

        }
        if (filtered[0]?.dataFieldName === "Dev Reading") {
            const findexes = convField?.findIndex((item) => item?.dataFieldName === "Dev Reading Date");
            convField[findexes].disabled = true;
            filtered.push(convField[findexes]);
            
        }
        setSelectedDatafield(filtered);
         dispatch(setIsDevReadingChanged(''));
        dispatch(setIsTestedDateChanged(''));
    }
    const onTextBoxChange = useCallback((data,e) => {
        onCheckTextBoxChange(data,e);
    }, [selectedDatafield]);

    const onDateTimeChanged = useCallback((data, e) => {
        onCheckDateTimeChanged(data, e);
    }, [datafieldList]);
    const onCheckDateTimeChanged = useCallback((data, e) => {
        if (data.dataFieldName === "Dev Reading Date") {
            dispatch(setIsDevReadingChanged('D'));
        }
        if (data.dataFieldName === "Test Date") {
            dispatch(setIsTestedDateChanged('T'));
        }
    }, []);

    const onCheckTextBoxChange = (data,e) => {
        if (data.dataFieldName === "Dev Reading") {
            const devReadingDate = selectedDatafield?.filter((item) => item.dataFieldName === "Dev Reading Date");
            const devReadingDateindex = selectedDatafield?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
            if(devReadingDateindex > 0){
                if(e.currentTarget.value?.length>0){
                    selectedDatafield[devReadingDateindex].disabled = false;
                    setValue(devReadingDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"));
                }else{
                    selectedDatafield[devReadingDateindex].disabled = true;
                    setValue(devReadingDate[0]?.uniqueDataFieldId,''); 
                }
            }
            
        }
    }

    const onSelectionChange = useCallback((data, id) => {
        if (data.dataFieldName === "Test Method") {
            const testResIndex = selectedDatafield?.findIndex((item) => item.dataFieldName === "Test Result");
            const testDateIndex = selectedDatafield?.findIndex((item) => item.dataFieldName === "Test Date");
            if (id) {
                if (testResIndex >= 0) {
                    const testDate = selectedDatafield?.filter((item) => item.dataFieldName === "Test Date");
                    const items = data?.validatedValues?.filter((item) => item.dataFieldLookupId === id);
                    if (items[0]?.dataFieldLookupValue !== "None") {
                        setValue(testDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"));
                        selectedDatafield[testDateIndex].disabled = false;
                    }
                    else {
                        setValue(testDate[0]?.uniqueDataFieldId,'');
                        selectedDatafield[testDateIndex].disabled = true;
                    }
                    selectedDatafield[testResIndex].disabled = false;
                    const none = selectedDatafield[testResIndex]?.validatedValues?.filter((item) => item?.dataFieldLookupId !== 3);
                    selectedDatafield[testResIndex].validatedValues = none;
                    const passed = selectedDatafield[testResIndex]?.validatedValues?.filter((item) => item?.dataFieldLookupValue === "Passed");
                    if (passed?.length) setValue(selectedDatafield[testResIndex]?.uniqueDataFieldId, passed[0].dataFieldLookupId);
                }
                if (testDateIndex >= 0) {
                    setValue(selectedDatafield[testDateIndex]?.uniqueDataFieldId,  moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"));
                }
            } else {
                if (testResIndex >= 0) {
                    const testDate = selectedDatafield?.filter((item) => item.dataFieldName === "Test Date");
                    const items = data?.validatedValues?.filter((item) => item.dataFieldLookupId === id);
                    if (items[0]?.dataFieldLookupValue !== "None") {
                        setValue(testDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"));
                        selectedDatafield[testDateIndex].disabled = false;
                    }
                    else {
                        setValue(testDate[0]?.uniqueDataFieldId,'');
                        selectedDatafield[testDateIndex].disabled = true;
                    }
                    selectedDatafield[testResIndex].validatedValues.push({
                        dataFieldLookupId: 3,
                        dataFieldLookupValue: "Not Tested",
                        isSelected: true
                    })
                    setValue(selectedDatafield[testResIndex]?.uniqueDataFieldId, 3);
                    selectedDatafield[testResIndex].disabled = true;
                }
                if (testDateIndex >= 0) {
                    setValue(selectedDatafield[testDateIndex]?.uniqueDataFieldId, "");
                }
            }
        }
        setSelectedDatafield([...selectedDatafield]);
    }, [selectedDatafield]);

    const onSubmit = (data) => {
        if (selectedDatafield && selectedDatafield.length) {
            // if (data[data?.uniqueDataFieldId] || data[data?.uniqueDataFieldId] === 0) {
                let testDateUniqueFieldId:any;
                let testDate:any = "";
                let devReadingDate:any = "";
                selectedDatafield.map((item,key)=>{
                    if(item.dataFieldName === "Test Date"){
                        testDateUniqueFieldId = item.uniqueDataFieldId;
                        let newDate = new Date(data[testDateUniqueFieldId]);
                        const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset
                        testDate = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");						

                    }
                })
                
                let body = {
                    uniqueDataFieldId: data?.uniqueDataFieldId,
                    dataFieldValue: data[data?.uniqueDataFieldId],
                    dataFieldLookUpId: null,
                    testDateUniqueFieldId:data[testDateUniqueFieldId]?.length > 0 ?  testDate : data[testDateUniqueFieldId]?.length
                }
                if (selectedDatafield?.length > 1) {
                    if(selectedDatafield[1]?.dataFieldName ==="Dev Reading Date") 
                    {
                        if(data[selectedDatafield[1]?.uniqueDataFieldId]?.length > 0)
                        {
                         let newDate = new Date(data[selectedDatafield[1]?.uniqueDataFieldId]);
                         const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset
                         devReadingDate = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");	}   					
                         body.dataFieldLookUpId = devReadingDate
                    }
                    else  {                   
                    body.dataFieldLookUpId = data[selectedDatafield[1]?.uniqueDataFieldId]}
                }
                setModifiedContent(body);
                setMessage("Are you sure you want to update the selected records with the new value?");
                setDisplayConfirmModal(true);
            // }
        }
    }
    
    const updateEquipment = () => {    
        if (modifiedContent && modifiedContent.uniqueDataFieldId)  {
            onFormSubmit(modifiedContent, reset, setSelectedDatafield, setModifiedContent);
            setDisplayConfirmModal(false);
        } 
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    return (
        <>
            <AddModal
                header='Multiple Edit'
                showModal={showModal}
                setShowModal={onHideModal}
                OnSaveClick={formSubmitHandler}
                isAdd={false}
                style={{ width: "35vw", minHeight: "25vh", maxHeight: "100%" }}
            >
                <div>
                    <form className="form-data-wrapper" onSubmit={handleSubmit(onSubmit)} ref={editForm}>
                        <div className="field">
                            <Controller
                                name="uniqueDataFieldId"
                                control={control}
                                rules={{ required: "Select a Field Name" }}
                                render={({ field, fieldState }) => (
                                    <span className="p-float-label">
                                        <Dropdown
                                            inputId="uniqueDataFieldId"
                                            className={classNames("w-100 error-tooltip ", { error: fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                onSelectDatafield(e.value)
                                            }}
                                            options={datafieldList}
                                            optionLabel="dataFieldName"
                                            optionValue="uniqueDataFieldId"
                                        />
                                        <label htmlFor="uniqueDataFieldId" className={`mandatory`}>
                                            Field Name
                                        </label>
                                        {getFormErrorMessage('uniqueDataFieldId')}
                                        
                                    </span>
                                )}
                            />
                        </div>
                        {selectedDatafield?.map((item, key) => (
                            <FormElement                            
                                key={key}
                                data={item}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                onSelectionChange={onSelectionChange}
                                onTextBoxChange={onTextBoxChange}
                                onDateTimeChanged={onDateTimeChanged}
                                errors={errors} 
                                calendarAppendTo={true}
                                fieldFocus={fieldFocus}
                                setFieldFocus={setFieldFocus}
                            />
                        ))
                        }
                    </form>
                </div>
            </AddModal>

            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={updateEquipment}
                message={message}
            />
        </>
    )
}

export default memo(MultipleEditEquipment)