import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { getInspectionSitesCustomers } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { useAppSelector } from "redux/hooks";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  activeSite: any;
  mapCustomer: (data: any) => void;
}

type FormData = {
};

export const InspectionMapCustomer: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  activeSite = [],
  mapCustomer = () => { },
}) => {

  const customerList = useAppSelector((state) => state.inspectionTask.inspectionSiteCustomers);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [customerListData, setCustomerListData] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const customerMapForm: any = useRef();
  const selectedCustomers = useRef<any>();
  const checkedRows = useRef<any>();
  const disabledRows = useRef<any>();

  const {
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
    },
  });

  useEffect(() => {
    dispatch(getInspectionSitesCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (customerList?.length > 0 && showModal === true) {
      let siteArray: any = [];
      customerList.map((item: any) => {
        return siteArray.push({
          id: item.uniqueCustomerId,
          siteName: item.customerName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setCustomerListData(siteArray);
    } else {
      setCustomerListData([]);
    }
  }, [customerList, showModal]);

  const mapSiteSearchList = () => {
    if (searchKeyword.length === 0) {
      var customerNewList: any = [];
      customerList.map((item: any) => {
        return customerNewList.push({
          id: item.uniqueCustomerId,
          siteName: item.customerName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setCustomerListData(customerNewList);
    } else {
      dispatch(getInspectionSitesCustomers(searchKeyword));
    }
    setSearchKeyword("");
  };

  const searchCustomerByEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      mapSiteSearchList();
    }
  };

  const formSubmitHandler = (data: any) => {
    customerMapForm.current.requestSubmit();
  };


  const onCustomerMapFormSubmit = () => {
    if (customerList && customerList.length) {
      let customerIds: any = [];
      let allCheckedCustomers = [...checkedRows.current, ...disabledRows.current];
      allCheckedCustomers.map((item: any) => {
        customerIds.push(item.id);
        return null;
      });
      const data = {
        customerIds: customerIds,
      };
      mapCustomer && mapCustomer(data);
    } else {
      setMessage("No Customer exist");
      setDisplayInfoModal(true);
    }
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => {
            setShowModal(false);
            setCustomerListData([]);
          }}
          className="cancel btn"
        />
        <Button label="Save Changes" className="update btn" autoFocus onClick={formSubmitHandler} />
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <Dialog
        header="Map Customers"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => {
          setShowModal(false);
          setCustomerListData([]);
        }}
        footer={renderFooter()}
        className="site-modal selectsite"
      >
        <div className="content-box">
          <div className="header d-flex align-items-center">
            <span className="title">Site:</span>
            <span className="value">{activeSite?.siteName}</span>
          </div>
          <div className="sub-header">
            Note: New Customer(s) required for Site-Customer mapping can be found through search option
          </div>
          <div className="search-bar text-center">
            <div className="p-input-icon-right inputgroup">
              <i className="pi pi-search" onClick={mapSiteSearchList} />
              <InputText
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event?.target.value)}
                onKeyDown={searchCustomerByEnterKey}
                placeholder="Search Customer Number / Name"
              />
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onCustomerMapFormSubmit, onError)}
          ref={customerMapForm}
        >
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 py-0">
              <MultiSelect
                singleSelect
                list={customerListData}
                getCheckedItems={(checkedItems) => {
                  checkedRows.current = checkedItems;
                }}
                getSelectedItems={(selectedItems) => {
                  selectedCustomers.current = selectedItems;
                }}
                getDisabledItems={(disabledItems) => {
                  disabledRows.current = disabledItems;
                }}
                headerName="Customer Name"
              />
            </div>
          </div>
        </form>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default InspectionMapCustomer;
