import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useDispatch } from "react-redux";
import { getDrawingList, setDrawingList, setSelectedTask, setSelectedTreeNode } from "redux/pages/Inspection/InspectionTask/inspectionDrawingSlice";
import { useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from 'constants/AppRoutes';
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import PdfDrawingViewer from "pages/AsBuilts/ManageDrawings/PdfDrawingViewer";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any, 
}

type FormData = {};

export const DrawingList: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedRows = [],
}) => {

  const {
  } = useForm<FormData>({
    defaultValues: {},
  });

  const drawingList = useAppSelector((state) => state.inspectionDrawing.drawingList);

  const [expandedKeys, setExpandedKeys] = useState({});
  const [drawingTreeData, setDrawingTreeData] = useState([]);
  const [drawingData, setDrawingData] = useState<any>();
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>();
  const [disableButton, setDisableButton] = useState(true);
  const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showPdfViewModal, setShowPdfViewModal] = useState<any>(false);
	const [filePdfName, setPdfFileName] = useState("");
  const [drawingId, setdrawingId] = useState(""); 
  const [revisionNumber, setRevisionNumber] = useState(""); 

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(setSelectedTask(selectedRows[0]))
    dispatch(getDrawingList(selectedRows[0].uniqueInspectionTaskId, setMessage, setDisplayInfoModal));
  }, [dispatch])

  useEffect(() => {
    if (drawingList && drawingList.siteDetails) {
      setDisableButton(false);
      let _expandedKeys = { ...expandedKeys };
      let siteData: any = [];
      let buildingData:  any = [];
      let floorData: any = [];
      drawingList?.siteDetails?.map((site: any) => {
        let siteArray = {key: site.uniqueSiteId, selectable: false, data: {name: site.siteName}, children: [], className:'has-child'}
        buildingData = [];
        _expandedKeys[site.uniqueSiteId.toString()] = true;
        site?.buildingDataList?.map((building: any) => {
          let buildingArray = {key: building.uniqueBuildingId, selectable: false, data: {name: building.buildingName}, children: [], className: 'has-child'}
          floorData = [];
          _expandedKeys[building.uniqueBuildingId.toString()] = true;
          building?.floorDetails?.map((floor: any) => {
            floorData.push({key: floor.uniqueFloorId, selectable: true, data: floor, className: 'has-no-child'})
          })
          buildingArray.children = floorData
          buildingData.push(buildingArray)
        })      
        siteArray.children = buildingData 
        siteData.push(siteArray)
      })
      setDrawingData(drawingList)
      dispatch(setDrawingList(""));
      let selectedSite = siteData[0]
      let selectedBuilding = selectedSite.children[0]
      let selectedFloor = selectedBuilding.children[0]
      setSelectedNodeKey(selectedFloor.key);
      setDrawingTreeData(siteData)
      setExpandedKeys(_expandedKeys);
    }
    
  }, [drawingList])


  const openDrawingClick = () => {
    let selectedFloor: any = [];
    drawingData?.siteDetails?.map((site: any) => {
      site?.buildingDataList?.map((building: any) => {
        building?.floorDetails?.map((floor: any) => {
          if (floor.uniqueFloorId === selectedNodeKey) {
            selectedFloor.push(floor)
          }
        })
      })      
    })
    if (selectedFloor.length < 1) {
      setMessage("Select a Drawing");
      setDisplayInfoModal(true);
    }else{
      const fileName = selectedFloor[0].fileName;
      const drawingid=selectedFloor[0].drawingId;
      const fileExtension = fileName.split('.').pop();
   
      if (fileExtension === "pdf" || fileExtension === "PDF"){
        setShowPdfViewModal(true);
        setPdfFileName(fileName);
        setdrawingId(drawingid);
        setRevisionNumber(selectedFloor[0].revisionNo);
      }
      else {
        dispatch(setSelectedTreeNode(selectedFloor))
        navigate(`/${APP_ROUTES.INSPECTION_DRAWINGS}`)
      }
    }
  }


  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button disabled={disableButton} label="Open Drawing" className="update btn" autoFocus onClick={openDrawingClick}/>
      </>
    );
  };

  const colExpand = (rowData: any, columnDetails: any) => {
    return (
      <div className="field-name">
        <span> {rowData?.data?.name} </span>
      </div>
    );
  };

  const sizeColumnData = (rowData: any, columnDetails: any) => {
    return (
      <div style={{textAlign : 'right', paddingRight: "40%"}}>
        <span> {rowData?.data?.fileSize} </span>
      </div>
    );
  };

  return (
    <>
      <Dialog
        header="Drawings List"
        visible={showModal}
        className="s-modal drawing-list"
        style={{ width: "70vw", height: "85%" }}
        footer={renderFooter()}
        onHide={() => setShowModal(false)}
      >
          <TreeTable value={drawingTreeData} expandedKeys={expandedKeys} resizableColumns onToggle={e => setExpandedKeys(e.value)} emptyMessage="No data found." selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={e => {setSelectedNodeKey(e.value)}}>
            <Column field="name" header="" expander className="col-expand" style={{ width: "160px" }} body={colExpand}></Column>
            <Column field="floorName" header="Floor Name"></Column>
            <Column field="floorCategory" header="Floor Category"></Column>
            <Column field="fileName" header="File Name"></Column>
            <Column field="fileSize" header="File Size (KB)" body={sizeColumnData}></Column>
          </TreeTable>
      </Dialog>

      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />

       {showPdfViewModal &&
				<PdfDrawingViewer
					showModal={showPdfViewModal}
					setShowModal={setShowPdfViewModal}
					fileName={filePdfName}
					setFileName={setPdfFileName}
					drawingId={drawingId}
          revisionNumber={revisionNumber}
				/>
			}
    </>
  );
};


export default DrawingList;
