import {
  memo,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
//components
import DisplaySetting from "components/common/DisplaySetting/DisplaySetting";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { setSelectedDisplayColumns } from "redux/pages/Manage/InspectionSettings/ReportMapping/ReportMappingslice";

type columnData = any[];
interface ICheckBoxGridViewPropType {
  gridData: any;
  columnData: columnData;
  onSaveCheckBoxData?: Function | any;
  isQuestionGridView?: boolean;
  getColumns?: (columns) => void;
}
const CheckBoxGridView = forwardRef<any, ICheckBoxGridViewPropType>(
  (props, ref) => {
    const dispatch = useDispatch<any>();
    const { gridData, columnData = [], isQuestionGridView = false, getColumns = () => { } } = props;

     const columns: any = useAppSelector((state) => state.reportMapping.selectedDisplayColumns);
       
    const [gridDetails, setGridDetails] = useState<any>();
    const [showDisplaySetting, setShowDisplaySetting] = useState<boolean>(false);
    const [expandedKeys, setExpandedKeys] = useState<any>(null);

    useImperativeHandle(ref, () => ({
      onSaveclick: () => {
        const updatedList = reArrangeData(gridDetails);
        props.onSaveCheckBoxData(updatedList);
      },
      onShowDisplaySettings: () => {
        setShowDisplaySetting(true)
      },
    }));

    useEffect(() => {
      if (columnData && columnData.length && gridData) {
        const result = initializingGrid(gridData, false);
        setGridDetails(result);
        setTimeout(() => {
          setAttributes()
        }, 50);
      }
    }, [gridData, columnData]);

    useEffect(() => {
      getColumns(columns)
    }, [columns])

    useEffect(() => {
      if (expandedKeys)
        setAttributes();
    }, [expandedKeys])

    const setAttributes = () => {
      const elements: any = document.getElementsByClassName('p-treetable-toggler p-link p-unselectable-text'); 
      if (elements?.length) {
        elements.forEach(element => {
          const parentElement = element.parentElement;
          parentElement.setAttribute('title', parentElement?.textContent);
        });
      }
    }

    const initializingGrid = (gridList, isParent) => {
      const list = gridList?.map((item) => {
        return {
          key: item?.dataFieldId,
          data: {
            dataFieldName: item?.dataFieldName,
            isSection: item?.isSection,
            position: item?.position,
            reports: item?.reports,
          },
          children: item?.reportFields ? initializingGrid(item?.reportFields, true) : [],
          className: item?.reportFields?.length ? 'has-child' : isParent ? 'has-no-child' : ''
        }
      })
      return list
    }

    const reArrangeData = (gridList) => {
      const list = gridList?.map((item) => {
        return {
          dataFieldId: item?.key,
          dataFieldName: item?.data?.dataFieldName,
          isSection: item?.data?.isSection,
          position: item?.data?.position,
          reports: item?.data?.reports,
          reportFields: item?.children ? reArrangeData(item?.children) : [],
        }
      });
      return list;
    }



    const onCheckBoxSelected = (event: any, columnDetails: any) => {
      let data = JSON.parse(JSON.stringify([...gridDetails]));
      const lists = setCheckBoxValue(event, columnDetails, data, false);
      getParentData(lists, event?.value, columnDetails);
      setGridDetails(lists)
    };

    const onCheckBoxAllSelected = ( columnDetails: any,e:any) => {
      let data = JSON.parse(JSON.stringify([...gridDetails]));
      const lists = setCheckBoxValueForAll(e?.checked,columnDetails, data);
      setGridDetails(lists)
    };

    const setCheckBoxValue = (event, details, gridData, isParent) => {
      gridData?.forEach(element => {
        if (event?.checked) {
          if (isParent) {
            const findIdex = element?.data?.reports?.findIndex((item) => item.reportId === details?.reportId);
            if (findIdex < 0)
              element?.data?.reports?.push(details);
            if (element?.children?.length) {
              setCheckBoxValue(event, details, element?.children, true);
            }
          } else {
            if (element?.key === event?.value) {
              const findIdex = element?.data?.reports?.findIndex((item) => item.reportId === details?.reportId);
              if (findIdex < 0)
                element?.data?.reports?.push(details);
              if (element?.children?.length) {
                setCheckBoxValue(event, details, element?.children, true);
              }
            } else {
              if (element?.children?.length) {
                setCheckBoxValue(event, details, element?.children, false);
              }
            }
          }
        } else {
          if (isParent) {
            const removedIndex = element?.data?.reports?.findIndex((item) => item?.reportId === details?.reportId);
            if (removedIndex >= 0)
              element?.data?.reports.splice(removedIndex, 1);
            if (element?.children?.length) {
              element?.children?.forEach(sub => {
                const findIndex = sub?.data?.reports.findIndex((el) => el.reportId === details.reportId);
                if (findIndex >= 0) sub?.data?.reports.splice(findIndex, 1);
              });
              setCheckBoxValue(event, details, element?.children, true);
            }
          } else {
            if (element?.key === event?.value) {
              const removedIndex = element?.data?.reports?.findIndex((item) => item?.reportId === details?.reportId);
              if (removedIndex >= 0)
                element?.data?.reports.splice(removedIndex, 1);
              if (element?.children?.length) {
                element?.children?.forEach(sub => {
                  const findIndex = sub?.data?.reports.findIndex((el) => el.reportId === details.reportId);
                  if (findIndex >= 0) sub?.data?.reports.splice(findIndex, 1);
                });
                setCheckBoxValue(event, details, element?.children, true);
              }
            } else {
              if (element?.children?.length) {
                setCheckBoxValue(event, details, element?.children, false);
              }
            }
          }
        }

      });

      return gridData;
    }

    const setCheckBoxValueForAll = (isChecked, details, gridData) => {
      gridData?.forEach(element => {
        if (isChecked) {
            const findIdex = element?.data?.reports?.findIndex((item) => item.reportId === details?.reportId);
            if (findIdex < 0)
              element?.data?.reports?.push(details);
            if (element?.children?.length) {
              setCheckBoxValueForAll(isChecked, details, element?.children);
          }
        } else {       
            const removedIndex = element?.data?.reports?.findIndex((item) => item?.reportId === details?.reportId);
            if (removedIndex >= 0)
              element?.data?.reports.splice(removedIndex, 1);
            if (element?.children?.length) {
              element?.children?.forEach(sub => {
                const findIndex = sub?.data?.reports.findIndex((el) => el.reportId === details.reportId);
                if (findIndex >= 0) sub?.data?.reports.splice(findIndex, 1);
              });
              setCheckBoxValueForAll(isChecked, details, element?.children);
            } 
        }

        

      });

      return gridData;
    }

    const getParentData = (gridData, key, details, parent = null) => {
      for (let item of gridData) {
        let res = item.key === key ? parent
          : item.children && getParentData(item.children, key, details, item);
        if (res) {
          let length = res?.children?.length;
          let count = 0;
          res?.children?.forEach((sub) => {
            if (sub?.data?.reports.some((el) => el.reportId === details?.reportId)) {
              count++;
            }
          });
          if (count === length) {
            const findIdex = res?.data?.reports?.findIndex((item) => item.reportId === details?.reportId);
            if (findIdex < 0)
              res?.data?.reports.push(details);
          } else {
            const findIdex = res?.data?.reports?.findIndex((item) => item.reportId === details?.reportId);
            if (findIdex >= 0)
              res?.data?.reports.splice(findIdex, 1)
          }
          return gridData;
        }
      }
    }

    const template = (rowData: any, columnDetails: any) => {
      return (
        <div className="checkbox-wrapper field-checkbox">
          <Checkbox
            key={rowData?.key}
            className="check-box"
            id={rowData?.key}
            value={rowData?.key}
            onChange={(e) => {
              onCheckBoxSelected(e, columnDetails);
            }}
            checked={rowData?.data?.reports.some(
              (item) => item.reportId === columnDetails.reportId
            )}
          />
        </div>
      );
    };

    const colExpand = (rowData: any, columnDetails: any) => {
      return (
        <div className="field-name">
          <span> {rowData?.data?.dataFieldName} </span>
        </div>
      );
    };


    const setColumns=(data)=>{
      dispatch(setSelectedDisplayColumns(data));
    }

    const getCheckBoxActivate=(fields:any,report:any)=>{
      let returnBoolean = false;

       if(fields?.length > 0){
        returnBoolean = true
            for ( let item of fields ) {
              if( !item?.data?.reports?.some(reportData => 
                 reportData?.reportId === report?.reportId))
                 { returnBoolean = false
                  break; }
        }}
        return returnBoolean;
      }

    return (
      <>
        {gridDetails && columns.length > 0 && (
          <TreeTable
            key='dataFieldId'
            value={gridDetails}
            expandedKeys={expandedKeys}
            onToggle={e => setExpandedKeys(e.value)} >
            <Column className="col-expand" style={{ width: "200px" }} body={colExpand} expander />
            {columns.map((item: any, key: any) => {
              return (
                <Column
                  headerTooltip={item.reportName?.length > 19 ? item.reportName  : ""}
                  headerTooltipOptions={{ position: 'bottom' }}
                  columnKey={item?.reportId}
                  key={item?.reportId}
                  style={{ width: "170px" }}
                  header={ <>
                  <span className="checkbox-Header" >
                  <Checkbox 
                    id={item?.reportId} 
                    key={item?.reportId} 
                    checked={getCheckBoxActivate(gridDetails,item)} 
                    onChange={(e) =>onCheckBoxAllSelected(item,e)} /> 
                    {item.reportName}</span> </> }
                  body={(e) => template(e, item)}
                />
              );
            })}
          </TreeTable>
        )}
        {showDisplaySetting && (<DisplaySetting
          showModal={showDisplaySetting}
          header="Report Names"
          setShowModal={setShowDisplaySetting}
          displayColumns={columnData}
          setdisplayColumns={setColumns}
          selectedCols={columns}
        />)}
      </>
    );
  }
);

export default memo(CheckBoxGridView);
