
import { InputText } from "primereact/inputtext";
import React, { memo, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
//components
import AddModal from "components/Table/AddModal/AddModal";
//icons
import { BsExclamationCircleFill } from "react-icons/bs";
import { blockedNonKeyboardCharacters } from "service/GeneralUtility";


interface EditInspectionFieldPropType {
    showModal: boolean;
    selectedItem?: any;
    selectNode?: any;
    onHideModal?: () => void;
    onUpdate?: (body) => void;
    type?: 'CONTRACT' | 'SYSTEM' | 'QUESTION';
}

const EditInspectionField: React.FC<EditInspectionFieldPropType> = (props) => {
    const { showModal, selectedItem, selectNode, onHideModal = () => { }, onUpdate = () => { }, type } = props;

    const editForm = useRef<any>(null)
    const [showDataGroupForm, setShowDataGroupForm] = useState(false);
    const [inspectionData, setInspectionData] = useState<any>(null);
    const [selectedAlignment, setSelectedAlignment] = useState("vertical");

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues: {
            dataItemName: "",
            dataGroupName: "",
            datasetName: "",
            datafieldName: "",
            isForwardData: false,
            isMandatory:false,
            isClearDefaultData: false,
            maxCharacters: "",
        },
    });

    useEffect(() => {
        if (selectedItem) {
            if (selectedItem?.isSection) {
                setDataGroupValue(selectedItem);
                setShowDataGroupForm(true);
            } else {
                setShowDataGroupForm(false);
                setDataItemValue(selectedItem);
            }
        }
    }, [selectedItem]);

    const setDataGroupValue = (data) => {
        setValue("dataGroupName", data.dataFieldName);
        setValue("isClearDefaultData", false);
    }

    const setDataItemValue = (data) => {
        setValue("dataItemName", data.dataFieldName);
        setValue("datasetName", data.dataSetName);
        setValue("datafieldName", data.dataFieldVariableName);
        setValue("isForwardData", data.forwardData);
        setValue("isMandatory", data.mandatory);
        setValue("isClearDefaultData", false);
        setValue("maxCharacters", data.maxLength);
        setInspectionData(data);
        if (data.isValidated) {
            let item = data?.isVerticalList ? "vertical" : "horizontal";
            setSelectedAlignment(item);
        }
    }

    const formSubmitHandler = () => {
        editForm?.current?.requestSubmit();
    };

    const onSubmit = (data) => {

        const body = {
            "dataFieldName": data?.datafieldName?.trim(),
            "parentGroupId": selectedItem?.parentDataFieldId,
            "uniqueDataFieldId": selectedItem?.uniqueDataFieldId,
            "datasetName": data?.datasetName ? data?.datasetName?.trim() : "",
            "fieldTypeId": selectedItem?.dataType?.dataTypeId,
            "forwardData": data?.isForwardData,
            "isMandatory":data?.isMandatory,
            "clearDefaultData":data?.isClearDefaultData,
            "nodeId": selectNode?.key,
            "inputTypeId": selectedItem?.dataType?.dataEntryControlId,
            "isHorizontal": selectedAlignment === "horizontal" ? true : false,
            "isSection": selectedItem?.isSection,
            "isVertical": selectedAlignment === "vertical" ? true : false,
            "isValidated": selectedItem?.isValidated,
            "isValidMultiple": selectedItem?.isValidMultiple,
            "isValidSingle": selectedItem?.isValidSingle,
            "maxChars": data?.maxCharacters,
            "Name": selectedItem?.isSection ? data?.dataGroupName?.trim() : data?.dataItemName?.trim(),
            "nodeName": selectNode?.label,
        }
        onUpdate(body);
    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const textValidate = (value) => {
        if (value) {
            let invalidCharacters: any = blockedNonKeyboardCharacters(value);
            if (invalidCharacters != null && invalidCharacters.length > 0) {
                return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
            } else {
                return true;
            }
        }
    }

    return (
        <AddModal
            header="Edit Inspection Field"
            showModal={showModal}
            setShowModal={onHideModal}
            OnSaveClick={formSubmitHandler}
            isAdd={false}
            style={{ width: "38vw", maxHeight: "100%" }}
        >
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid pt-3" ref={editForm}>
                {showDataGroupForm ? (
                    <>
                        <div className="field col-12 md:col-4 pt-0">
                            <span className="p-float-label">
                                <Controller
                                    name="dataGroupName"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        },
                                        maxLength: {
                                            value : 250,
                                            message : "Maximum 250 characters allowed"
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="dataGroupName"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            {...field}
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dataGroupName">
                                    Name
                                </label>
                                {getFormErrorMessage("dataGroupName")}
                            </span>
                        </div>
                        {type === 'QUESTION' &&
                            (
                                <div className="field col-12 md:col-4">
                                    <div className="radio-section d-flex align-items-center pt-2 test">
                                        <span className="field-checkbox mb-0">
                                            <Controller
                                                name="isClearDefaultData"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <Checkbox inputId="isClearDefaultData" checked={field.value} {...field} />
                                                )}
                                            />
                                            <label htmlFor="isClearDefaultData">Clear Default Data test</label>
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <>
                        <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                                <Controller
                                    name="dataItemName"
                                    control={control}
                                    rules={{
                                        required: "Enter Name",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText id="dataItemName" className={classNames("w-100 error-tooltip", { error: fieldState.invalid })} {...field}
                                         maxLength={1000}
                                          />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dataItemName">
                                    Name
                                </label>
                                {getFormErrorMessage("dataItemName")}
                            </span>
                        </div>
                        {inspectionData?.dataEntryControl?.dataEntryControlName === "Text Area" && (
                            <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <Controller
                                        name="maxCharacters"
                                        control={control}
                                        rules={{
                                            required: "Enter Max Characters Allowed",
                                            pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: "Enter a valid Number" },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id="maxCharacters"
                                                className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <label className="mandatory" htmlFor="maxCharacters">
                                        Max Characters
                                    </label>
                                    {getFormErrorMessage("maxCharacters")}
                                </span>
                            </div>
                        )}
                        {inspectionData?.isValidated ? (
                            <div className="field col-12 md:col-4">
                                <span className="field-radiobutton ">
                                    <RadioButton
                                        value="horizontal"
                                        inputId="horizontal"
                                        name="Horizontal"
                                        onChange={(e) => setSelectedAlignment(e.value)}
                                        checked={selectedAlignment === "horizontal"}
                                    />
                                    <label htmlFor="horizontal">Horizontal</label>
                                </span>
                                <span className="field-radiobutton mb-0">
                                    <RadioButton
                                        value="vertical"
                                        inputId="vertical"
                                        name="Vertical"
                                        onChange={(e) => setSelectedAlignment(e.value)}
                                        checked={selectedAlignment === "vertical"}
                                    />
                                    <label htmlFor="vertical">Vertical</label>
                                </span>
                            </div>
                        ) : null}
                        <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                                <Controller
                                    name="datasetName"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText id="datasetName" className={classNames("w-100 error-tooltip", { error: fieldState.invalid })} {...field} />
                                    )}
                                />
                                <label className="mandatory" htmlFor="datasetName">
                                    Dataset Name
                                </label>
                                {getFormErrorMessage("datasetName")}
                            </span>
                        </div>
                        <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                                <Controller
                                    name="datafieldName"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText id="datafieldName" className={classNames("w-100 error-tooltip", { error: fieldState.invalid })} {...field} />
                                    )}
                                />
                                <label className="mandatory" htmlFor="datafieldName">
                                    Datafield Name
                                </label>
                                {getFormErrorMessage("datafieldName")}
                            </span>
                        </div>
                        <div className="field-checkbox">
                            <div className="field-checkbox pl-2">
                                <Controller
                                    name="isMandatory"
                                    control={control}
                                    render={({ field, fieldState }) => <Checkbox inputId="isMandatory" checked={field.value} {...field} />}
                                />
                                <label htmlFor="isMandatory">Mandatory</label>
                            </div>
                        </div>
                        {type === 'QUESTION' &&
                            (
                                <div className="field col-12 md:col-4">
                                    <div className="checkbox-section d-flex align-items-center ">
                                        <div className="field-checkbox mb-0">
                                            <span>
                                                <Controller
                                                    name="isForwardData"
                                                    control={control}
                                                    render={({ field, fieldState }) => <Checkbox inputId="isForwardData" checked={field.value} {...field} />}
                                                />
                                                <label htmlFor="isForwardData">Forward Data to New Task</label>
                                            </span>
                                        </div>

                                        <div className="field-checkbox mb-0">
                                            <span>
                                                <Controller
                                                    name="isClearDefaultData"
                                                    control={control}
                                                    render={({ field, fieldState }) => <Checkbox inputId="isClearDefaultData" checked={field.value} {...field} />}
                                                />
                                                <label htmlFor="isClearDefaultData">Clear Default Data</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
                }
            </form>
        </AddModal>
    );
};

export default memo(EditInspectionField);
