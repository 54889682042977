import { InputText } from 'primereact/inputtext';
import { BsExclamationCircleFill } from 'react-icons/bs';



const getErrorMsg = options => {
    const { value } = options;
    if(value.trim().length<=0 || !value?.trim()){
        return 'This field is required';
    }
  };

  const textEditor = (options) => {
    const errorMsg = getErrorMsg(options);
    return <>
      <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
      {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
    </>
}


export const GOAL_COLS = [
    {
        field: 'projectGoals',
        header: 'GOAL',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },

];