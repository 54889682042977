
import React, { useState,useCallback, useEffect } from "react";
import { InputText } from "primereact/inputtext";

import DataTableComponent from "components/Table/DataTable";
import { useDispatch } from "react-redux";
import { getRevisionList, setRevisionList, setUpdatedRevision } from "redux/pages/AsBuilts/AsBuiltsSlice";
import { useAppSelector } from "redux/hooks";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import AsbuiltsDrawing from "./AsbuiltsDrawing";


interface Props {
    showModal?: boolean;
    setShowModal?: (state: boolean) => void;
    selectedRowData?: (data: any) => void;
    selectedBuilding?: any;
    settingsRef: any;
    getSelectedData?: (data: any) => void;
    markupListClick?: (data: any) => void;
    showDrawing?: boolean;
    setShowDrawingModal?: (state: boolean) => void;
}

export const ListRevisions: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRowData = [],
    selectedBuilding = [],
    settingsRef = null,
    getSelectedData = () => { },
    markupListClick = () => { },
    showDrawing = false,
    setShowDrawingModal = () => { },
}) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [revisionData, setRevisionData] = useState<any>([]);
    const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState('')
    const [sortOrder, setSortOrder] = useState('');
    const [settingListItems, setSettingListItems] = useState<any>()

    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
    const revisionList = useAppSelector((state) => state.asbuilts.revisionList);
    const revisionPageDetails = useAppSelector((state) => state.asbuilts.revisionPageDetails);
    const updatedRevision = useAppSelector((state) => state.asbuilts.updatedRevision);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(getRevisionList(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, 1,setDisplayInfoModal,setMessage))
    }, [selectedRowData])

    useEffect(() => {
        if (revisionList) {
            setRevisionData(revisionList)
            dispatch(setRevisionList(""))
        }
    }, [revisionList])

    useEffect(() => {
        if (selectedRow && selectedRow.length) {
          filterSettingItemList();
        }
      }, [selectedRow])

    const filterSettingItemList = () => {
        const settingList: any = [];
        settingList.push({
            id: 1,
            name: 'List Markups',
            onClick: () => { markupListClick(selectedRow) }
        })
        setSettingListItems(settingList)
    }

    const onPageChanged = (options: any) => {
        let currentStart;
        setPageIndex(options);
        currentStart = options * revisionPageDetails?.currentLength + 1;
        setCurrentStart(currentStart)
        dispatch(getRevisionList(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, currentStart, "", sortField, sortOrder))
    }
    const onCloseClick = () => {
        setShowDrawingModal(false);
    }
    const onSort = (event) => {
        if (event && revisionData && revisionData.length) {
            let sortDirection: string;
            if (event.sortOrder === 1)
                sortDirection = "ASC"
            else
                sortDirection = "DESC"
            setSortField(event.sortField)
            setSortOrder(sortDirection)
            dispatch(getRevisionList(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, currentStart, "","","", event?.sortField, sortDirection))
        }
    }



    const handleRowSelect = useCallback((data: any) => {
        if(data){
        setSelectedRow(data)
        getSelectedData([...data])
        }
    }, []);
    useEffect(() => {
        if (updatedRevision) {
            let updatedData = JSON.parse(JSON.stringify(updatedRevision[0]));
            let revisionInfo = JSON.parse(JSON.stringify(revisionData));
            let currentIndex = revisionInfo.findIndex((item: any) => item.revisions === updatedData.revisions);
            const newMarkupList: any = revisionInfo.filter((item: any) => item.revisions !== updatedData.revisions);
            newMarkupList.splice(currentIndex, 0, updatedData);
            setRevisionData(newMarkupList);
            setSelectedRow([updatedData]);
            dispatch(setUpdatedRevision(""));
          }
    }, [updatedRevision])    

    return (
        <>
       
            
       {showDrawing ?
       <div className="asBuild-parent">
            <div className={`asBuild-wrapper ${ "drawing-f"} `}>
                <div className="as-build-drawing__wrapper">
                    <div className="asBuild-drawing">
                       
                        <div className="type-list">
                            <div className="item">
                                <h6>Site</h6> <span>{selectedRowData[0]?.siteName}</span>
                            </div>
                            <div className="item">
                                <h6>Building</h6>
                                <span>{selectedRowData[0]?.buildingName}</span>
                            </div>
                            <div className="item">
                                <h6>Floor</h6>
                                <span>{selectedRowData[0]?.floorName}</span>
                            </div>
                            <div className="item">
                                <h6>Category</h6>
                                <span>{selectedRowData[0]?.category}</span>
                            </div>
                            <div className="item">
                                <h6>Revision Number </h6>
                                <span>{selectedRow[0]?.revisions}</span>
                            </div>
                            <div className="item">
                                <h6>File Name</h6>
                                <span>{selectedRow[0]?.dwgFileName}</span>
                            </div>
                            <div className="item">
                                <h6>Description</h6>
                                <span>{selectedRow[0]?.description}</span>
                            </div>
                        </div>
                     
                              <AsbuiltsDrawing
                                    selectedRevisionRow={selectedRow}
                                    selectedDrawing={selectedRowData[0]}
                                     bIsResizeDrawing={false}
                                     selectedBuilding={selectedBuilding}
                                     selectedSite={loggedInUserData?.userDefaults}
                                     closeDrawingView={onCloseClick}
                                     showMarkupTools={false}
                                />
                      
                    </div>
                </div>
                </div></div>
                :
            <DataTableComponent
                rows={revisionData}
                cols={REVISION_COLS}
                paginator={true}
                dataKeyId="revisions"
                title={``}
                pageDetails={revisionPageDetails}
                pageIndex={pageIndex}
                onPageChanged={onPageChanged}
                onSortData={onSort}
                showCustomSearchCmp
                getSelectedRowData={handleRowSelect}
                settingRef={settingsRef}
                settingListItems={settingListItems}
                
            />}
            <InformationBox
            showInfoModal={displayInfoModal}
            setShowInfoModal={setDisplayInfoModal}
            message={message}
          />
          </>
    );
};

export default ListRevisions;

const disableEditor = (options) => {
    return <InputText type="text" value={options.value} disabled onChange={(e) => options.editorCallback(e.target.value)} />;
}

const REVISION_COLS = [
	{
		field: "revisions",
		header: "Revision Number",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
        align: 'right',
	},
	{
		field: "description",
		header: "Description",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "dwgFileName",
		header: "File Name",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
	{
		field: "dwgFileSize",
		header: "File Size (KB)",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
        align: 'right',
	},
    {
		field: "uploadedOn",
		header: "Uploaded On",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
	},
    {
		field: "markups",
		header: "Markups",
        style: { flex: '1 0 150px' },
		sortable: true,
        editor: (options) => disableEditor(options),
        align: 'right',
	},

];



