import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ScrollPanel } from "primereact/scrollpanel";
import { Checkbox } from "primereact/checkbox";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
//components
import InspectionForms from "components/common/Forms/InspectionForm/InspectionForms";
import TreeView from "components/common/TreeView/TreeView";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import AddEquipmentList from "../Equipment/ManageEquipmentGrp/AddEquipmentGroup";
import EditEquipmentList from "../Equipment/ManageEquipmentGrp/EditEquipmentGroup";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
//icons
import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getEquipmentPanelList, setEquipmentPanelList, setNewEquipmentGroup, setUpdatedEquipmentGroup } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { getInspectionQuestions, onGetInspectionQuestionFieldValues, onGetMonitoringValues, onSaveInspectionQuestion, setInspectionQuestion, setInspectionQuestionFieldValues, setUpdatedQuestionDatafields } from "redux/pages/Inspection/InspectionTask/InspectionDetails/InspectionDetails/InspectionDetailsSlice";
import { setIsReportUpdated } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
//utility
import { addDefaultDataValues, convertInspectionFields } from "pages/ManageInspection/InspectionFields/Utility/InspectionFieldUtility";
import { getFirstDataFieldItem } from "../Equipment/utility";
import { showUI } from 'service/GeneralUtility';

interface QuestionsPropType {
    selectedTask?: any;
    setIsValueChanged?: any;
    displayConfirmModal?: any;
    setDisplayConfirmModal?: any;
    cancelUpdate?: () => void;
    confirmMessage?: any;
    setConfirmMessage?: any;
    hideConfirmModal?: any;
    reportBtnClicked?: any;
}
const Questions: React.FC<QuestionsPropType> = (props) => {
    const {
        selectedTask,
        setIsValueChanged,
        displayConfirmModal,
        setDisplayConfirmModal,
        cancelUpdate = () => { },
        confirmMessage,
        setConfirmMessage,
        hideConfirmModal,
        reportBtnClicked
          }= props;

    const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [inspectionFormSetup, setInspectionFormSetup] = useState<any>([]);
    const [treeData, setTreeData] = useState<any>([]);
    const [isCopied, setIsCopied] = useState(false);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [copiedList, setCopiedList] = useState([]);
    const [bodyInfo, setBodyInfo] = useState<any>(null);
    const [showEquipmentGroupModal, setShowEquipmentGroupModal] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [showAddEquipmentList, setShowAddEquipmentList] = useState<boolean>(false);
    const [showEditEquipmentList, setShowEditEquipmentList] = useState<boolean>(false);
    const [equipmentPanels, setEquipmentPanels] = useState<any>([]);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [clickedNode, setClickedNode] = useState<any>(null);//keep the selected node value once asked confirmation
    const [showProgress, setShowProgress] = useState(false);//progress bar
    const [selectedTreeNodeItem, setSelectedTreeNodeItem] = useState<any>(null);
    const [resetFormField, setResetFormField] = useState<boolean>(false);
    const [equipmentGroupUpdatedata, setEquipmentGroupUpdateData] = useState<any>(null);

    const inspectionFormRef = useRef<any>(null);
    const isFieldValueChanged = useRef<any>(null);

    const dispatch = useDispatch<any>();
    const equipmentPanelList = useAppSelector((state) => state.equipment.equipmentPanelList);
    const inspectionQuestions = useAppSelector((state) => state.inspectionDetails.inspectionQuestion);
    const updatedDatafields = useAppSelector((state) => state.inspectionDetails.updatedQuestionDatafields);
    const inspectionQuestionFieldValues = useAppSelector((state) => state.inspectionDetails.inspectionQuestionFieldValues);
    const newEquipmentGroup = useAppSelector((state) => state.equipment.newEquipmentGroup);
    const updatedEquipmentGroup = useAppSelector((state) => state.equipment.updatedEquipmentGroup);
    const isReportUpdated = useAppSelector((state) => state.inspectionTask.isReportUpdated);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const monitoringValues = useAppSelector((state) => state.inspectionDetails.monitoringValues);

    const {
    } = useForm<FormData>({
    });

    useEffect(() => {
        if (selectedTask) {
            dispatch(getEquipmentPanelList(selectedTask?.uniqueInspectionTaskId, false));
        }
    }, [selectedTask]);

    useEffect(() => {
        if (equipmentPanelList) {
            setEquipmentPanels(equipmentPanelList)
            setSelectedEquipmentGroup(equipmentPanelList[0]?.equipmentId);
            dispatch(setEquipmentPanelList(""));
        }
    }, [equipmentPanelList]);

    useEffect(() => {
        if (selectedEquipmentGroup) {
            let conv = JSON.parse(JSON.stringify(equipmentPanels))
            let filterItem = conv.filter((item) => item.equipmentId !== selectedEquipmentGroup);
            filterItem.forEach(element => {
                element.checked = false;
            });
            setCopiedList(filterItem);
            dispatch(getInspectionQuestions(selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup));
            dispatch(setIsReportUpdated(false));
        }
    }, [selectedEquipmentGroup, isReportUpdated]);

    const checkNodeExists = (values, elements) => {
        values?.forEach(element => {
            if (element?.key === selectedTreeNodeItem?.key) {
                elements.push(element);
            } else {
                checkNodeExists(element?.children, elements);
            }
        });
        return elements[0];
    };

    useEffect(() => {        
        if (inspectionQuestions?.length) {
            let value: any = JSON.stringify(inspectionQuestions?.[0]?.inspectionGroupSections);
            value = value?.replace(/\"inspectionGroupSectionId\":/g, '"key":');
            value = value?.replace(/\"inspectionGroupSectionName\":/g, '"label":');
            value = value?.replace(/\"inspectionGroupSections\":/g, '"children":');
            value = JSON.parse(value);
            setTreeData(value);
            let element = [];
            let selectedNode = [];
            const selectedElement = checkNodeExists(value, selectedNode);
            if (selectedTreeNodeItem && selectedElement) {
                let convList = convertInspectionFields(selectedElement);
                setInspectionFormSetup(convList);
                setSelectedItem(selectedElement);
                setSelectedTreeNodeItem(selectedElement);
                dispatch(setInspectionQuestion(""));
            } else {
                const firstElement = getFirstDataFieldItem(value, element);
                let convList = convertInspectionFields(firstElement);
                setInspectionFormSetup(convList);
                setSelectedItem(firstElement);
                setSelectedTreeNodeItem(firstElement);
                dispatch(setInspectionQuestion(""));
            };
        }
    }, [inspectionQuestions]);

    useEffect(() => {
        if (inspectionFormSetup && selectedEquipmentGroup) {
            setShowProgress(true);
            dispatch(onGetInspectionQuestionFieldValues(selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup, setShowProgress, handleEquipmentGroupChange));
        }
    }, [inspectionFormSetup, resetFormField])

    useEffect(() => {
        if (updatedDatafields) {
            let conv = JSON.parse(JSON.stringify(inspectionQuestionFieldValues))
            const result = addDefaultDataValues(conv, updatedDatafields?.fieldDetails);
            dispatch(setInspectionQuestionFieldValues(result));
            if (!reportBtnClicked && !equipmentGroupUpdatedata?.value) {
                setMessage("Inspection details saved");
                setDisplayInfoModal(true);
            }
            setShowEquipmentGroupModal(false);
            setIsValueChanged(false);
            isFieldValueChanged.current = null;
            dispatch(setUpdatedQuestionDatafields(""));
            cancelUpdate();
            handleEquipmentGroupChange();
            if (isFieldValueChanged.current && clickedNode) onCancel(clickedNode);
        }
    }, [updatedDatafields]);

    useEffect(() => {
        if (newEquipmentGroup) {
            let lists = JSON.parse(JSON.stringify(equipmentPanels))
            if (newEquipmentGroup?.equipmentId > 0) {
                lists.push(newEquipmentGroup);
                setEquipmentPanels(lists);
                setShowAddEquipmentList(false);
                setMessage("Equipment data added");
                setSelectedEquipmentGroup(newEquipmentGroup?.equipmentId);
            } else {
                setMessage("Address already in use, cannot be added");
            }
            setDisplayInfoModal(true);
            dispatch(setNewEquipmentGroup(""));
        }
    }, [newEquipmentGroup]);

    useEffect(() => {
        if (updatedEquipmentGroup) {
            let lists = JSON.parse(JSON.stringify(equipmentPanels));
            const index = lists?.findIndex((item) => item?.equipmentId === updatedEquipmentGroup?.equipmentId);
            if (index >= 0) {
                lists[index].name = updatedEquipmentGroup?.name;
            }
            setEquipmentPanels([...lists]);
            dispatch(setUpdatedEquipmentGroup(""));
            setShowEditEquipmentList(false)
        }
    }, [updatedEquipmentGroup])

    useEffect(() => {
        if (treeData?.length) {
            let _expandedKeys = {};
            for (let node of treeData) {
                expandNode(node, _expandedKeys);
            }
            setExpandedKeys(_expandedKeys);
        }
    }, [treeData])

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    }

    const onNodeClick = useCallback((data) => {
        if (isFieldValueChanged.current && selectedTask?.status === "Active" && data?.label !== selectedItem?.label ){
            setClickedNode(data);
            setDisplayConfirmModal(true)
            setConfirmMessage(`Do you want to save the changes to`)
        } else {
            let convList = convertInspectionFields(data)
            setInspectionFormSetup(convList);
            setSelectedItem(data);
            setSelectedTreeNodeItem(data);
        }
    }, [selectedItem, isFieldValueChanged]);

    const onInspectionSubmit = useCallback((data) => {
        const body = {
            fieldDetails: data,
            nodeId: inspectionFormSetup?.key
        }
        if (isCopied) {
            setBodyInfo(body);
            setShowEquipmentGroupModal(true);
            if (!copiedList?.length) {
                setDisplayInfoModal(true);
                setMessage("No Other Equipment Groups exist");
            }
        } else {
            setShowProgress(true);
            dispatch(onSaveInspectionQuestion(selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup, body, setShowProgress));
        }
    },
        [isCopied, bodyInfo, showEquipmentGroupModal, inspectionFormSetup]
    );

    const onInspectionSubmitWithCopies = () => {
        const filtered = copiedList.filter((item: any) => item.checked);
        if (!filtered?.length) {
            setDisplayInfoModal(true);
            setMessage("Select Equipment Group(s)");
            return;
        }
        const removedDataSets = filtered?.map(({ checked, name, ...rest }: any) => {
            return rest;
        });
        bodyInfo.copyIds = removedDataSets;
        setShowProgress(true);
        dispatch(onSaveInspectionQuestion(selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup, bodyInfo, setShowProgress));
    }

    const getAllChecked = () => {
        const filtered = copiedList.filter((item: any) => item.checked);
        if (filtered?.length === copiedList?.length) return true;
        else return false;
    }

    const onAddEquipmentGroupClick = () => {
        if (selectedEquipmentGroup === null || selectedEquipmentGroup === "ALL" || selectedEquipmentGroup === 0) {
            setMessage(
                selectedEquipmentGroup === null ? "Select an Equipment Group"
                    : selectedEquipmentGroup === "ALL" ? "Select an Equipment Group"
                        : selectedEquipmentGroup === 0 ? "Select an Equipment Group"
                            : ""
            );
            setDisplayInfoModal(true);
        }
        else {
            setShowAddEquipmentList(true)
        }
    }

    const onEditEquipmentGroupClick = () => {
        if (selectedEquipmentGroup === null || selectedEquipmentGroup === "ALL" || selectedEquipmentGroup === 0 || selectedEquipmentGroup === undefined) {
            setMessage(
                selectedEquipmentGroup === null ? "Select an Equipment Group"
                    : selectedEquipmentGroup === "ALL" ? "Select an Equipment Group"
                        : selectedEquipmentGroup === 0 ? "Select an Equipment Group"
                            : selectedEquipmentGroup === undefined ? "Select an Equipment Group"
                                : ""
            );
            setDisplayInfoModal(true);
        }
        else {
            setShowEditEquipmentList(true)
        }
    }

    const onConfirmClick = () => {
        setDisplayConfirmModal(false);
        inspectionFormRef?.current?.requestSubmit();
    }

    const onCancel = (data) => {
        if (!reportBtnClicked && !equipmentGroupUpdatedata?.value) {
            let convList = convertInspectionFields(data)
            setInspectionFormSetup(convList);
            setSelectedItem(data);
            setSelectedTreeNodeItem(data);
            setDisplayConfirmModal(false);
            setIsValueChanged(false);
            isFieldValueChanged.current = null;
            setClickedNode(null);
        } else {
            setResetFormField(!resetFormField);
            if(selectedItem?.key === 31){
                dispatch(onGetMonitoringValues(selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup, setShowProgress, !isMonitoring));
                setIsMonitoring(!isMonitoring);
            }
        }
        cancelUpdate();
        isFieldValueChanged.current = null;
    };

    const onHide = () => {
        setDisplayConfirmModal(false);
        setEquipmentGroupUpdateData(null);
        setClickedNode(null);
        hideConfirmModal();
    };

    const checkIsValueChanged = useCallback((e) => {
        if (e) {
            setIsValueChanged(true);
            isFieldValueChanged.current = true;
        }
    }, []);

    useEffect(() => {
        if (displayConfirmModal) {
            setIsCopied(false);
        }
    }, [displayConfirmModal]);

    const onEquipmentGroupUpdates = (value, data?: any) => {
        if (isFieldValueChanged.current && selectedTask?.status === "Active") {
            setDisplayConfirmModal(true);
            setConfirmMessage(`Do you want to save the changes to`);
            switch (value) {
                case 'ADD': {
                    setEquipmentGroupUpdateData({ value: "ADD", data: "" });
                    break;
                }
                case 'EDIT': {
                    setEquipmentGroupUpdateData({ value: "EDIT", data: "" });
                    break;
                }
                case 'CHANGE': {
                    setEquipmentGroupUpdateData({ value: "CHANGE", data: data });
                    break;
                }
            }
        } else {
            switch (value) {
                case 'ADD': {
                    onAddEquipmentGroupClick();
                    break;
                }
                case 'EDIT': {
                    onEditEquipmentGroupClick();
                    break;
                }
                case 'CHANGE': {
                    setSelectedEquipmentGroup(data);
                    break;
                }
            }
        }
    };

    const handleEquipmentGroupChange = () => {
        if(equipmentGroupUpdatedata && equipmentGroupUpdatedata.value) {
            switch (equipmentGroupUpdatedata?.value) {
                case 'ADD': {
                    onAddEquipmentGroupClick();
                    setEquipmentGroupUpdateData(null);
                    break;
                }
                case 'EDIT': {
                    onEditEquipmentGroupClick();
                    setEquipmentGroupUpdateData(null);
                    break;
                }
                case 'CHANGE': {
                    setSelectedEquipmentGroup(equipmentGroupUpdatedata.data);
                    setEquipmentGroupUpdateData(null);
                    break;
                }
            }
        }
    };

    const onInspectionFieldError = (error) => {
        if(error) {
            setMessage(error);
            setDisplayInfoModal(true);
        }
    };

    const renderDeleteFooter = () => {
        return (
            <>
                <Button disabled={!copiedList?.length ? true : false} label="Save Changes" className="save-btn" onClick={() => onInspectionSubmitWithCopies()} />
            </>
        );
    };

    return (
        <>
            {showProgress &&
                (
                    <div className="pre-loader inspection-questions">
                        <ProgressSpinner />
                    </div>
                )
            }
            <div className="inspection-questions">
                <div className="tree-wrapper">
                    <div className="select-box field mb-0">
                        <div className="action-section custom-float-field mb-0">
                            <div className="field mb-0">
                                <span className="p-float-label">
                                    <Dropdown
                                        inputId="equipment-group"
                                        className="w-100"
                                        value={selectedEquipmentGroup}
                                        onChange={(e) => {
                                            onEquipmentGroupUpdates("CHANGE", e.value);
                                        }}
                                        options={equipmentPanels}
                                        optionLabel="name"
                                        optionValue="equipmentId"
                                    />
                                    <label htmlFor="dropdown">Equipment Group</label>
                                </span>
                            </div>
                            {showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_EQUIPMENTGROUP_MANAGE") && selectedTask?.status === "Active" && (
                                <div className="icon-fields">
                                    <div className="field">
                                        <span className="p-float-label action-btns center">
                                            <Button className="button-icon">
                                                <BsPlusLg className="icon" onClick={() => onEquipmentGroupUpdates("ADD")} title="Add" />
                                            </Button>
                                        </span>
                                    </div>
                                    <div className="field mt-2">
                                        <span className="p-float-label action-btns center">
                                            <Button className="button-icon">
                                                <BsFillPencilFill className="icon" onClick={() => onEquipmentGroupUpdates("EDIT")} title="Edit" />
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="if-border">
                        <ScrollPanel className="treeview-scroll">
                            <TreeView
                                selectionMode="single"
                                onNodeClick={onNodeClick}
                                onToggle={e => setExpandedKeys(e.value)}
                                treeData={treeData}
                                selectedItem={selectedItem?.key}
                                expandedKeys={expandedKeys}
                                isCustomTemplateForNode = {true} />
                                
                        </ScrollPanel>
                    </div>
                </div>
                <div className={`questions-wrap`}>
                    <InspectionForms
                        type='QUESTION'
                        ref={inspectionFormRef}
                        isAddAction={true}
                        isCheckedAction={true}
                        showAction={false}
                        formElements={inspectionFormSetup}
                        inspectionFieldValues={inspectionQuestionFieldValues}
                        monitoringValues={monitoringValues}
                        checkIsValueChanged={checkIsValueChanged}
                        onInspectionSubmit={onInspectionSubmit}
                        onInspectionFieldError={onInspectionFieldError}
                    />
                    {inspectionFormSetup?.inspectionGroupFields?.length > 0 && selectedTask?.status === "Active" && (
                        <div className="footer-btn text-left">
                            <div className="field-checkbox">
                                {equipmentPanels?.length > 1 &&
                                    <>
                                        <Checkbox inputId="copy" name="Copy data to other Equipment Groups" onChange={(e) => setIsCopied(e.checked)} checked={isCopied} />
                                        <label className="mr-3" htmlFor="SendLog">Copy data to other Equipment Groups </label>
                                    </>
                                }
                                {selectedItem?.key === 31 &&
                                    <>
                                        <Checkbox
                                            inputId="moitoring"
                                            name="Siemens Monitoring"
                                            onChange={(e) => {
                                                dispatch(onGetMonitoringValues(selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup, setShowProgress, e.checked))
                                                setIsMonitoring(e.checked)
                                                setIsValueChanged(true);
                                                isFieldValueChanged.current = true;
                                            }}
                                            checked={isMonitoring} />
                                        <label htmlFor="monitoring">Siemens Monitoring</label>
                                    </>
                                }
                            </div>
                            {/* {selectedTask && selectedTask?.status === "Active" && showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_QUESTIONS_SAVE") &&  */}
                            <Button className={`save-btn`} onClick={() => inspectionFormRef?.current?.requestSubmit()}>
                                Save
                            </Button>
                            {/* } */}
                        </div>
                    )}
                </div>
                {showEquipmentGroupModal && (
                    <Dialog
                        header="Equipment Groups"
                        visible={showEquipmentGroupModal}
                        style={{ width: "35vw" }}
                        footer={renderDeleteFooter()}
                        onHide={() => setShowEquipmentGroupModal(false)}
                        className="add-overlay"
                    >
                        <form>
                            <div className="row px-2">
                                <div className="field col-12 md:col-2 mb-0">
                                    <div className="field-checkbox pt-2">
                                        <Checkbox
                                            inputId="binary"
                                            checked={getAllChecked()}
                                            onChange={(e) => {
                                                copiedList.forEach((element: any) => {
                                                    element.checked = e.checked
                                                });
                                                setCopiedList([...copiedList])
                                            }}
                                        />
                                        <label htmlFor="report1">Select All</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row px-2">
                                <div className="field col-12 md:col-4">
                                    <div className="checkbox-list">
                                        <ScrollPanel style={{ width: "100%", height: "150px" }} className="checkbox-list-scrollbar">
                                            {copiedList?.map((item: any, key) => (
                                                <div className="field-checkbox px-2 pt-2" key={key}>
                                                    <Checkbox inputId={item.equipmentId} value={item.equipmentId} checked={item.checked} onChange={(e) => {
                                                        copiedList.forEach((element: any) => {
                                                            if (element?.equipmentId === e.value) {
                                                                element.checked = e.checked
                                                            }
                                                        });
                                                        setCopiedList([...copiedList])
                                                    }} />
                                                    <label htmlFor={item.equipmentId}>{item.name}</label>
                                                </div>
                                            ))
                                            }
                                        </ScrollPanel>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Dialog>
                )}
                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                />
                <ConfirmationBox
                    showConfirmModal={displayConfirmModal}
                    setShowConfirmModal={setDisplayConfirmModal}
                    cancelAction={() => onCancel(clickedNode)}
                    onHideAction={onHide}
                    confirmAction={onConfirmClick}
                    message={confirmMessage + ` ${selectedItem?.label}?`}
                />
                {showAddEquipmentList &&
                    <AddEquipmentList
                        selectedTask={selectedTask}
                        showModal={showAddEquipmentList}
                        setShowModal={setShowAddEquipmentList} />}
                {showEditEquipmentList &&
                    <EditEquipmentList
                        selectedPanel={selectedEquipmentGroup}
                        selectedTask={selectedTask}
                        showModal={showEditEquipmentList}
                        setShowModal={setShowEditEquipmentList} />}
            </div>
        </>
    );
};

export default Questions;
