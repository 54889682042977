import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
//components
import FormElement from '../Utility/FormElement';
//redux
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { getAdditionalDatafieldSets, getDatafieldValues, setAdditionalDatafieldSets, setAdditionalDatafieldValues } from 'redux/common/commonSlice';
import { setIsDevReadingChanged, setIsTestedDateChanged } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import moment from 'moment';

interface EquipmentFormPropType {
    datafields?: any;
    ddAppendTo?: 'self' | undefined;
    isEditForm?: boolean;
    onFormSubmit?: (body, reset, setValue) => void;
    body?: any;
}
const EquipmentForm = forwardRef<any, EquipmentFormPropType>((props, ref) => {

    const { datafields, isEditForm, onFormSubmit = () => { }, body, ddAppendTo = undefined } = props;
    const { control, formState: { errors }, handleSubmit, watch, setValue, unregister, reset } = useForm({mode:'all'});
    const addForm = useRef<any>(null);
    const [datafieldlist, setDatafieldList] = useState<any>([]);
    const [focusElement, setFocusElement] = useState<any>(null);

    const dispatch = useDispatch<any>();
    const additionalFieldValues = useAppSelector((state) => state.common.additionalDatafieldValues);
    const additionalDatafieldSets = useAppSelector((state) => state.common.additionalDatafieldSets);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);

    useImperativeHandle(ref, () => ({
        requestSubmit: () => {
            addForm?.current?.requestSubmit();
        },
    }));

    useEffect(() => {
        let fieldsConv = JSON.parse(JSON.stringify(datafields))
        if (fieldsConv?.length) {
            fieldsConv.forEach(element => {  
                const devReadingDateIndex = fieldsConv?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
                if (devReadingDateIndex > 0) {
                    if (element?.dataFieldName === "Dev Reading") {
                        if (element?.fieldValue === '') {
                            fieldsConv[devReadingDateIndex].disabled = true;
                        }
                        else {
                            fieldsConv[devReadingDateIndex].disabled = false;
                        }
                    }
                }
                    
                if (element?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                    const testDateIndex = fieldsConv?.findIndex((item) => item.dataFieldName === "Test Date");
                    if (element?.dataFieldName=== "Test Result") {
                        const slectedItem = element.validatedValues?.filter((item) => item?.isSelected);
                        if (slectedItem[0]?.dataFieldLookupValue === "Not Tested"){                  
                        fieldsConv[testDateIndex].disabled = true;
                    }
                    else{
                        fieldsConv[testDateIndex].disabled = false;
                    }
                    }
                    if (element?.dataFieldName=== "Test Method") {
                        const slectedItem = element.validatedValues?.filter((item) => item?.isSelected);
                        if (slectedItem[0]?.dataFieldLookupValue === "None"){                  
                        fieldsConv[testDateIndex].disabled = true;
                    }
                    else{
                        fieldsConv[testDateIndex].disabled = false;
                    }
                    }
                    if (element.defaultSelectNeeded)
                        element.validatedValues.unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
                    const slectedItem = element.validatedValues?.filter((item) => item?.isSelected);
                    if (slectedItem?.length) {
                        setValue(element.uniqueDataFieldId, slectedItem[0].dataFieldLookupId);
                    } else {
                        setValue(element.uniqueDataFieldId, -1)
                    }
                } else if (element?.dataEntryControl?.dataEntryControlName === "Check Box(s)") {
                    if (element.fieldValue) {
                        setValue(element.uniqueDataFieldId, element.fieldValue)
                    } else {
                        let list: any = [];
                        for (let j = 0; j < element?.validatedValues?.length; j++) {
                            let sm = element.validatedValues[j].dataFieldLookupId;
                            let item = {
                                [sm]: false,
                            };
                            list.push(item);
                        }
                        setValue(element?.uniqueDataFieldId, list);
                    }
                } else
                    setValue(element.uniqueDataFieldId, element.fieldValue);
            });
            const ascDatafields = [...fieldsConv].sort((a, b) => a.positionNo - b.positionNo);
            setDatafieldList([...ascDatafields]);
        }
    }, [datafields]);

    useEffect(() => {
        if (additionalFieldValues) {
            let lists = JSON.parse(JSON.stringify(datafieldlist))
            let fieldValues = JSON.parse(JSON.stringify(additionalFieldValues));
            const findIndex = lists?.findIndex((item) => item.uniqueDataFieldId === fieldValues[1]);
            if (findIndex >= 0) {
                if (lists[findIndex]?.defaultSelectNeeded) {
                    fieldValues[0].unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
                }
                lists[findIndex].validatedValues = fieldValues[0];
            }
            const filterData: any = fieldValues[0].filter(
                (item: any) =>
                    item.isSelected === true
            );
            if (findIndex >= 0 && filterData.length > 0) {
                lists[findIndex].fieldValue = filterData[0].dataFieldLookupId.toString();
                setValue(datafields[findIndex].uniqueDataFieldId, parseInt(filterData[0].dataFieldLookupId))
            }
            else {
                setValue(datafields[findIndex].uniqueDataFieldId, -1)
            }

            setDatafieldList([...lists]);
            dispatch(setAdditionalDatafieldValues(""));
        }
    }, [additionalFieldValues]);

    useEffect(() => {
        if (additionalDatafieldSets?.length) {
            let lists = JSON.parse(JSON.stringify(datafieldlist))
            let count = 0;
            for (let i = 0; i < datafieldlist?.length; i++) {
                if (datafieldlist[i].parentUniqueDataFieldId) {
                    unregister(datafieldlist[i]?.uniqueDataFieldId);
                    lists.splice(i - count, 1);
                    count++;
                }
            }
            let fieldCount = 0;
            let filteredArray = JSON.parse(JSON.stringify(additionalDatafieldSets));
            for (let i = 0; i < lists?.length; i++) {
                for (let j = 0; j < filteredArray?.length; j++) {
                    if (lists[i].uniqueDataFieldId === filteredArray[j].uniqueDataFieldId && filteredArray[j].hasToBeDeleted === 0) {
                        filteredArray.splice(j, 1);
                        fieldCount++;
                    }
                }
            }

            for (let i = 0; i < filteredArray?.length; i++) {
                if (filteredArray[i]?.hasToBeDeleted === 0)
                    lists.push(filteredArray[i]);
                else {
                    const removeIndex = lists.findIndex((item) => item.uniqueDataFieldId === filteredArray[i].uniqueDataFieldId);
                    if (removeIndex >= 0) {
                        unregister(lists[removeIndex]?.uniqueDataFieldId);
                        lists.splice(removeIndex, 1);
                    }
                }
            }
            const ascDatafields = [...lists].sort((a, b) => a.positionNo - b.positionNo);
            setDatafieldList([...ascDatafields]);
            dispatch(setAdditionalDatafieldSets(""));
        }

    }, [additionalDatafieldSets])

    useLayoutEffect(() => {
        if (datafieldlist?.length) {
            if (focusElement) {
                const element = document.getElementsByName(focusElement);
                element?.[0]?.focus();
                setFocusElement(null);
            }
        }
    }, [datafieldlist])

    const onSubmit = (data) => {
        let fields: any = [];
        for (const field of datafieldlist) {
            const { uniqueDataFieldId } = field;
            if (data.hasOwnProperty(uniqueDataFieldId)) {
                let item: any = {
                    dataFieldId: uniqueDataFieldId,
                    value: null,
                    multipleValue: null,
                };
    
                if (Array.isArray(data[uniqueDataFieldId])) {
                    let multipleValue: any = [];
                    for (let i = 0; i < data[uniqueDataFieldId].length; i++) {
                        for (const obj in data[uniqueDataFieldId][i]) {
                            if (data[uniqueDataFieldId][i][obj]) {
                                multipleValue.push(obj);
                            }
                        }
                    }
                    item.multipleValue = multipleValue;
                } else {
                    if (field.dataEntryControl.dataEntryControlName === "Drop-down list" && data[uniqueDataFieldId] === -1) {
                        data[uniqueDataFieldId] = "";
                    }
                    if ((field.dataType.dataTypeId === 7) && (field.dataFieldName === "Test Date" || field.dataFieldName === "Dev Reading Date") && data[uniqueDataFieldId]?.length > 0) {
                        let newDate = new Date(data[uniqueDataFieldId]);
                        const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset;
                        data[uniqueDataFieldId] = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");
                    }
                    item.value = data[uniqueDataFieldId]?.toString();
                }
                fields.push(item);
            }
        }
        onFormSubmit(fields, reset, setValue);
        dispatch(setIsDevReadingChanged(''));
        dispatch(setIsTestedDateChanged(''));
    }
    
    const onSelectionChange = useCallback((data, id) => {
        if (data?.dataFieldRelation !== undefined) {
            onFieldRelationChange(data, id);
        }
        if (data?.dataFieldSet !== undefined) {
            onDataFieldSetChange(data, id);
        }
        onCheckDropDownChanges(data, id);

    }, [datafieldlist]);

    const onTextBoxChange = useCallback((data,e) => {
        onCheckTextBoxChange(data,e);
    }, [datafieldlist]);

    const onCheckTextBoxChange = (data,e) => {
        if (data.dataFieldName === "Dev Reading") {
            const devReadingDate = datafieldlist?.filter((item) => item.dataFieldName === "Dev Reading Date");
            const devReadingDateindex = datafieldlist?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
            if (devReadingDateindex > 0){
                if(e.currentTarget.value?.length>0){
                    datafieldlist[devReadingDateindex].disabled = false;
                    setValue(devReadingDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"));
                }else{
                    datafieldlist[devReadingDateindex].disabled = true;
                    setValue(devReadingDate[0]?.uniqueDataFieldId,''); 
                } 
            }
        }   
    }
    const onDateTimeChanged = useCallback((data,e) => {
        onCheckDateTimeChanged(data,e);
    }, [datafieldlist]);

    const onCheckDateTimeChanged = useCallback((data,e) => {
        if (data.dataFieldName === "Dev Reading Date") {
            dispatch(setIsDevReadingChanged('D'));
        } 
        if(data.dataFieldName === "Test Date"){
            dispatch(setIsTestedDateChanged('T'));
        }
    }, []);

    const onCheckDropDownChanges = (data, id) => {
        let isChecked = false;
        const testResIndex = datafieldlist?.findIndex((item) => item.dataFieldName === "Test Result");
        const testDateIndex = datafieldlist?.findIndex((item) => item.dataFieldName === "Test Date");
        if (data.dataFieldName === "Test Method") {
            if (id) {
                if (testResIndex >= 0) {
                    const testDate = datafieldlist?.filter((item) => item.dataFieldName === "Test Date");
                    const items = data?.validatedValues?.filter((item) => item.dataFieldLookupId === id);
                    if (items[0]?.dataFieldLookupValue !== "None") {
                        setValue(testDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"));
                        datafieldlist[testDateIndex].disabled = false;
                    }
                    else {
                        setValue(testDate[0]?.uniqueDataFieldId,'');
                        datafieldlist[testDateIndex].disabled = true;
                    }
                    datafieldlist[testResIndex].disabled = false;
                    const none = datafieldlist[testResIndex]?.validatedValues?.filter((item) => item?.dataFieldLookupId !== 3);
                    datafieldlist[testResIndex].validatedValues = none;
                    const passed = datafieldlist[testResIndex]?.validatedValues?.filter((item) => item?.dataFieldLookupValue === "Passed");
                    if (passed?.length) setValue(datafieldlist[testResIndex]?.uniqueDataFieldId, passed[0].dataFieldLookupId);
                }
                if (testDateIndex >= 0) {
                    setValue(datafieldlist[testDateIndex]?.uniqueDataFieldId,  moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"));
                }
            } else {
                if (testResIndex >= 0) {
                    const testDate = datafieldlist?.filter((item) => item.dataFieldName === "Test Date");
                    const items = data?.validatedValues?.filter((item) => item.dataFieldLookupId === id);
                    if (items[0]?.dataFieldLookupValue !== "None") {
                        setValue(testDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"));
                        datafieldlist[testDateIndex].disabled = false;
                    }
                    else {
                        setValue(testDate[0]?.uniqueDataFieldId,'');
                        datafieldlist[testDateIndex].disabled = true;
                    }
                    datafieldlist[testResIndex].validatedValues.push({
                        dataFieldLookupId: 3,
                        dataFieldLookupValue: "Not Tested",
                        isSelected: true
                    })
                    setValue(datafieldlist[testResIndex]?.uniqueDataFieldId, 3);
                    datafieldlist[testResIndex].disabled = true;
                }
                if (testDateIndex >= 0) {
                    setValue(datafieldlist[testDateIndex]?.uniqueDataFieldId, "");
                }
            }
            isChecked = true;
        } else if (data.dataFieldName === "Test Result") {
            if (id) {
                const testDate = datafieldlist?.filter((item) => item.dataFieldName === "Test Date");
                const items = data?.validatedValues?.filter((item) => item.dataFieldLookupId === id);
                if (items[0]?.dataFieldLookupValue !== "Not Tested") {
                    setValue(testDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"));
                    datafieldlist[testDateIndex].disabled = false;
                }
                else {
                    setValue(testDate[0]?.uniqueDataFieldId,'');
                    datafieldlist[testDateIndex].disabled = true;
                }   
            }
            isChecked = true;      
        }
        if (isChecked) {
            setFocusElement(data?.uniqueDataFieldId);
            setDatafieldList([...datafieldlist]);
        }

    }

    const onFieldRelationChange = (data, id) => {
        let service: any = data?.dataFieldRelation?.subSetValueService;
        if (service !== undefined) {
            service = service.replace("{dataFieldLookupId}", id);
            dispatch(getDatafieldValues(service, data?.dataFieldRelation?.childDataFieldId, body));
            setFocusElement(data?.uniqueDataFieldId);
        }
    }

    const onDataFieldSetChange = (data, id) => {
        let service: any = data?.dataFieldSet?.subSetValueService;
        if (service !== undefined) {
            service = service.replace("{dataFieldLookupId}", id);
            dispatch(getAdditionalDatafieldSets(service, body));
            setFocusElement(data?.uniqueDataFieldId);
        }
    }

    return (
        <>
            <form className="form-data-wrapper" onSubmit={handleSubmit(onSubmit)} ref={addForm}>
                {datafieldlist?.length > 0 &&
                    datafieldlist?.map((item: any, key: any) => (
                        <FormElement
                            key={key}
                            data={item}
                            control={control}
                            ddAppendTo={ddAppendTo}
                            setValue={setValue}
                            watch={watch}
                            onSelectionChange={onSelectionChange}
                            onDateTimeChanged={onDateTimeChanged}
                            onTextBoxChange={onTextBoxChange}
                            errors={errors} 
                             />
                            
                    ))
                }
            </form>
        </>
    )
})

export default memo(EquipmentForm)