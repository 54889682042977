import React, { useEffect, useState ,useCallback} from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import { APP_ROUTES } from "constants/AppRoutes";
import { LOG_COLS } from "./CopyMoveLogsData";

import { Button } from "primereact/button";
import { FaDownload } from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ScrollPanel } from "primereact/scrollpanel";
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import moment from "moment";

//components
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import { DataTableComponent } from "components/Table/DataTable";

//redux
import { useDispatch } from "react-redux";
import { onGetUserLists,onGetCopyMoveLogDetails} from "redux/pages/Manage/Tools/ToolSlice";
import { useAppSelector } from "redux/hooks"
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface CopyMoveLogPropTypes { }

export const CopyMoveLogs: React.FC<CopyMoveLogPropTypes> = (props) => {

    const navigate = useNavigate();
    const [bodyInfo, setBodyInfo] = useState<any>({
        fromDate: moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("yyyy-MM-DD"),
        toDate: moment(new Date()).format("yyyy-MM-DD"),
    });

    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [gridData, setGridData] = useState<any>([]);
    const [pageDetails, setPageDetails] = useState<any>({});
    const [pageIndex, setPageIndex] = useState(0);
    const [currentStart, setCurrentStart] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [selectedAction, setSelectedAction] = useState<any>('All');
    const [selectedUser, setSelectedUser] = useState<any>(0);
    const [userList, setUserList] = useState<any>([]); 
    
    const Actions = [
        { name: 'All', value: 'All' },
        { name: 'Copy', value: 'Copy' },
        { name: 'Move', value: 'Move' },       
    ];
    let userData: any = []; 
        userData.push({ "name":'All',"value": 0 });

    const dispatch = useDispatch<any>();
    const userLists = useAppSelector((state) => state.tool.userLists);
    const copyMoveLogDetails = useAppSelector((state) => state.tool.copyMoveLogDetails);
    const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

    useEffect(() => {
        let body = {
            fromDate:bodyInfo.fromDate,
            toDate:bodyInfo.toDate,
            action:selectedAction,
            actionBy:selectedUser === 0?'0':selectedUser,
        }
        dispatch(onGetCopyMoveLogDetails(body,0));
    },[selectedUser, rowsPerPage])
    
    useEffect(() => {
        if (selectedAction) {
            let body = {
                fromDate: bodyInfo.fromDate,
                toDate: bodyInfo.toDate,
                action: selectedAction,
            }
            dispatch(onGetUserLists(body));
        }

    }, [bodyInfo, selectedAction]);

    useEffect(() => {     
        if (copyMoveLogDetails) {
            setPageDetails(copyMoveLogDetails?.pageDetails)
            gridBinding()  
        }
    }, [copyMoveLogDetails]);

    useEffect(() => {
        if (userLists) { 
            setSelectedUser(0) 
             userLists.userDetails.map((item: any) => {
                       return userData.push({
                           name: item.userName, 
                           value: item.uniqueUserId, 
                    });
             }); 
             setUserList(userData); 
         }
     }, [userLists])

    useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
            let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
            setPageDetails(modifiedPageDetails);
            setCurrentStart(1)
            setPageIndex(0)
        }
    }, [rowsPerPage])
    
    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows(data)
    }, []);
    
    const onPageChanged = (options: any) => {   
        if (options !== pageIndex) { 
            let currentStart = (options * pageDetails?.currentLength) + 1;
            setPageIndex(options)
            setCurrentStart(currentStart)
            let body = {
                fromDate:bodyInfo.fromDate,
                toDate:bodyInfo.toDate,
                action:selectedAction,
                actionBy:selectedUser === 0?'0':selectedUser
            } 
            dispatch(onGetCopyMoveLogDetails(body,currentStart,sortField, sortOrder))           
      }
    };

    const onExportAll = () => {
		const reportParameters = {
			exportParameters: {
                fromDate: bodyInfo.fromDate,
                toDate: bodyInfo.toDate,
                action: selectedAction,
                actionBy: selectedUser === 0 ? '0' : selectedUser,
				sortColumn: sortField,
				sortOrder: sortOrder,
			 },
			exportType: "Copy Move Log"
		};
        let exportFileName = selectedAction === "All" ? "CopyMoveLog Export" :  selectedAction === "Copy" ? "CopyLog Export" : "MoveLog Export"
		dispatch(onExportReport(reportParameters, exportFileName));
	  };

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
          sortDirection = "ASC"
        else
          sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
        let body = {
            fromDate:bodyInfo.fromDate,
            toDate:bodyInfo.toDate,
            action:selectedAction,
            actionBy:selectedUser === 0?'0':selectedUser
        }
        dispatch(onGetCopyMoveLogDetails(body,currentStart,event?.sortField, sortDirection)); 
      }

    const onClear = () => {
        bodyInfo.fromDate = moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("yyyy-MM-DD");
        bodyInfo.toDate = moment(new Date()).format("yyyy-MM-DD");
        setBodyInfo(bodyInfo);
        setSelectedAction('All')
        setSelectedUser(0)
        let body = {
            fromDate:bodyInfo.fromDate,
            toDate:bodyInfo.toDate,
            action:'All',
            actionBy:'0'
        }
            
        dispatch(onGetCopyMoveLogDetails(body,currentStart,sortField, sortOrder))
    }
    
    const onSearch = () => {
		if (bodyInfo.fromDate && bodyInfo.toDate) {
			if (new Date(bodyInfo.fromDate) <= new Date(bodyInfo.toDate)) {
			 { 
                    let body = {
                        fromDate:bodyInfo.fromDate,
                        toDate:bodyInfo.toDate,
                        action:selectedAction,
                        actionBy:selectedUser === 0?'0':selectedUser
                    }    
                     dispatch(onGetCopyMoveLogDetails(body,0))
				} 
			} else {
				setDisplayInfoModal(true);
				setMessage('End Date should be greater than Start Date');
			}
		}
	};

    const gridBinding = () => {
        let gridItems :any =[]
        copyMoveLogDetails.copyMoveLogs.map((item:any)=>{
           return gridItems.push({
            action:item.action,
            actionBy:item.actionBy,
            date:item.date,
            sourceCustomer:item.sourceCustomer,
            destinationCustomer:item.destinationCustomer,
            copyMoveLogSummaryId:item.copyMoveLogSummaryId,
            actionCategory:item.actionCategory,
            taskCategory:item.taskCategory,
            isAllReports:item.isAllReports,
            source:item.source,
            destination:item.destination,
        })
        })
        setGridData(gridItems);  
        }
    const rowExpandedTemplate = (data: any) => { 
        return(
            <div className="copy-move-expand">
                <div className="header">
                    <h6>{data.action} Log Details</h6>
                </div>
                <ScrollPanel className="copy-move-scroll">
                    <div className="data-list-wrapper">
                        <div className="type-list">
                            <div className="item">
                                <h6>Date</h6>
                                <span>: {data.date}</span>
                            </div>
                            <div className="item">
                                <h6>Action by</h6>
                                <span>: {data.actionBy}</span>
                            </div>
                            <div className="item">
                                <h6>{data.action} All Reports</h6>
                                <span>: {data.isAllReports}</span>
                            </div>
                        </div>
                        <div className="type-list">
                            <div className="item">
                                <h6>{data.action}</h6>
                                <span>: {data.actionCategory}</span>
                            </div>
                            <div className="item">
                                <h6>Task</h6>
                                <span>: {data.taskCategory}</span>
                            </div>
                        </div>
                    </div>
                    <div className="source-destination">
                        <div className="border-col">
                            <div className="title-height">{/* don't remove */}</div>
                            <div className="row-col">
                                <h6>Customer</h6>
                            </div>
                            <div className="row-col">
                                <h6>Site</h6>                  
                            </div>
                            {data.source?.contractDetails.map((contractItem,contractKey) => {
                              return (
                                <>
                                <div className="row-col" key={contractKey}>
                                    <h6>Contract</h6>  
                               </div>                      
                               {contractItem.systemDetails.map((systemItem,systemKey)=> {
                                return(
                                    <>
                                <div className="row-col" key={systemKey}>
                                    <h6>System</h6>
                                </div>
                                {systemItem.taskDetails.map((taskItem,taskKey)=>{
                                    return (
                                    <div className="row-col" key={taskKey}>
                                        <h6>Task</h6>                          
                                    </div>
                                ); 
                                })
                                 }
                           </> 
                           );
                        })
                                 }
                            </>
                            );
                        })          
                        }
                        </div>
                        <div className="border-col">
                            <div className="title"><h6>Source</h6></div>
                            <div className="row-col">
                                <span>  {data.sourceCustomer}</span>
                            </div>
                            <div className="row-col">                
                                <span> {data.source?.site}</span>
                            </div>
                            {data.source?.contractDetails.map((contractItem,contractKey) => {
                              return (
                                <>
                                <div className="row-col" key={contractKey}>                       
                                    <span> {contractItem.contractName}</span>   
                               </div>                      
                               {contractItem.systemDetails.map((systemItem,systemKey)=> {
                                return(
                                    <>
                                <div className="row-col" key={systemKey}>                   
                                    <span> {systemItem.systemName}</span>
                                </div>
                                {systemItem.taskDetails.map((taskItem,taskKey)=>{
                                    return (
                                    <div className="row-col" key={taskKey}>                                                         
                                        <span> {taskItem.taskName}</span>                             
                                    </div>
                                ); 
                                })
                                 }
                           </> 
                           );
                        })
                                 }
                            </>
                            );
                        })          
                        }
                        </div>
                        <div className="border-col">
                            <div className="title"><h6>Destination</h6></div>
                            <div className="row-col">
                                <span>  {data.destinationCustomer}</span>
                            </div>
                            <div className="row-col">
                                <span> {data.destination?.site}</span>
                            </div>
                            {data.destination?.contractDetails.map((contractItem,contractKey) => {
                              return (
                                <>
                            <div className="row-col" key = {contractKey}>
           
                                <span> {contractItem.contractName}</span>
                            </div>
                            {contractItem.systemDetails.map((systemItem,systemKey)=> {
                                return(
                                    <>
                            <div className="row-col" key={systemKey}>
               
                                <span> {systemItem.systemName}</span>
                            </div>
                            {systemItem.taskDetails.map((taskItem,taskKey)=>{
                                return (
                            <div className="row-col" key={taskKey}>
            
                                <span> {taskItem.taskName}</span>
                            </div>
                                    ); 
                                })
                                 }
                           </> 
                           );
                        })
                                 }
                            </>
                            );
                        })          
                        }
                        </div>
                    </div>
                </ScrollPanel>
            </div>
        )    
    }
   
    return(
        <div className="copy-move-wrapper">
            <section className="d-flex align-items-center">
                <Breadcrumb title="Tools" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>Copy / Move Logs</span>
                </div>
            </section>
            <div className="heading">
                Copy / Move Logs
            </div>
            <div className="form-wrapper">
                <div className="col-one">

                    <div className="field mb-0">
                       <div className="custom-float-field mb-0"> 
                        <span className="p-float-label">
                            <Calendar
                                id="single"
                                value={new Date(bodyInfo.fromDate)}
                                onChange={(e: any) => setBodyInfo((body) => ({ ...body, fromDate: moment(e.value).format("yyyy-MM-DD") }))}
                                selectionMode="single"
                                dateFormat="yy-mm-dd"
                                readOnlyInput
                            />
                            <label htmlFor="fieldValue">From Date</label>
                        </span>
                        </div> 
                    </div>
                    <div className="field mb-0">
                     <div className="custom-float-field mb-0"> 
                        <span className="p-float-label">
                            <Calendar
                                id="single"
                                value={new Date(bodyInfo.toDate)}
                                onChange={(e: any) => setBodyInfo((body) => ({ ...body, toDate: moment(e.value).format("yyyy-MM-DD") }))}
                                selectionMode="single"
                                dateFormat="yy-mm-dd"
                                readOnlyInput
                            />
                            <label htmlFor="fieldValue">To Date</label>
                        </span>
                        </div>
                    </div>

                    <div className="field mb-0">
                        <span className="p-float-label">
                            <Dropdown
                                inputId="actionId"
                                className="w-100 error-tooltip"
                                optionLabel="name"
                                options={Actions} 
                                value={selectedAction} 
                                onChange={(e) => setSelectedAction(e.value)}
                            />
                            <label className="mandatory" htmlFor="dropdown">
                                Action
                            </label>
                        </span>
                    </div>
                    <div className="field mb-0">
                        <span className="p-float-label">
                            <Dropdown
                                inputId="reportGroup"
                                className="w-100 error-tooltip"
                                optionLabel="name" 
                                options={userList}
                                value={selectedUser} 
                                onChange={(e) => setSelectedUser(e.value)}                              
                            />
                            <label className="mandatory" htmlFor="dropdown">
                                Action by
                            </label>
                        </span>
                    </div>

                    <div className="btn-col">
                        <Button className=" btn" onClick={onSearch}>Search</Button>
                        <Button className="pre btn" onClick={onClear}>Clear</Button>
                    </div>

                </div>
                <div className="action-btns">
                    <Button className="button-icon" onClick={onExportAll} tooltip="Export All" tooltipOptions={{ position: "top" }}>
                        <FaDownload className="icon" />
                    </Button>
                </div>
            </div>
            <InformationBox
			  showInfoModal={displayInfoModal}
			  setShowInfoModal={setDisplayInfoModal}
			  message={message}
		  />
            <DataTableComponent
                rows={gridData}
                cols={LOG_COLS}
                paginator
                dataKeyId="copyMoveLogSummaryId"
                title={``}
                showroweditor={false}
                showExpandedRows={true}
                rowExpandedTemplate={rowExpandedTemplate}
                pageDetails={pageDetails}
                pageIndex={pageIndex}
                onPageChanged={onPageChanged}
                onSortData={onSort}
                showCustomSearchCmp
                getSelectedRowData={handleRowSelect}
            />
        </div>
    )
}

export default CopyMoveLogs;
