import React, { useCallback, useEffect, useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { OverlayPanel } from "primereact/overlaypanel";
import { RadioButton } from "primereact/radiobutton";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FilePond, registerPlugin } from "react-filepond";
import { Controller, useForm } from "react-hook-form";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import Table from "components/Table/Table";
import UploadModal from "components/Table/UploadModal/UploadModal";
import { showUI } from 'service/GeneralUtility';
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import PopOver from "components/common/PopOver/PopOver";
import { ATTACHMENT_LIST_COLS } from "./AttachmentListData";
import { baseURL } from "service/HttpService";
import ViewFileModal from "components/common/ViewFileModal/ViewFileModal";
import { deleteAttachment, downloadEquipmentAttachment, editAttachment, getEquipmentAttachments, onViewEquipmentAttachment, setEquipmentAttachmentsList, uploadAttachment } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import AttachmentReport from "./Report/AttachmentReport";
import { onExportReport } from "redux/common/report/customizeReportSlice";
import { getPermittedFiles } from "redux/common/commonSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  uniqueInspectionTaskId: any;
  selectedEquipments: any;
}

export const AttachmentList: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  uniqueInspectionTaskId = "",
  selectedEquipments = [],
}) => {

  type FormData = {
    description: string;
    currentFileName:string;
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm<FormData>({
    defaultValues: {
      description: "",
      currentFileName:""
    },
  });

  const dispatch = useDispatch<any>();
  const downloadRef = React.createRef<OverlayPanel>();
  const equipmentAttachmentsList = useAppSelector((state) => state.equipment.equipmentAttachmentsList);
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
  const permittedFiles = useAppSelector((state) => state.common.permittedFiles);
  registerPlugin(FilePondPluginFileValidateType);
  
  const addForm: any = useRef();
  const editForm: any = useRef();
  const addFileRef: any = useRef();
  const editFileRef: any = useRef();

  const [pageIndex] = useState(0);
  const [currentStart] = useState(1);
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('');
  const [attachmentListdetails] = useState({
    currentLength: 100,
    currentStart: 1,
    totalCount: 500,
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false)
  const [replaceAttachment, setReplaceAttachment] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [enableUpload, setEnableUpload] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [streamData, setStreamData] = useState(null);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [acceptedFileTypes, setAcceptedFileTypes] = useState(["allowed/extension"]);

  useEffect(() => {
    dispatch(getEquipmentAttachments(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, currentStart, sortField, sortOrder));

    return () => {
      dispatch(setEquipmentAttachmentsList([]));
    };
  }, [uniqueInspectionTaskId, selectedEquipments]);

  useEffect(() => {
    setValue('description', selectedRows[0]?.description);  
  }, [showEditModal])

  useEffect(() => {
    if (!showAddModal) {
      reset();
      resetFileUploadForm();
    }
  }, [showAddModal, setShowAddModal]);

  useEffect(() => {
    if (!showEditModal) {
      reset();
      resetFileEditForm();
    }
  }, [showEditModal, setShowEditModal]);

  useEffect(() => {
    if (isDelete === true) {
      setMessage("Are you sure you want to delete the selected Attachment?");
      setDisplayConfirmModal(true);
      setIsDelete(false)
    }
  }, [isDelete])

  useEffect(() => {
    dispatch(getPermittedFiles())
  }, [dispatch])

  useEffect(() => {
    let fileTypes: any = ["allowed/extension"];
    permittedFiles.forEach(item => {
      if (item.extension1 !== null) {
        fileTypes.push(item.extension1)
      }
      if (item.extension2 !== null) {
        fileTypes.push(item.extension2)
      }
    });
    setAcceptedFileTypes(fileTypes);
  }, [permittedFiles]);

  const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1)
      sortDirection = "ASC"
    else
      sortDirection = "DESC"
    setSortField(event.sortField);
    setSortOrder(sortDirection);
    dispatch(getEquipmentAttachments(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, currentStart, event?.sortField, sortDirection))
  }

  const editIconClick = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRows.length === 1) {
      setShowEditModal(true)
    }
    else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
  };

  const deleteIconClick = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRows.length === 1) {
      setIsDelete(true);
    }
    else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
  };

  const onDownloadIconClick = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRows.length === 1) {
      onDownload();
    }
    else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const handleRowSelect = useCallback((data: any) => {
    setSelectedRows([...data]);
  }, []);

  const onError = (errors: any, e: any) => {};

  const addFormSubmitHandler = (data: any) => {
    addForm.current.requestSubmit();
  };

  const onprocessfile = (error: any, file: any) => {
    setFiles([file]);
    if(error) {
      setEnableUpload(true);
    } else {
      setEnableUpload(false);
    }
  };

  const onprocessfiles = () => {
    setEnableUpload(false);
  };

  const beforeAddFile = (file) => {
    let data = addFileRef.current.props.files;
    if (data?.length > 0) {
      data.map((item: any) => {
        if (file?.filename === item.filename && file?.fileSize === item.fileSize) {
          addFileRef.current.removeFiles(item.id)
        }
      })
    }
    return true
  }

  const onWarning = (error: any) => {
    if (error && error.body === 'Max files') {
      setDisplayInfoModal(true);
      setMessage('Maximum of 5 files can only be uploaded at a time.');
    }
  };

  const onRemoveFiles = (error: any, file: any) => {
    if (file?.status === 8 || file?.status === 6) {
      const reaminingFiles = addFileRef.current.getFiles();
      if (reaminingFiles.length) {
        let fileCount: number = 0;
        reaminingFiles.map((item: any) => {
          if (item.status !== 5) {
            fileCount += fileCount + 1;
          }
        });

        if (fileCount > 0) {
          setEnableUpload(true);
        } else {
          setEnableUpload(false);
        }
      } else {
        setEnableUpload(true);
      }
    }
  };

  const onFileError = (error: any, file?: any, status?: any) => {
    if(error) {
      setEnableUpload(true);
    }
  };

  const onProcessFileStart = (file: any) => {
    setEnableUpload(true);
  };

  useEffect(() => {
    if (!files?.length) setEnableUpload(true);
  }, [files]);

  const fileTypeValidation = (source: File, type: string) => new Promise<string>((resolve, reject) => {
    const uploadedFileExtension = source.name.split('.').pop();
    const isAllowed = acceptedFileTypes.find(fileType => fileType.split('.').pop() === uploadedFileExtension) !== undefined;
    if (isAllowed) {
      resolve('allowed/extension');
    } else {
      reject('.' + uploadedFileExtension);
    }
  });

  const resetFileUploadForm = () => {
    addFileRef?.current?.removeFiles();
    reset();
    setFiles([]);
  };

  const resetFileEditForm = () => {
    editFileRef?.current?.removeFiles();
    setFiles([]);
  };

  const onAddFormSubmit = (data: any) => {
    if(files.length){
      let tempFileIds: string = "";
      files.map((item: any) => {
        let response = JSON.parse(JSON.parse(JSON.stringify(item.serverId)));
        if (response?.status === "done") {
          tempFileIds += response.data?.uniqueDirectoryId[0] + ","
        }
      })
      tempFileIds = tempFileIds.slice(0, -1);
      let body = {
        description: data.description,
        TempStorageDirectory: tempFileIds
      };
      setEnableUpload(true);
      dispatch(uploadAttachment(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, body, setMessage, setDisplayInfoModal, resetFileUploadForm, setEnableUpload));
    } else {
      setDisplayInfoModal(true);
      setMessage('Select a File to upload');
    }
  };

  const editFormSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const onEditFormSubmit = (data: any) => {
    if(!replaceAttachment){
      let body = {
        description: data.description,
      };
      setEnableUpload(true);
      dispatch(editAttachment(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, selectedRows[0]?.attachmentId, body, setMessage, setDisplayInfoModal, resetFileEditForm, setSelectedRows, setEnableUpload));
    }else{
      if (files.length && files[0]?.serverId) {
        let response = JSON.parse(JSON.parse(JSON.stringify(files[0]?.serverId)));
        if (response?.status === "done") {
          let body = {
            description: data.description,
            TempStorageDirectory: response.data?.uniqueDirectoryId[0],
            isNewFileRequired: true,
            oldFileName: selectedRows[0]?.fileName,
            newFileName: files?.filename
          };
          setEnableUpload(true);
          dispatch(editAttachment(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, selectedRows[0]?.attachmentId, body, setMessage, setDisplayInfoModal, resetFileEditForm, setSelectedRows, setEnableUpload));
        }
      } else {
        setDisplayInfoModal(true);
        setMessage('Select a File to upload');
      }
    }
  };

  const onDeleteConfirm=()=>{
    dispatch(deleteAttachment(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, selectedRows, setMessage, setDisplayInfoModal, setDisplayConfirmModal, setSelectedRows))
  }

  const onDownload=()=>{
    dispatch(downloadEquipmentAttachment(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, selectedRows[0]?.attachmentId, selectedRows[0]?.fileName, setMessage, setDisplayInfoModal,))
  }

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

  const getSelectedAttachmentIds = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.attachmentId);
    });
    return params;
  };

  const onExportAll = () => {
    const reportParameters = {
      exportParameters: {
        uniqueInspctionTaskId: uniqueInspectionTaskId,
        equipmentId: selectedEquipments[0]?.EquipmentId,
        sortColumn: sortField,
        sortOrder: sortOrder,
        selectedIds: ""
      },
      exportType: "Equipment Attachments Report"
    };
    dispatch(onExportReport(reportParameters, "Equipment Attachments"));
  };

  const onExportHighlightedClick = () => {
    const reportParameters = {
      exportParameters: {
        uniqueInspctionTaskId: uniqueInspectionTaskId,
        equipmentId: selectedEquipments[0]?.EquipmentId,
        sortColumn: sortField,
        sortOrder: sortOrder,
        selectedIds: getSelectedAttachmentIds()
      },
      exportType: "Equipment Attachments Report"
    };
    dispatch(onExportReport(reportParameters, "Equipment Attachments"));
  };

  const openViewModal = () => {
    if (selectedRows.length < 1) {
      setMessage("Select an Attachment");
      setDisplayInfoModal(true);
    }
    else if (selectedRows.length === 1) {
      dispatch(onViewEquipmentAttachment(uniqueInspectionTaskId, selectedEquipments[0]?.EquipmentId, selectedRows[0]?.attachmentId, selectedRows[0]?.fileName, setMessage, setDisplayInfoModal, setStreamData, setShowViewModal))
    }
    else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    }
  }

  return (
    <>
      <Dialog
        header="Failed Equipment Images"
        visible={showModal}
        style={{ width: "50vw" }}
        onHide={() => setShowModal(false)}
        className="display-settings personal selectsite"
      >
        <div className="header d-flex align-items-center">
          <span className="title">Address:</span>
          <span className="value">{selectedEquipments[0]?.Address}</span>
        </div>
        <Table
          rows={equipmentAttachmentsList}
          cols={ATTACHMENT_LIST_COLS.LIST_COLS}
          dataKeyId="attachmentId"
          title={``}
          pageIndex={pageIndex}
          pageDetails={attachmentListdetails}
          showDownloadIcon={true}
          showSettingIcon={false}
          onAddClick={() => setShowAddModal(true)}
          showHamIcon={false}
          onEditClick={editIconClick}
          onDeleteClick={deleteIconClick}
          //showAddIcon={showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_ATTACHMENT_MANAGE")}     --- Tracker 652
          //showEditIcon={showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_ATTACHMENT_MANAGE")}    --- Tracker 652
          showAddIcon={false}
          showEditIcon={false}
          showDeleteIcon={showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_ATTACHMENT_MANAGE")}
          searchboxfield={false}
          showroweditor={false}
          onSortData={onSort}
          getSelectedRowData={handleRowSelect}
          onDownloadClick={(e) => downloadRef.current?.toggle(e)}
          showViewIcon = {true}
          showDislaySettingsIcon = {true}
          onViewClick = {openViewModal}
          defaultSelectedRows = {equipmentAttachmentsList.length > 0 ? [equipmentAttachmentsList[0]] : []}
        />
        {showAddModal &&
          <UploadModal
            header="Upload Attachment"
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            OnSaveClick={addFormSubmitHandler}
            isAdd={false}
            style={{ width: "35vw", maxHeight: "95%" }}
            buttonDisable={enableUpload}
          >
            <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <div className="flex align-items-center flex-column upload-file-section">
                    <div className="upload-detail" style={{ width: "100%", height: "50%" }}>
                      <FilePond
                        ref={addFileRef}
                        allowMultiple={true}
                        maxFiles={5}
                        files={files}
                        onupdatefiles={setFiles}
                        server={`${baseURL}api/v1/common/files/upload`}
                        onprocessfiles={onprocessfiles}
                        beforeAddFile={beforeAddFile}
                        onwarning={onWarning}
                        onremovefile={onRemoveFiles}
                        onerror={onFileError}
                        onprocessfilestart={onProcessFileStart}
                        name="files"
                        credits={false}
                        labelIdle='Click to choose a file or drag and drop a file'
                        labelFileLoading=""
                        labelTapToUndo=""
                        labelFileProcessing=""
                        labelFileProcessingComplete=""
                        labelFileProcessingAborted=""
                        labelFileProcessingError=""
                        labelButtonAbortItemProcessing=""
                        labelButtonUndoItemProcessing=""
                        labelButtonRetryItemProcessing=""
                        labelButtonProcessItem=""
                        labelTapToCancel=""
                        labelFileRemoveError=""
                        labelTapToRetry=""
                        allowFileTypeValidation={true}
                        acceptedFileTypes={acceptedFileTypes}
                        fileValidateTypeDetectType={fileTypeValidation}
                        fileValidateTypeLabelExpectedTypes={'You do not have privilege to add attachment of this File Type'}
                      />
                    </div>
                  </div>
                </span>
              </div>

              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Controller name="description" control={control} rules={{
                    maxLength: 4000
                  }} render={({ field, fieldState }) => (
                    <InputTextarea maxLength={4000} id="Textarea" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                  )} />
                  <label htmlFor="inputtext">Description</label>
                  {getFormErrorMessage('description')}
                  <span className='textarea-txt-count'>Max 4000 Chars</span>
                </span>
              </div>
            </form>
          </UploadModal>
        }
        {showEditModal &&
          <UploadModal
            header="Edit Attachment"
            showModal={showEditModal}
            setShowModal={setShowEditModal}
            OnSaveClick={editFormSubmitHandler}
            isAdd={false}
            style={{ width: "35vw", maxHeight: "95%" }}
            buttonDisable={replaceAttachment ? enableUpload : false}
            buttonCondition={replaceAttachment}
          >
            <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
              <div className="field col-12 md:col-4 mb-0 pb-0">
                <label htmlFor="radiobutton">
                  Do you want to replace the current Attachment ?</label>
                <div className='radio-section d-flex align-items-center pt-2'>
                  <div className="field-radiobutton">
                    <RadioButton disabled={false} inputId="replaceYes" name="replace" value={true} onChange={(e) => setReplaceAttachment(e.value)} checked={replaceAttachment} />
                    <label htmlFor="replace">Yes</label>
                  </div>
                  <div className="field-radiobutton pl-2">
                    <RadioButton disabled={false}
                      inputId="replaceNo"
                      name="replace"
                      value={false}
                      onChange={(e) => {
                        setReplaceAttachment(e.value);
                        setFiles([]);
                      }}
                      checked={!replaceAttachment} />
                    <label htmlFor="replace">No</label>
                  </div>
                </div>
              </div>
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label text-container">
                  <label htmlFor="inputtext">Current File Name</label>
                  <span className="text-value">
                    <b>{selectedRows[0]?.fileName}</b>
                  </span>
                </span>
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <div className="flex align-items-center flex-column upload-file-section">
                    <div className="upload-detail" style={{ width: "100%", height: "50%" }}>
                      {replaceAttachment ? <FilePond
                        ref={editFileRef}
                        allowMultiple={false}
                        maxFiles={1}
                        server={`${baseURL}api/v1/common/files/upload`}
                        onprocessfile={onprocessfile}
                        onupdatefiles={setFiles}
                        onerror={onFileError}
                        onprocessfilestart={onProcessFileStart}
                        name="files"
                        credits={false}
                        labelIdle='Click to choose a file or drag and drop a file'
                        labelFileLoading=""
                        labelTapToUndo=""
                        labelFileProcessing=""
                        labelFileProcessingComplete=""
                        labelFileProcessingAborted=""
                        labelFileProcessingError=""
                        labelButtonAbortItemProcessing=""
                        labelButtonUndoItemProcessing=""
                        labelButtonRetryItemProcessing=""
                        labelButtonProcessItem=""
                        labelTapToCancel=""
                        labelFileRemoveError=""
                        labelTapToRetry=""
                        allowFileTypeValidation={true}
                        acceptedFileTypes={acceptedFileTypes}
                        fileValidateTypeDetectType={fileTypeValidation}
                        fileValidateTypeLabelExpectedTypes={'You do not have privilege to add attachment of this File Type'}
                      /> : null}
                    </div>
                  </div>
                </span>
              </div>

              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Controller name="description" control={control} rules={{
                    maxLength: 4000
                  }} render={({ field, fieldState }) => (
                    <InputTextarea id="Textarea" maxLength={4000} className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                  )} />
                  <label htmlFor="inputtext">Description</label>
                  {getFormErrorMessage('description')}
                  <span className='textarea-txt-count'>Max 4000 Chars</span>
                </span>
              </div>
            </form>
          </UploadModal>
        }
        <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
        />
        <ConfirmationBox
          showConfirmModal={displayConfirmModal}
          setShowConfirmModal={setDisplayConfirmModal}
          confirmAction={() =>{onDeleteConfirm()}}
          message={message}
        />
        <PopOver ref={downloadRef}>
          <ul>
            <li onClick={onReportAllClick}>Report All</li>
            <li onClick={onReportHighlightedClick}>Report Highlighted</li>
            <li onClick={onExportAll}>Export All</li>
					  <li onClick={onExportHighlightedClick}>Export Highlighted</li>
            <li onClick={onDownloadIconClick}>Download</li>
          </ul>
        </PopOver>
      </Dialog>
      {showReportModal&&
        <AttachmentReport
          showModal={showReportModal}
          setShowModal={setShowReportModal}
          reportFlag={reportFlag}
          uniqueInspctionTaskId={uniqueInspectionTaskId}
          equipmentId={selectedEquipments[0]?.EquipmentId}
          selectedRows={selectedRows}
          sortByColumns={ATTACHMENT_LIST_COLS.LIST_COLS} />
      }
      {showViewModal&&streamData&&
        <ViewFileModal showModal={showViewModal} setStreamData={setStreamData} setShowModal={setShowViewModal} streamData={streamData} fileName={selectedRows[0]?.fileName}/>
      }
    </>
  );
};

export default AttachmentList;
