import React, { useState, useRef, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { FilePond } from 'react-filepond';
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { baseURL } from "service/HttpService";
import { getDrawingDetails } from "redux/pages/AsBuilts/AsBuiltsSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  selectedBuilding: any;
  onReplaceDrawing: (data: any) => void;
}

type FormData = {
  building: string;
  floor: string;
  drawingCategory: string;
  drawingType: string;
  description: string;
  revisionNo: string;
  filename: string;
};

export const ReplaceASbuilts: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedRows = [],
  selectedBuilding = [],
  onReplaceDrawing = () => { },
}) => {




  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      building: "",
      floor: "",
      drawingCategory: "",
      drawingType: "",
      description: "",
      revisionNo: "",
      filename: "",
    },
  });

  const [drawingInfo, setDrawingInfo] = useState<any>("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileSize, seFileSize] = useState("");

  const drawingDetails = useAppSelector((state) => state.asbuilts.drawingDetails);
  const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);

  const replaceForm: any = useRef();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    let body = {
      revisionNo: selectedRows[0]?.revisions
  };
    dispatch(getDrawingDetails(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRows[0]?.drawingId, body))
  }, [selectedRows])

  useEffect(() => {
    if (drawingDetails) {
      setValue("description", drawingDetails.description)
    }
  }, [drawingDetails])


  const onRemoveFile = () => {
    setDrawingInfo("");
  }

  const beforeAddFile = (item: any) => {
    if (item.fileExtension === "dwg" || item.fileExtension === "DWG"|| item.fileExtension === "pdf"|| item.fileExtension === "PDF") {
      setFileName(item.filename);
      seFileSize(item.fileSize);
      return true;
    }
    else {
      setMessage("Select either a DWG File or PDF File.")
      setDisplayInfoModal(true);
      return false;
    }
  }

  const formSubmitHandler = (data: any) => {
    replaceForm.current.requestSubmit();
  };

  const onReviseFormSubmit = (data: any) => {
    if (drawingInfo === "") {
      setMessage("Select either a DWG File or PDF File.")
      setDisplayInfoModal(true);
    } else {
      let payload = {
        floorId: drawingDetails?.floorId,
        description: data.description.trim(),
        revisionNo: drawingDetails?.revisionNo,
        DWGFileName: fileName,
        DWGFileSize: fileSize,
        TempStorageDirectory: drawingInfo
      }
      onReplaceDrawing && onReplaceDrawing(payload);
    }
  };


  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Upload" className="update btn" autoFocus onClick={formSubmitHandler} />
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <Dialog
        header="Replace Drawing"
        visible={showModal}
        style={{ width: "40vw", height: "85vh" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onReviseFormSubmit, onError)}
          ref={replaceForm}
        >

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">Building</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">
                  <Controller
                    name="building"
                    control={control}
                    render={() => (
                      <span className='text-value ml-0'>{drawingDetails?.buildingName}</span>
                    )} />
                </span>
              </div>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">Floor</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">
                  <Controller
                    name="floor"
                    control={control}
                    render={() => (
                      <span className='text-value ml-0'>{drawingDetails?.floorName}</span>
                    )} />
                </span>
              </div>
            </div>
          </div>




          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">Drawing Category</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">
                  <Controller
                    name="drawingCategory"
                    control={control}
                    render={() => (
                      <span className='text-value ml-0'>{drawingDetails?.categoryName}</span>
                    )} />
                </span>
              </div>
            </div>
          </div>



          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">Drawing Type</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">
                  <Controller
                    name="drawingType"
                    control={control}
                    render={() => (
                      <span className='text-value ml-0'>{drawingDetails?.typeName}</span>
                    )} />
                </span>
              </div>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">Revision No</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">

                  <Controller
                    name="revisionNo"
                    control={control}
                    render={() => <span className="text-value ml-0">{drawingDetails?.revisionNo}</span>}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">File Name</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label text-container">

                  <Controller
                    name="filename"
                    control={control}
                    render={() => <span className="text-value ml-0">{drawingDetails?.dwgFileName}</span>}
                  />
                </span>
              </div>
            </div>
          </div>


          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label mandatory">Description</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label">
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: 'Description is required.',
                      validate: (value) => {
                        return value.trim().length <= 0
                          ? "Description is required." : true
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id="Textarea"
                        className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                        maxLength={4000}
                        {...field}
                      />
                    )}
                  />
                  <label className='mandatory' htmlFor="inputtext">Description</label>
                  {getFormErrorMessage("description")}
                </span>
              </div>
            </div>
          </div>


          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
              <div className="left-sider-label d-flex align-items-center">
                <div className="p-float-label">
                  <p className="label">File</p>
                </div>
              </div>
              <div className="right-side-field">
                <FilePond
                  allowMultiple={false}
                  maxFiles={1}
                  allowDrop
                  server={{
                    process: {
                      url: `${baseURL}api/v1/common/files/upload`,
                      method: 'POST',
                      withCredentials: false,
                      onload: (response) => {
                        const res = JSON.parse(response);
                        if (res.status === "done") {
                          setDrawingInfo(res.data.uniqueDirectoryId[0]);
                        }
                        return response.key
                      },
                      onerror: (response) => response.data,
                      ondata: (formData) => formData
                    },
                  }}
                  name="files"
                  credits={false}
                  labelIdle="Click to choose a file or drag and drop a file"
                  labelFileLoading=""
                  labelTapToUndo=""
                  labelFileProcessing=""
                  labelFileProcessingComplete=""
                  labelFileProcessingAborted=""
                  labelFileProcessingError=""
                  labelButtonAbortItemProcessing=""
                  labelButtonUndoItemProcessing=""
                  labelButtonRetryItemProcessing=""
                  labelButtonProcessItem=""
                  labelTapToCancel=""
                  labelFileRemoveError=""
                  labelTapToRetry=""
                  allowFileTypeValidation={true}
                  onremovefile={onRemoveFile}
                  //acceptedFileTypes={[".dwg", "application/octet-stream"]}
                  beforeAddFile={beforeAddFile}
                />
              </div>
            </div>
          </div>
          <InformationBox
            showInfoModal={displayInfoModal}
            setShowInfoModal={setDisplayInfoModal}
            message={message}
          />

        </form>



      </Dialog>
    </>
  );
};

export default ReplaceASbuilts;
