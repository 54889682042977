import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
//icons
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { IoPlayForwardSharp } from "react-icons/io5";
//components
import FormElement from "../Utility/FormElement";
import { ScrollPanel } from "primereact/scrollpanel";
//redux
import { getAdditionalDatafieldSets, getDatafieldValues, setAdditionalDatafieldValues } from "redux/common/commonSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import DatafieldOrder from "components/common/DatafieldOrder/DatafieldOrder";
import { onGetInspectionDatafieldOrderList, onInspectionDatafieldOrderUpdated,onGetInspectionquestionfieldOrderList, onInspectionQuestionDatafieldOrderUpdated, setUpdatedQuestionInspectionDatafieldOrderList} from 'redux/pages/Manage/InspectionSettings/InspectionFields/InspectionFieldSlice';
import { MdTableRows } from "react-icons/md";

interface InspectionFormsPropType {
    showAction?: boolean;
    isExpanded?: boolean;
    isAddAction?: boolean;
    isInspectionField?: boolean;
    isCheckedAction?: boolean;
    formElements?: any;
    inspectionFieldValues?: any;
    monitoringValues?: any;
    body?: any;
    selectedItem?: any;
    fieldFocus?: boolean;
    onAddClicked?: (data) => void;
    onEditClicked?: (data) => void;
    onDeleteClicked?: (data) => void;
    onInspectionSubmit?: (data) => void;
    onInspectionFieldError?: (data) => void;
    checkIsValueChanged?: (e) => void;
    onSelectionChanged?: (data, id) => void;
    type?: 'CONTRACT' | 'SYSTEM' | 'QUESTION';
    setFieldFocus?: any;
}
const InspectionForms = forwardRef<any, InspectionFormsPropType>((props, ref) => {

    const { isAddAction,
        isExpanded,
        isInspectionField = false,
        isCheckedAction,
        formElements,
        showAction = true,
        inspectionFieldValues,
        monitoringValues = [],
        body,
        type,
        selectedItem,
        fieldFocus = false,
        onAddClicked = () => { },
        onEditClicked = () => { },
        onDeleteClicked = () => { },
        checkIsValueChanged = () => { },
        onInspectionSubmit = () => { },
        onInspectionFieldError = () => { },
        onSelectionChanged = () => { },
        setFieldFocus } = props;

    const inspectionForm = useRef<any>(null);
    const { control, formState: { errors }, handleSubmit, reset, unregister, watch, setValue } = useForm({ mode: 'all' });

    const dispatch = useDispatch<any>();
    const additionalFieldValues = useAppSelector((state) => state.common.additionalDatafieldValues);
    const [showFieldOrder, setShowFieldOrder] = useState(false);
    const [fieldOrder, setFieldOrder] = useState<any>([]);
    const inspectiondatafieldOrderList = useAppSelector((state) => state.InspectionField.inspectiondatafieldOrderList);  
    const inspectionquestionfieldOrderLists = useAppSelector((state) => state.InspectionField.inspectionquestionfieldOrderList);
    const [tooltipLimit, setTooltipLimit] = useState(70);

    useImperativeHandle(ref, () => ({
        requestSubmit: () => {
            inspectionForm?.current?.requestSubmit();
        },
        onBranchOffieSelectionInDynamically: (uniqueId, id) => {
            let service = `v1/inspection/inspectiontask/systems/contractfields/branchoffices/${id}`
            dispatch(getDatafieldValues(service, null, body));
            setValue(uniqueId, id);
        },
    }));

    useEffect(() => {
        if (formElements) {
            reset();
            unregister();
            convertCheckBoxItem(formElements?.inspectionGroupFields)
        }
    }, [formElements]);

    useEffect(() => {
        if (inspectionFieldValues?.length)
            onSetDefaultValues(formElements?.inspectionGroupFields, inspectionFieldValues);
    }, [inspectionFieldValues])

    useEffect(() => {
        if (monitoringValues?.length) {
            onSetDefaultValues(formElements?.inspectionGroupFields, monitoringValues);
        }
    }, [monitoringValues])

    useEffect(() => {
        if (additionalFieldValues?.length) {
            if (additionalFieldValues[1]) {

            } else {
                onSetDefaultValues(formElements?.inspectionGroupFields, additionalFieldValues[0]);
            }

            dispatch(setAdditionalDatafieldValues(""));
        }
    }, [additionalFieldValues])

    useEffect(() => {
        if (inspectiondatafieldOrderList) {
            let value: any = JSON.stringify(inspectiondatafieldOrderList);
            value = value?.replace(/\"dataFieldId\":/g, '"id":');
            value = value?.replace(/\"dataFieldName\":/g, '"name":');
            value = JSON.parse(value);
            setFieldOrder(value);
        }
    }, [inspectiondatafieldOrderList])

    const onOrderUpdated = useCallback((body) => {
         switch (type) {
            case 'SYSTEM':{
                let value: any = JSON.stringify(body);
                value = value?.replace(/\"id\":/g, '"DataFieldId":');
                value = JSON.parse(value);
                dispatch(onInspectionDatafieldOrderUpdated(value));
                setShowFieldOrder(false);
                break;
            }
            case 'QUESTION':{             
                    let value: any = JSON.stringify(body);             
                    value = value?.replace(/\"id\":/g, '"DataFieldId":');
                    value = JSON.parse(value);
                    dispatch(setUpdatedQuestionInspectionDatafieldOrderList(value));
                    dispatch(onInspectionQuestionDatafieldOrderUpdated(value));
                    setShowFieldOrder(false);
                    break;
            }
        }

        
    }, []);

    useEffect(() => {
        if (inspectionquestionfieldOrderLists) {
            let value: any = JSON.stringify(inspectionquestionfieldOrderLists);
            value = value?.replace(/\"dataFieldId\":/g, '"id":');
            value = value?.replace(/\"dataFieldName\":/g, '"name":');
            value = JSON.parse(value);
            setFieldOrder(value);
        }
    }, [inspectionquestionfieldOrderLists])

    useEffect(() => {
        const handleResize = () => {
          const screenWidth = window.innerWidth;
          const quarterscreenwidth=(screenWidth/4);
          if (screenWidth < 600) {
            setTooltipLimit(70); 
          } else {
            setTooltipLimit(quarterscreenwidth);
          }
        };  
        window.addEventListener('resize', handleResize);
        handleResize(); 
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      

    const convertCheckBoxItem = (elements) => {
        if (elements?.length) {
            for (let i = 0; i < elements?.length; i++) {
                if (elements[i]?.inspectionGroupFields?.length) {
                    if (elements[i]?.dataEntryControl?.dataEntryControlName === "Check Box(s)" && elements[i]?.validatedValues?.length) {
                        checkedItems(elements[i]);
                    } else if (elements[i]?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                        dropDownSelection(elements[i])
                    }
                    convertCheckBoxItem(elements[i]?.inspectionGroupFields)
                } else {
                    if (elements[i]?.dataEntryControl?.dataEntryControlName === "Check Box(s)" && elements[i]?.validatedValues?.length) {
                        checkedItems(elements[i]);
                    } else if (elements[i]?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                        dropDownSelection(elements[i])
                    }
                }
            }
        }
    }

    const checkedItems = (elements) => {
        let list: any = [];
        for (let j = 0; j < elements?.validatedValues?.length; j++) {
            let sm = elements.validatedValues[j].dataFieldLookupId;
            let item = {
                [sm]: false,
            };
            list.push(item);
        }
        setValue(elements?.uniqueDataFieldId, list);
    }

    const dropDownSelection = (elements) => {
        if (elements?.defaultSelectNeeded)
            elements.validatedValues.unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
        const slectedItem = elements.validatedValues?.filter((item) => item?.isSelected);
        if (slectedItem?.length) {
            setValue(elements.uniqueDataFieldId, slectedItem[0].dataFieldLookupId);
        } else {
            setValue(elements.uniqueDataFieldId, -1)
        }
    }

    const onSetDefaultValues = (elements, filedValues) => {
        if (elements?.length) {
            for (let i = 0; i < elements?.length; i++) {
                if (elements[i]?.inspectionGroupFields?.length) {
                    const filterData = filedValues?.filter((item) => item?.uniqueDataFieldId === elements[i]?.uniqueDataFieldId);
                    if (filterData?.length) {
                        if (elements[i]?.dataEntryControl?.dataEntryControlName === "Check Box(s)") {
                            if (elements[i]?.isValidated)
                                setCheckBoxIsTrue(filterData[0]?.multipleFieldValue, elements[i]?.validatedValues, elements[i]?.uniqueDataFieldId);
                            else {
                                let fieldValue = false;
                                if (filterData[0]?.fieldValue === "true")
                                    fieldValue = true;
                                setValue(elements[i]?.uniqueDataFieldId, fieldValue);
                            }
                        } else if (elements[i]?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                            let validatedValues = elements[i]?.validatedValues?.filter((item) => item?.dataFieldLookupId === filterData[0]?.fieldValue);
                            if (validatedValues?.length)
                                setValue(elements[i]?.uniqueDataFieldId, filterData[0]?.fieldValue);
                            else
                                setValue(elements[i]?.uniqueDataFieldId, -1)
                        } else {
                            setValue(elements[i]?.uniqueDataFieldId, filterData[0]?.fieldValue);
                        }
                        if (elements[i]?.isValidated) {
                            setChildDatafield(elements[i], filterData[0])
                        }
                    }
                    onSetDefaultValues(elements[i]?.inspectionGroupFields, filedValues)
                } else {
                    const filterData = filedValues?.filter((item) => item?.uniqueDataFieldId === elements[i]?.uniqueDataFieldId);
                    if (filterData?.length) {
                        if (elements[i]?.dataEntryControl?.dataEntryControlName === "Check Box(s)") {
                            if (elements[i]?.isValidated)
                                setCheckBoxIsTrue(filterData[0]?.multipleFieldValue, elements[i]?.validatedValues, elements[i]?.uniqueDataFieldId);
                            else {
                                let fieldValue = false;
                                if (filterData[0]?.fieldValue === "true")
                                    fieldValue = true;
                                setValue(elements[i]?.uniqueDataFieldId, fieldValue);
                            }
                        } else if (elements[i]?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                            let validatedValues = elements[i]?.validatedValues?.filter((item) => item?.dataFieldLookupId === filterData[0]?.fieldValue);
                            if (validatedValues?.length)
                                setValue(elements[i]?.uniqueDataFieldId, filterData[0]?.fieldValue);
                            else
                                setValue(elements[i]?.uniqueDataFieldId, -1)
                        } else {
                            setValue(elements[i]?.uniqueDataFieldId, filterData[0]?.fieldValue);
                        }
                        if (elements[i]?.isValidated) {
                            setChildDatafield(elements[i], filedValues)
                        }
                    }
                }
            }
        }
    }

    const setCheckBoxIsTrue = (elements, validatedValues, uniqueDataFieldId) => {
        let list: any = [];
        for (let j = 0; j < validatedValues?.length; j++) {
            let checked = false;
            let filterItem = elements?.filter((item) => item === validatedValues[j].dataFieldLookupId);
            if (filterItem?.length)
                checked = true
            let sm = validatedValues[j].dataFieldLookupId;
            let item = {
                [sm]: checked,
            };
            list.push(item);
        }
        setValue(uniqueDataFieldId, list);

    }

    const setChildDatafield = (elements, filedValues) => {
        const filtervalues = elements.validatedValues?.filter((item) => item?.childDataField?.length);
        if (filtervalues?.length) {
            filtervalues.forEach(val => {
                val?.childDataField?.forEach(subVal => {
                    const values = filedValues?.filter((item) => item?.uniqueDataFieldId === subVal?.uniqueDataFieldId);
                    if (values?.length) {
                        setValue(subVal?.uniqueDataFieldId, values[0]?.fieldValue);
                    }
                });

            });
        }
    }

    const checkChildFields = (data: any, fields: any) => {
        let childArray: any = [];
        props?.formElements?.inspectionGroupFields && props?.formElements?.inspectionGroupFields?.map((inspectionGroupFieldItem, key) => (
            inspectionGroupFieldItem?.validatedValues && inspectionGroupFieldItem?.validatedValues?.map((validatedValueItem, index) => (
                validatedValueItem?.childDataField && validatedValueItem?.childDataField?.map((childItem, num) => (
                    childArray.push({
                        "parentUniqueDataFieldId": inspectionGroupFieldItem?.uniqueDataFieldId,
                        "parentFieldName": inspectionGroupFieldItem?.dataFieldName,
                        "datafieldLookupId": validatedValueItem?.dataFieldLookupId,
                        "dataFieldLookupValue": validatedValueItem?.dataFieldLookupValue,
                        "childUniqueDataFieldId": childItem?.uniqueDataFieldId,
                        "childFieldName": childItem?.dataFieldName
                    })
                ))
            ))
        ))

        let dataArray: any = [];
        for (const k in data) {
            let childValues: any = [];
            if (typeof data[k] === 'object') {
                for (const j in data[k]) {
                    for (const l in data[k][j]) {
                        childValues.push({ "datafieldLookupId": l, "value": data[k][j][l] })
                    }
                }
                dataArray.push({ "uniqueDatafieldId": k, "value": childValues })
            } else {
                dataArray.push({ "uniqueDatafieldId": k, "value": data[k] })
            }
        }

        if (childArray.length > 0) {
            for (const j in childArray) {
                for (const k in dataArray) {
                    if (typeof dataArray[k] === 'object') {
                        if (dataArray[k].uniqueDatafieldId === childArray[j].parentUniqueDataFieldId) {
                            for (const l in dataArray[k].value) {
                                for (const m in fields) {
                                    if (fields[m].dataFieldId === childArray[j].childUniqueDataFieldId) {
                                        if (dataArray[k].value[l]?.value === false && childArray[j]?.datafieldLookupId === dataArray[k].value[l]?.datafieldLookupId) {
                                            fields.splice(m, 1)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return fields;
    }

    const onSubmit = (data) => {
        let fields: any = [];
        for (const k in data) {
            if (data[k] != undefined) {
                let multipleValue: any = [];
                let item: any = {
                    dataFieldId: k,
                    value: null,
                    multipleValue: null,
                }
                if (Array.isArray(data[k])) {
                    for (let i = 0; i < data[k]?.length; i++) {
                        for (const obj in data[k][i]) {
                            if (data[k][i][obj]) {
                                multipleValue.push(obj);
                            }
                        }
                    }
                    item.multipleValue = multipleValue;
                } else {
                    item.value = data[k]?.toString().trim();
                }
                fields.push(item);
            }
        }
        if (type === "SYSTEM") {
            let systemFields = checkChildFields(data, fields)
            onInspectionSubmit(systemFields);

        } else {
            onInspectionSubmit(fields);
        }
    }

    const onSelectionChange = useCallback((data, id) => {
        if (data?.dataFieldRelation !== undefined) {
            onFieldRelationChange(data, id);
        }
        if (data?.dataFieldSet !== undefined) {
            onDataFieldSetChange(data, id);
        }
        onSelectionChanged(data, id);
    }, []);

    const onFieldRelationChange = (data, id) => {
        let service: any = data?.dataFieldRelation?.subSetValueService;
        if (service !== undefined) {
            service = service.replace("{dataFieldLookupId}", id);
            dispatch(getDatafieldValues(service, data?.dataFieldRelation?.childDataFieldId, body))
        }
    }

    const onDataFieldSetChange = (data, id) => {
        let service: any = data?.dataFieldSet?.subSetValueService;
        if (service !== undefined) {
            service = service.replace("{dataFieldLookupId}", id);
            dispatch(getAdditionalDatafieldSets(service, body))
        }
    }


    const onHideToolTip = (e) => {
        e.currentTarget.title = "";
    };

    const checkFieldExists = (values, key, elements) => {
        values?.forEach(element => {
            if (element?.uniqueDataFieldId === key) {
                elements.push(element);
            } else {
                checkFieldExists(element?.inspectionGroupFields, key, elements);
            }
        });
        return elements[elements?.length - 1];
    };

    const onError = (error) => {
        if (error) {
            let inspectionFieldElements = [];
            const errorKey = Object.keys(error);
            const element = checkFieldExists(formElements?.inspectionGroupFields, errorKey[errorKey?.length - 1], inspectionFieldElements);

            const message = error[element?.uniqueDataFieldId]?.type === "maxLength" ? `${element?.dataFieldName} cannot exceed ${element?.maxLength}.`
                : error[element?.uniqueDataFieldId]?.type === "required" ? <>{element?.dataFieldName} <span style={{color:'red'}}>field is required.</span></>
                    : errors[element?.uniqueDataFieldId]?.type === "validate" ? errors[element?.uniqueDataFieldId]?.message + '.'
                        : null;

            onInspectionFieldError(message);
        }
    };

    const onGetInspectionfieldOrderList = () => {
        setShowFieldOrder(true);
        switch (type) {
            case 'SYSTEM': {
                dispatch(onGetInspectionDatafieldOrderList());
                break;
            }       
            
        }
    };
    const onGetQuestionInspectionfieldOrderList = (dataItem) => {
        setShowFieldOrder(true);
        let dataFieldId
        dataItem ? dataFieldId = dataItem : dataFieldId = selectedItem
        switch (type) {
            case 'QUESTION':{
                dispatch(onGetInspectionquestionfieldOrderList(dataFieldId));
                break;
            }
            
        }
    };

    const onGetSlicedName=(data:any) =>{
         if(( data === null) || (data === undefined) || (data === "undefined"))
         {
             return data
         }
         else
         {
             if(data?.length > tooltipLimit)
             data =  data.slice(0, tooltipLimit)+"...";
             return data
         }
     }

    const onUpdateToolTipData = (data,e: any) => {
        if (data?.length > tooltipLimit) {
          e.currentTarget.title = data;
        }
      };

    const ActionTemplate = (props) => {
        return (
            <>
                {showAction &&
                    <div className="action-btns">
                        {((props?.data?.isSection && props?.data?.isStatic !="1" )  || (props?.isAddAction && props?.data?.isStatic !="1") || (props?.data?.isGroupedControl && props?.data?.isStatic !="1")) &&                          
                         <Button
                                className="button-icon"
                                tooltip="Add"
                                tooltipOptions={{ position: "top" }}
                                onClick={() => onAddClicked(props.data)}
                            >
                                <BsPlusLg className="icon" />
                            </Button>
                        }
                        {(props?.data?.isStatic != "1") &&
                            <Button
                                className="button-icon"
                                tooltip="Edit"
                                tooltipOptions={{ position: "top" }}
                                onClick={() => onEditClicked(props.data)}
                            >
                                <BsFillPencilFill className="icon" />
                            </Button>}
                        {(props?.data?.isStatic != "1") &&
                              <Button
                              className="button-icon"
                              tooltip="Delete"
                              tooltipOptions={{ position: "top" }}
                              onClick={() => onDeleteClicked(props.data)}
                          >
                              <BsTrashFill className="icon" />
                          </Button>
                        }
                        {type === "SYSTEM" && !props?.data?.isSection && !props?.data?.isGroupedControl
                            && !props?.data?.isVerticalList &&
                            <Button
                                className="button-icon"
                                tooltip="Field Order"
                                tooltipOptions={{ position: "top" }}
                                onClick={() => {
                                    setShowFieldOrder(true);
                                    onGetInspectionfieldOrderList()
                                }}>
                                <MdTableRows className="icon" />
                            </Button>
                        }
                            {(type === "SYSTEM" && props?.data?.isStatic==1) &&
                             <Button
                             className="button-icon"
                             tooltip="Edit"
                             disabled
                             tooltipOptions={{ position: "top" }}
                         >
                             <BsFillPencilFill className="icon" />
                         </Button>
                        }
                        {(type === "SYSTEM" && props?.data?.isStatic==1) &&
                            <Button
                            className="button-icon"
                            tooltip="Delete"
                            disabled
                            tooltipOptions={{ position: "top" }}
                        >
                            <BsTrashFill className="icon" />
                        </Button>
                        }
                        {type === "QUESTION"  && (props?.data?.inspectionGroupFields !== null) &&
                            <Button
                                className="button-icon"
                                tooltip="Field Order"
                                tooltipOptions={{ position: "top" }}
                                disabled = {props?.data?.inspectionGroupFields?.length === 0}
                                onClick={() => {
                                    setShowFieldOrder(true);
                                    onGetQuestionInspectionfieldOrderList(props?.data?.uniqueDataFieldId)
                                }}>
                                <MdTableRows className="icon" />
                            </Button>
                        }
                    </div>
                }
            </>
        )
    }

    const dataGroup = (props) => {

        return (
            <div className={`data-col-1 ${(props?.isSection || props?.isGroupedControl) ? "if-border" : ""}`}>
                <div className={`wrap ${(props?.isGroupedControl) ? "wrap-validate" : ""}`}>
                    <div className={`field-name ${props?.isSection ? "name-100" : ""}`}>
                        {props?.isSection ? (
                            <h6 id="section-node"  onMouseEnter={(e) => onUpdateToolTipData(props?.dataFieldName,e)}
                            onMouseLeave={(e) => onHideToolTip(e)} >{onGetSlicedName(props?.dataFieldName)}</h6>
                        ) : props?.isGroupedControl ? (
                            <>
                                {type === "QUESTION" &&
                                    <>
                                    <span>
                                        {(props?.dataEntryControl?.dataEntryControlId !== 6) ? (
                                            <>
                                                {props?.forwardData ?
                                                
                                                    <IoPlayForwardSharp className="field-icon" />:
                                                    <IoPlayForwardSharp className="field-icon field-icon-disabled" />
                                                }
                                            </>
                                        ) : (props?.validatedValues?.length) ? (
                                            <>
                                                {props?.forwardData ?
                                                    <IoPlayForwardSharp className="field-icon" /> :
                                                    <IoPlayForwardSharp className="field-icon field-icon-disabled" />
                                                }
                                            </>
                                        ) : null}
                                        </span>
                                    </>
                                }
                                {(props?.dataEntryControl?.dataEntryControlId !== 6) ? (
                                    <>
                                        <div className="h6-offset" >
                                            <h6  onMouseEnter={(e) => onUpdateToolTipData(props?.dataFieldName,e)}
                                        onMouseLeave={(e) => onHideToolTip(e)} >
                                                {onGetSlicedName(props?.dataFieldName)}
                                            </h6>
                                        </div>
                                    </>
                                ) : (props?.validatedValues?.length) ? (
                                    <>
                                        <div className="h6-offset">
                                            <h6 onMouseEnter={(e) => onUpdateToolTipData(props?.dataFieldName,e)}
                                        onMouseLeave={(e) => onHideToolTip(e)}>
                                                {onGetSlicedName(props?.dataFieldName)}
                                            </h6>
                                        </div>
                                    </>
                                ) : <h6></h6>}
                            </>
                        ) : (
                            <>
                                {type === "QUESTION" &&
                                    <>
                                    <span>
                                        {props?.forwardData ?
                                            <IoPlayForwardSharp className="field-icon" /> :
                                            <IoPlayForwardSharp className="field-icon field-icon-disabled" />
                                        }
                                        </span>
                                    </>
                                }
                                <div className="offset" >
                                    <span
                                        id={`span-${props?.uniqueDataFieldId}`}
                                        onMouseEnter={(e) => onUpdateToolTipData(props?.dataFieldName,e)}
                                        onMouseLeave={(e) => onHideToolTip(e)}
                                    >{onGetSlicedName(props?.dataFieldName)}</span>
                                </div>
                            </>
                        )}
                        {props?.isGroupedControl &&
                            <ActionTemplate data={props} />
                        }
                    </div>

                    {!props?.isSection ? (
                        <div className="field-div">
                            <FormElement
                                data={props}
                                control={control}
                                errors={errors}
                                watch={watch}
                                onSelectionChange={onSelectionChange}
                                checkIsValueChanged={checkIsValueChanged}
                                setValue={setValue}
                                calendarAppendTo={true}
                                fieldFocus={fieldFocus}
                                isMandatory={isInspectionField}
                                setFieldFocus={setFieldFocus}
                            />
                        </div>
                    ) : null}

                    {!props?.isGroupedControl &&
                        <ActionTemplate data={props} />
                    }
                </div>
                {props?.isSection &&
                    props?.inspectionGroupFields?.map((item: any) => (
                        dataGroup(item)
                    ))
                }
            </div>
        );
    }

    return (
        <div style={type === "QUESTION" ? { width:'80%'} : {}} className={`inspection-forms-wrapper ${isExpanded ? "" : "expand"}`}>
            <div className="main-wrapper">
                <div className="title-header">
                    
                    <h6  onMouseEnter={(e) => onUpdateToolTipData(formElements?.label,e)} onMouseLeave={(e) => onHideToolTip(e)}>{onGetSlicedName(formElements?.label)} </h6>
                   <ActionTemplate isAddAction={isAddAction} isCheckedAction={isCheckedAction} />
                </div>
                <ScrollPanel className="data-wrapper-scroll">
                    <form ref={inspectionForm} className="data-wrapper" onSubmit={handleSubmit(onSubmit, onError)} >

                        {formElements?.inspectionGroupFields?.map((item: any) => (
                            dataGroup(item)
                        ))
                        }

                    </form>
                </ScrollPanel>
            </div>
            {showFieldOrder && (
                <DatafieldOrder
                    header="Field Order"
                    showModal={showFieldOrder}
                    data={fieldOrder}
                    onOrderUpdated={onOrderUpdated}
                    onHideModal={() => setShowFieldOrder(false)}
                />
            )}
        </div>

    );
});

export default memo(InspectionForms);
