import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../service/HttpService";
import { AppThunk } from "../../store";
export interface SalesActivityState {
  errors: any;
  panelTypeData: any;
  equipmentTypeData: any;
  modelData: any;
  selectedBranchOfficeData: any
  selectedPanelTypedata: any
  selectedEquipmentTypeData: any
  selectedModelData: any
  branchOfficeListForSalesActivity:any
}
let initialState: SalesActivityState = {
  errors: "",
  panelTypeData: [],
  equipmentTypeData: [],
  modelData: [],
  selectedBranchOfficeData: [],
  selectedPanelTypedata: [],
  selectedEquipmentTypeData: [],
  selectedModelData: [],
  branchOfficeListForSalesActivity:[]
};
const SalesActivityState = createSlice({
  name: "salesActivity",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setPanelTypeListData: (state, { payload }: PayloadAction<any>) => {
      state.panelTypeData = payload;
    },
    setEquipmentTypeListData: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeData = payload;
    },
    setModelListData: (state, { payload }: PayloadAction<any>) => {
      state.modelData = payload;
    },
    setSelectedBranchOfficeData: (state, { payload }: PayloadAction<any>) => {
      state.selectedBranchOfficeData = payload;
    },
    setSelectedPanelTypedata: (state, { payload }: PayloadAction<any>) => {
      state.selectedPanelTypedata = payload;
    },
    setSelectedEquipmentTypeData: (state, { payload }: PayloadAction<any>) => {
      state.selectedEquipmentTypeData = payload;
    },
    setSelectedModelData: (state, { payload }: PayloadAction<any>) => {
      state.selectedModelData = payload;
    },
    setBranchOfficeListForSalesActivity: (state, { payload }: PayloadAction<any>) => {
      state.branchOfficeListForSalesActivity = payload;
    }
  },
});

export const getPanelTypeList = (body: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/salesactivityreport/paneltypes`, body);
    if (res.status === "done") {
      dispatch(setPanelTypeListData(res.data?.filterTypes));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentTypeList = (body: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/salesactivityreport/equipmentclasses`, body);
    if (res.status === "done") {
      dispatch(setEquipmentTypeListData(res.data?.filterTypes));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getModelList = (body: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/salesactivityreport/models`, body);
    if (res.status === "done") {
      dispatch(setModelListData(res?.data?.filterTypes));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getBranchOfficeListForSalesActivity = (): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/salesactivityreport/branchofficelist`);
      if (res.status === "done") {
        if (res.data) {
          dispatch(setBranchOfficeListForSalesActivity(res?.data?.branchOfficeList));
        }
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const {
  setErrors,
  setPanelTypeListData,
  setEquipmentTypeListData,
  setModelListData,
  setSelectedBranchOfficeData,
  setSelectedPanelTypedata,
  setSelectedEquipmentTypeData,
  setSelectedModelData,
  setBranchOfficeListForSalesActivity
} = SalesActivityState.actions;
export default SalesActivityState.reducer;