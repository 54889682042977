import React, { useState,useRef, useEffect } from "react";
import { useAppSelector } from "redux/hooks";

import { FilePond, registerPlugin } from "react-filepond";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import UploadModal from "components/Table/UploadModal/UploadModal";
import { baseURL } from "service/HttpService";
import { useDispatch } from "react-redux";
import { onUploadMultipleSiteAttachment } from "redux/pages/Administration/Site/siteSlice";
import { onUploadMultipleBuildingAttachment } from "redux/pages/Administration/Building/buildingSlice";
import { onUploadMultipleFloorAttachment } from "redux/pages/Administration/Floor/floorSlice";
import { getPermittedFiles } from "redux/common/commonSlice";
interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    setDisplayInfoModal: (state: boolean) => void;
    setMessage: any;
    selectedTab: string;
    selectedSite: any;
    setSelectedRows:any;
}

export const AttachmentUpload: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRows = [],
    setDisplayInfoModal=()=>{},
    setMessage="",
    selectedTab="Sites",
    selectedSite = [],
    setSelectedRows=[],
}) => {

    type FormData = {
        description: string;
    };

    const {
        control,
        handleSubmit,
    } = useForm<FormData>({
        defaultValues: {
            description: ""
        },
    });

    const permittedFiles = useAppSelector((state) => state.common.permittedFiles);
    registerPlugin(FilePondPluginFileValidateType);

    const addForm: any = useRef();
    const addFileRef: any = useRef();
    const dispatch = useDispatch<any>();

    const [enableUpload, setEnableUpload] = useState(true);
    const [files, setFiles] = useState<any>([]);
    const [acceptedFileTypes, setAcceptedFileTypes] = useState(["allowed/extension"]);

    useEffect(() => {
        dispatch(getPermittedFiles())
    }, []);

    useEffect(() => {
        let fileTypes: any = ["allowed/extension"];
        permittedFiles.forEach(item => {
            if (item.extension1 !== null) {
                fileTypes.push(item.extension1)
            }
            if (item.extension2 !== null) {
                fileTypes.push(item.extension2)
            }
        });
        setAcceptedFileTypes(fileTypes);
    }, [permittedFiles]);

    
    const onprocessfiles = () => {
        setEnableUpload(false);
    };

    const getTooltipMessage = (field) => {
        if (field.value.length >4000) {           
            return "Maximum character exceeded";
          }
      };
      
    const beforeAddFile = (file) => {
        let data = addFileRef.current.props.files;
        if (data?.length > 0) {
            data.map((item: any) => {
                if (file?.filename === item.filename && file?.fileSize === item.fileSize) {
                    addFileRef.current.removeFiles(item.id)
                }
            })
        }
        return true
    }

    const onWarning = (error: any) => {
        if (error && error.body === 'Max files') {
            setDisplayInfoModal(true);
            setMessage('Maximum of 5 files can only be uploaded at a time.');
        }
    };

    const onRemoveFiles = (error: any, file: any) => {
        if (file?.status === 8 || file?.status === 6) {
            const reaminingFiles = addFileRef.current.getFiles();
            if (reaminingFiles.length) {
                let fileCount: number = 0;
                reaminingFiles.map((item: any) => {
                    if (item.status !== 5) {
                        fileCount += fileCount + 1;
                    }
                });

                if (fileCount > 0) {
                    setEnableUpload(true);
                } else {
                    setEnableUpload(false);
                }
            } else {
                setEnableUpload(true);
            }
        }
    };

    const onFileError = (error: any, file?: any, status?: any) => {
        if(error) {
          setEnableUpload(true);
        }
      };
    
    const onProcessFileStart = (file: any) => {
        setEnableUpload(true);
    };

    useEffect(() => {
        if (!files?.length) setEnableUpload(true);
    }, [files]);

    const fileTypeValidation = (source: File, type: string) => new Promise<string>((resolve, reject) => {
        const uploadedFileExtension = source.name.split('.').pop();
        const isAllowed = acceptedFileTypes.find(fileType => fileType.split('.').pop() === uploadedFileExtension) !== undefined;
        if (isAllowed) {
            resolve('allowed/extension');
        } else {
            reject('.' + uploadedFileExtension);
        }
    });

    const addFormSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
    };

    const onAddFormSubmit = (data: any) => {
        if (files.length) {
            let tempFileIds: string = "";
            files.map((item: any) => {
                let response = JSON.parse(JSON.parse(JSON.stringify(item.serverId)));
                if (response?.status === "done") {
                    tempFileIds += response.data?.uniqueDirectoryId[0] + ","
                }
            })
            tempFileIds = tempFileIds.slice(0, -1);
            let uniqueSiteIds :any = [];
            selectedRows.map((item:any,key:any)=>uniqueSiteIds.push(item.uniqueSiteId))
            let siteBody = {
                "UniqueSiteIds":uniqueSiteIds, 
                "extension2": "",
                "description": data.description,
                TempStorageDirectory: tempFileIds
            };
            let uniqueBuildingIds :any = [];
            selectedRows.map((item:any,key:any)=>uniqueBuildingIds.push(item.uniqueBuildingId))
            let buildingBody ={
                "UniqueBuildingIds":uniqueBuildingIds,
                "extension2": "",
                "description": data.description,
                TempStorageDirectory: tempFileIds
            };
            let uniqueFloorIds :any = [];
            selectedRows.map((item:any,key:any)=>uniqueFloorIds.push(item.uniqueFloorId))
            let floorBody ={
                "uniqueFloorIds":uniqueFloorIds,
                "extension2": "",
                "description": data.description,
                TempStorageDirectory: tempFileIds
            }
            setEnableUpload(true);
            switch (selectedTab) {
                case "Sites":
                    dispatch(onUploadMultipleSiteAttachment(siteBody, setShowModal, setMessage, setDisplayInfoModal, setEnableUpload));
                    break;
                case "Buildings":
                    dispatch(onUploadMultipleBuildingAttachment(selectedRows,buildingBody, setShowModal, setMessage, setDisplayInfoModal, setEnableUpload,setSelectedRows));
                    break;
                case "Floors":
                    dispatch(onUploadMultipleFloorAttachment(selectedRows,floorBody,setShowModal, setMessage, setDisplayInfoModal, setEnableUpload,setSelectedRows));
                    break;
                default:
                    break;
            }
        } else {
            setDisplayInfoModal(true);
            setMessage('Select a File to upload');
        }
    };
    
    const onError = (errors: any, e: any) => {};

    return (
        <>
            <UploadModal
                header="Upload Attachment"
                showModal={showModal}
                setShowModal={setShowModal}
                OnSaveClick={addFormSubmitHandler}
                isAdd={false}
                style={{ width: "35vw", maxHeight: "95%" }}
                buttonDisable={enableUpload}
            >
                <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <div className="flex align-items-center flex-column upload-file-section">
                                <div className="upload-detail" style={{ width: "100%", height: "50%" }}>
                                    <FilePond
                                        ref={addFileRef}
                                        allowMultiple={true}
                                        maxFiles={5}
                                        files={files}
                                        server={`${baseURL}api/v1/common/files/upload`}
                                        onprocessfiles={onprocessfiles}
                                        onupdatefiles={setFiles}
                                        beforeAddFile={beforeAddFile}
                                        onwarning={onWarning}
                                        onremovefile={onRemoveFiles}
                                        onerror={onFileError}
                                        onprocessfilestart={onProcessFileStart}
                                        name="files"
                                        credits={false}
                                        labelIdle='Click to choose an file or drag and drop an file'
                                        labelFileLoading=""
                                        labelTapToUndo=""
                                        labelFileProcessing=""
                                        labelFileProcessingComplete=""
                                        labelFileProcessingAborted=""
                                        labelFileProcessingError=""
                                        labelButtonAbortItemProcessing=""
                                        labelButtonUndoItemProcessing=""
                                        labelButtonRetryItemProcessing=""
                                        labelButtonProcessItem=""
                                        labelTapToCancel=""
                                        labelFileRemoveError=""
                                        labelTapToRetry=""
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={acceptedFileTypes}
                                        fileValidateTypeDetectType={fileTypeValidation}
                                        fileValidateTypeLabelExpectedTypes={'You do not have privilege to add attachment of this File Type'}
                                    />
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="description" control={control} rules={{
                                maxLength: 4000, 
                            }} render={({ field, fieldState }) => (
                                <InputTextarea id="Textarea"
                                 tooltip ={getTooltipMessage(field)}
                                 tooltipOptions={{className:'p-tooltip-custom',position: 'top',event: 'focus' }}
                                 className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                            )} />
                            <label htmlFor="inputtext">Description</label>
                            <span className='textarea-txt-count'>Max 4000 Chars</span>
                        </span>
                    </div>
                </form>
            </UploadModal>
        </>
    );
};

export default AttachmentUpload;
