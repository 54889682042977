import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { ScrollPanel } from 'primereact/scrollpanel';
import DeficiencyList from "./SystemDetailComponent/DeficiencyList";
import TurnAroundList from "./SystemDetailComponent/TurnAround";
import SoftwareVersionUpdates from "./SystemDetailComponent/SoftwareVersionUpdates";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { getProjectSystems } from "redux/pages/Reports/ProvenOutcomesSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";





interface Props {
	selectedProjectId: any;
	isAdd:boolean;

}

type FormData = {
	hyperLink: string;
	description: string;
	startDate: string;
	endDate: string;
};

export const SystemDetails: React.FC<Props> = ({
	selectedProjectId = "",
	isAdd = false	

}) => {
const [showDeficiencyModal, setShowDeficiencyModal] = useState(false);
const [showTurnAroundModal, setShowTurnAroundListModal] = useState(false);
const [showSoftwareVersionUpdatesModal, setShowSoftwareVersionUpdatesModalListModal] = useState(false);
const [selectedSystem,setSelectedSystem] = useState<any>(null);
const [systemData,setSystemData] = useState<any>(null);
const [message, setMessage] = useState("");
const [displayInfoModal, setDisplayInfoModal] = useState(false);


const dispatch = useDispatch<any>();
const systemDetailsList = useAppSelector((state) => state.provenOutcomes. systemList);
const newlyAddedData = useAppSelector((state) => state.provenOutcomes.newlyAddedData)
	
const {
	control,
} = useForm<FormData>({
	defaultValues: {
	}
});



useEffect(() => {
    getSystemList();
  }, [])

	const getSystemList = () => {
		if (!isAdd)
			dispatch(getProjectSystems(selectedProjectId, setMessage, setDisplayInfoModal));
		else
			dispatch(getProjectSystems(newlyAddedData?.uniqueProjectId, setMessage, setDisplayInfoModal));
	}

	useEffect(() => {
		if (systemDetailsList) {
			let system: { flsSystemId: any; flsSystemName: string; projectId: any }[] = [];
			system.push({ flsSystemId: "--Select--", flsSystemName: "--Select--", projectId: 0 });
			systemDetailsList?.map((item: any) => {
				system.push({
					flsSystemId: item.flsSystemId,
					flsSystemName: item.flsSystemName,
					projectId: item.projectId
				});
			})

			setSystemData(system);
			if (systemDetailsList?.length == 1) { setSelectedSystem(system[1]?.flsSystemId) }
			else { setSelectedSystem(system[0]?.flsSystemId) }
		}
	}, [systemDetailsList]);

const onSystemListChange = (event) => {
	setSelectedSystem(event.value);
}
const ShowSoftwareVersionUpdatesClick = () => {
	if(selectedSystem != "--Select--" && selectedSystem) {
		setShowSoftwareVersionUpdatesModalListModal(true);
	}
	else{
		setMessage("Select a System");
        setDisplayInfoModal(true);
	}
}
const ShowDeficiencyClick = () => {
	if(selectedSystem != "--Select--" && selectedSystem) {
		setShowDeficiencyModal(true);
	}
	else{
		setMessage("Select a System");
        setDisplayInfoModal(true);
	}
}
const ShowTurnAroundListClick = () => {
	if(selectedSystem != "--Select--" && selectedSystem) {
		setShowTurnAroundListModal(true);
	}
	else{
		setMessage("Select a System");
        setDisplayInfoModal(true);
	}
}

	return (
		<div className="step-item">
			<h5 className="title">System Details</h5>
			<div className="content-area">
				<ScrollPanel>
					<div className="system-details custom-float-field">
						<div className="field row">
							<div className="col-12">
								<span className="p-float-label">
									<Controller
										name="hyperLink"
										control={control}
										render={({ }) => (
											<Dropdown 
											className="w-100"
											value={selectedSystem}
											onChange={(e) => {
												onSystemListChange(e)
											}}											
											options={systemData}
											optionLabel="flsSystemName"
											optionValue="flsSystemId"
											/>
										)}
									/>
									<label  htmlFor="inputtext">
										System
									</label>
								</span>
							</div>
						</div>
					</div>
					<div className="custom-btn flex-column d-flex">
						<button className="btn-white" onClick={()=>ShowSoftwareVersionUpdatesClick()}>Software Versions and Updates</button>
						<button className="btn-white" onClick={()=>ShowTurnAroundListClick()}>Inspection Report Turnaround</button>
						<button className="btn-white" onClick={() =>ShowDeficiencyClick()}>Inspection Deficiency List</button>
					</div>
					<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
					{showDeficiencyModal ?<DeficiencyList
						showModal={showDeficiencyModal}
						setShowModal={setShowDeficiencyModal} systemId={selectedSystem} selectedProjectId={selectedProjectId}/>: null}
                     
					  {showTurnAroundModal ?   <TurnAroundList
                        showModal={showTurnAroundModal}
                        setShowModal={setShowTurnAroundListModal} systemId={selectedSystem} selectedProjectId={selectedProjectId}/>: null}
                      
					   {showSoftwareVersionUpdatesModal ? <SoftwareVersionUpdates
                        showModal={showSoftwareVersionUpdatesModal}
                        setShowModal={setShowSoftwareVersionUpdatesModalListModal} systemId={selectedSystem} selectedProjectId={selectedProjectId}/>: null}
				</ScrollPanel>
			</div>
		</div>
		
	);
};

export default SystemDetails;

