import React, { useState,useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
import { Dialog } from "primereact/dialog";



interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  layersSubmit?: (data: any) => void;
}

type FormData = {
};

export const Layers: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRows = [],
  layersSubmit = () => { },
}) => {

   const [layerData, setLayerData] = useState<any>([]);
   const checkedRows = useRef<any>();
   const layerForm: any = useRef();

  

  const {
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
    },
  });
  useEffect(() => {
    if(selectedRows && selectedRows.length>0){
      let layerDataInfo= JSON.parse(JSON.stringify(selectedRows));
    setLayerData(layerDataInfo);
    }
   
 }, [selectedRows])
  const formSubmitHandler = (data: any) => {
    layerForm.current.requestSubmit();
  };


  const onAddFormSubmit = (data: any) => {
    let allCheckedSites :any= [...checkedRows.current];
    allCheckedSites.forEach((element) => {
      let currentIndex = allCheckedSites.findIndex((item: any) => item.id === allCheckedSites?.id);
      if(currentIndex!==-1){
      layerData.splice(currentIndex, 0, element);}
  });
    layerData.forEach((element) => {
      element["layerName"] = element.siteName;
      if (element.checked){
          element["isVisible"] = true;
          
      }
      else{
          element["isVisible"] = false;
      }
  });
  layersSubmit && layersSubmit(layerData)
  };
  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Update" className="update btn" autoFocus onClick={formSubmitHandler} />
      </>
    );
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <Dialog
        header="Layer List"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={layerForm}
        >
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 py-0">
            <MultiSelect
            singleSelect
            list={layerData}
            getCheckedItems={(checkedItems) => {
              checkedRows.current = checkedItems;
            }}
             headerName = "Layers"
          />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default Layers;
