import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import DataTableComponent from "components/Table/DataTable";
//components
import UserModal from './Manage/UserModal/UserModal';
import EntityModal from './Manage/EntityModal/EntityModal';
import ActivityModal from './Manage/ActivityModal/ActivityModal';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
//icons
import { FiExternalLink } from 'react-icons/fi';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
//redux
import { useDispatch } from 'react-redux';
import { onGetActivities, onGetEntities, onGetLogBookList, onGetModules, onGetUserRoles, onGetUsers, onLogBookExport, setLogBookList } from 'redux/pages/Administration/LogBook/logBookSlice';
import { useAppSelector } from 'redux/hooks';
//utility
import { getGridColumnDataWithStyle } from 'pages/InspectionTasks/ManageInspectionTasks/InspectionDetails/Equipment/utility';
import moment from 'moment';
import { ScrollPanel } from 'primereact/scrollpanel';


type searchFormData = {
    plannedStartDate: Date;
    plannedEndDate: Date;
    userRoleId: string;
    siteId: string;
    moduleIds: object;
    isCheckedAll: boolean;
};

interface LogbookPropType {

}
const Logbook: React.FC<LogbookPropType> = (props) => {

    const [gridData, setGridData] = useState<any>([]);
    const [columns, setColumns] = useState<any>([]);
    const [pageDetails, setPageDetails] = useState<any>({});
    const [pageIndex, setPageIndex] = useState(0);


    const { control, formState: { errors }, handleSubmit, watch, setValue } = useForm<searchFormData>({
        defaultValues: {
            plannedStartDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            plannedEndDate: new Date(),
            userRoleId: "",
            siteId: "",
            moduleIds: [],
            isCheckedAll: false,
        },
    });
    const searchForm = useRef<any>(null);
    const [sites, setSites] = useState<any>([
        { siteId: "all", siteName: "All Sites" },
        { siteId: "", siteName: "This Site" }
    ]);
    const [key, setKey] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    //modals
    const [showUserModal, setShowUserModal] = useState(false);
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [selectedEntities, setSelectedEntities] = useState<any>([]);
    const [selectedActivities, setSelectedActivities] = useState<any>([]);
    const [bodyInfo, setBodyInfo] = useState<any>(null);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [currentStart, setCurrentStart] = useState(1);
    const [payload, setPayload] = useState<any>();

    const dispatch = useDispatch<any>();
    const userRoles = useAppSelector((state) => state.logBook.userRoles);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    const logPageDetails = useAppSelector((state) => state.logBook.logPageDetails);
    const modules = useAppSelector((state) => state.logBook.modules);
    const users = useAppSelector((state) => state.logBook.users);
    const entities = useAppSelector((state) => state.logBook.entities);
    const activities = useAppSelector((state) => state.logBook.activities);
    const logBooks = useAppSelector((state) => state.logBook.logBookList);
    const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

    useEffect(() => {
        dispatch(onGetUserRoles());
        dispatch(onGetModules());
    }, []);

    useEffect(() => {
        if (loggedInUserDetails) {
            sites[1].siteId = loggedInUserDetails?.userDefaults?.uniqueSiteId;
            setSites(sites);
        }
    }, [loggedInUserDetails]);

    useEffect(() => {
        if (userRoles?.length) {
            setValue("userRoleId", userRoles[0]?.userRoleId);
            setValue("siteId", "all");
        }
    }, [userRoles])

    useEffect(() => {
        if (modules?.length) {
            let list: any = [];
            for (let j = 0; j < modules?.length; j++) {
                let sm = modules[j].cloudid;
                let item = {
                    [sm]: false,
                };
                list.push(item);
            }
            setValue("moduleIds", list);
        }
    }, [modules]);

    useEffect(() => {
        if (key) {
            searchForm?.current?.requestSubmit();
        }
    }, [key])    

    useEffect(() => {        
        if (logBooks) {
            const column = getGridColumnDataWithStyle(logBooks?.generalProperties);
            if(column[0].header == 'Sl No'){
                column[0].sortable = false
            }
            setGridData(logBooks?.data);
            setColumns(column);
            dispatch(setLogBookList(""))
        }
    }, [logBooks])

    useEffect(() => {
        if (payload && rowsPerPage && rowsPerPage > 0) {
            dispatch(onGetLogBookList(payload, 1, sortField, sortOrder));
        }
    }, [dispatch, rowsPerPage]);

    useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
            let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
            setPageDetails(modifiedPageDetails);
            setCurrentStart(1)
            setPageIndex(0)
        }
    }, [rowsPerPage]);

    useEffect(() => {
        if (logPageDetails) {
            setPageDetails(logPageDetails);
        }
    }, [logPageDetails])
    
    const onSubmit = useCallback((data) => {
        let multipleValue: any = [];
        if (Array.isArray(data["moduleIds"])) {
            for (let i = 0; i < data["moduleIds"]?.length; i++) {
                for (const obj in data["moduleIds"][i]) {
                    if (data["moduleIds"][i][obj]) {
                        multipleValue.push(parseInt(obj));
                    }
                }
            }
        }
        if (!multipleValue?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one Module");
            return;
        }

        const body = {
            fromDate: moment(data?.plannedStartDate).format("YYYY-MM-DD").toString(),
            toDate: moment(data?.plannedEndDate).format("YYYY-MM-DD").toString(),
            userRoleId: data.userRoleId === "0" ? null : data.userRoleId,
            siteId: data.siteId === "all" ? null : data.siteId,
            moduleIds: multipleValue,
            isAllModules: data.isCheckedAll,
        }

        if (key === "users")
            getUsers(body);
        else if (key === "entities")
            getEntities(body);
        else if (key === "activities")
            getActivities(body)
        else if (key === "searches")
            onSearch(body);
    }, [key])

    const onSearch = (body) => {
        if (!selectedUsers?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one User");
            return;
        }
        if (!selectedEntities?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one Entity");
            return;
        }
        if (!selectedActivities?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one Activity");
            return;
        }
        let userIds: any = [];
        let entities: any = [];
        let activities: any = [];
        selectedEntities.forEach(element => {
            entities.push(element?.entityId);
        });
        selectedUsers.forEach(element => {
            userIds.push(element?.userId);
        });
        selectedActivities.forEach(element => {
            activities.push(element?.eventId);
        });
        body.userIds = userIds;
        body.entityIds = entities;
        body.eventIds = activities;
        setPayload(body);
        dispatch(onGetLogBookList(body, 1, sortField, sortOrder));
        setBodyInfo(body);
    }

    const getUsers = (body) => {
        dispatch(onGetUsers(body));
        setShowUserModal(true);
    }

    const getEntities = (body) => {
        if (!selectedUsers?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one User");
            return;
        }
        let userIds: any = [];
        selectedUsers.forEach(element => {
            userIds.push(element?.userId);
        });
        body.userIds = userIds;
        dispatch(onGetEntities(body));
        setShowEntityModal(true);
    }

    const getActivities = (body) => {
        if (!selectedUsers?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one User");
            return;
        }
        if (!selectedEntities?.length) {
            setDisplayInfoModal(true);
            setMessage("Select atleast one Entity");
            return;
        }
        let userIds: any = [];
        let entities: any = [];
        selectedEntities.forEach(element => {
            entities.push(element?.entityId);
        });
        selectedUsers.forEach(element => {
            userIds.push(element?.userId);
        });
        body.userIds = userIds;
        body.entityIds = entities;
        dispatch(onGetActivities(body));
        setShowActivityModal(true);
    }

    const onDataExport = () => {
        if (bodyInfo) {
            dispatch(onLogBookExport(bodyInfo));
        }
    }

    const onPageChanged = (options: any) => {
        if (pageIndex !== options) {
            let currentStart = (options * pageDetails?.currentLength) + 1
            setCurrentStart(currentStart)
            setPageIndex(options)
            dispatch(onGetLogBookList(payload, currentStart, sortField, sortOrder));
        }
    };

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
        sortDirection = "ASC"
        else
        sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
        dispatch(onGetLogBookList(payload, currentStart, event.sortField, sortDirection));
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const onAllCheckBoxChanged = (checked) => {
        const checkedItem: any = watch("moduleIds");
        for (let i = 0; i < checkedItem?.length; i++) {
            for (const k in checkedItem[i]) {
                checkedItem[i][k] = checked;
            }
        }
        setValue("moduleIds", checkedItem);
    }

    const onCheckBoxChanged = (cloudid) => {
        let count = 0;
        const checkedItem: any = watch("moduleIds");
        for (let i = 0; i < checkedItem?.length; i++) {
            if (checkedItem?.[i]?.[cloudid] !== undefined) {
                checkedItem[i][cloudid] = !checkedItem?.[i]?.[cloudid];
            }
            for (const k in checkedItem[i]) {
                if (checkedItem[i][k])
                    count++;
            }
        }
        if (checkedItem?.length === count)
            setValue("isCheckedAll", true);
        else
            setValue("isCheckedAll", false);
        setValue("moduleIds", checkedItem);
    };

    const getCheckBoxActive = (cloudid) => {
        let flag = false;
        const checkedItem: any = watch("moduleIds");
        for (let i = 0; i < checkedItem?.length; i++) {
            if (checkedItem[i][cloudid] !== undefined) {
                flag = checkedItem?.[i]?.[cloudid];
            }
        }
        return flag;
    };

    return (
        <div className='logbook-wrapper'>
            <div className='logbook'>
                <form onSubmit={handleSubmit(onSubmit)} ref={searchForm}>
                    <div className='select-box'>
                        <div className='input-col border-divider'>
                            <div className="custom-float-field mb-4">
                                <span className="p-float-label">
                                    <Controller
                                        name="plannedStartDate"
                                        control={control}
                                        rules={{
                                            required: 'This field is required.'
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Calendar
                                                id="plannedStartDate"
                                                className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                                dateFormat="yy-mm-dd"
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {field.onChange(e.value);setKey("");}} />
                                        )} />
                                    <label className='mandatory' htmlFor="calendar">From</label>
                                    {getFormErrorMessage('plannedStartDate')}
                                </span>
                            </div>
                            <div className="custom-float-field">
                                <span className="p-float-label">
                                    <Controller
                                        name="plannedEndDate"
                                        control={control}
                                        rules={{
                                            required: 'This field is required.'
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Calendar
                                                id="plannedEndDate"
                                                className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                                dateFormat="yy-mm-dd"
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {field.onChange(e.value);setKey("");}} />
                                        )} />
                                    <label className='mandatory' htmlFor="calendar">To</label>
                                    {getFormErrorMessage('plannedEndDate')}
                                </span>
                            </div>
                        </div>
                        <div className='input-col border-divider'>
                            <div className="custom-float-field mb-4">
                                <span className="p-float-label">
                                    <Controller
                                        name="userRoleId"
                                        control={control}
                                        rules={{
                                            required: 'This field is required.'
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="userRoleId"
                                                className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {field.onChange(e.value);setKey("")}}
                                                options={userRoles}
                                                optionLabel="userRoleName"
                                                optionValue='userRoleId'
                                            />

                                        )} />
                                    {getFormErrorMessage('userRoleId')}
                                    <label className='mandatory' htmlFor="dropdown">User</label>
                                </span>
                            </div>
                            <div className="custom-float-field">
                                <span className="p-float-label">
                                    <Controller
                                        name="siteId"
                                        control={control}
                                        rules={{
                                            required: 'This field is required.'
                                        }}

                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="system"
                                                className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {field.onChange(e.value);setKey("");}}
                                                options={sites}
                                                optionLabel="siteName"
                                                optionValue='siteId'
                                            />

                                        )} />
                                    <label className='mandatory' htmlFor="dropdown">All Sites</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='check-box border-divider'>
                        <div className='title'>
                            <h6>Modules</h6>
                            <div className="custom-float-field mb-0">
                                <div className="field-checkbox">
                                    <Controller
                                        name="isCheckedAll"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                inputId="binary"
                                                checked={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e.checked);
                                                    onAllCheckBoxChanged(e.checked);
                                                    setKey("");
                                                }}
                                            />
                                        )} />
                                    <label className='mandatory' htmlFor="binary">All</label>
                                </div>
                            </div>
                        </div>
                        
                        <ScrollPanel className="check-box-col">
                        <div className='box-col'>
                        {modules?.length > 0 &&
                            <Controller
                                name="moduleIds"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {modules?.length > 0 &&
                                            modules?.map((item, key) => (
                                                <div key={key} className="custom-float-field">
                                                    <div className="field-checkbox">
                                                        <Checkbox
                                                            inputId={item?.cloudid}
                                                            name={item?.cloudid}
                                                            onChange={() => {onCheckBoxChanged(item?.cloudid);setKey("");}}
                                                            checked={getCheckBoxActive(item?.cloudid)}
                                                        />
                                                        <label htmlFor={item?.cloudid} title={item?.cloudName}>{item?.cloudName}</label>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                )} />
                        }
                        </div>
                        </ScrollPanel>
                    </div>
                </form>

                <div className='btn-wrapper'>
                    <Button className='box-btn' onClick={() => {
                        setKey("users");
                    }}>User (All) <FiExternalLink /> </Button>
                    <Button className='box-btn' onClick={() => {
                        setKey("entities");
                    }}>Entity (All) <FiExternalLink /> </Button>
                    <Button className='box-btn' onClick={() => {
                        setKey("activities");
                    }}>Activity (All) <FiExternalLink /> </Button>
                </div>
                <div className="search-col">
                    <Button label="Search" className='submit-btn' autoFocus onClick={() => {
                        setKey("searches");
                    }} />
                </div>
            </div>
            <div className='logbook-table'>
                <div className="container-fluid ">
                    <div className="row">
                        <div className="icon-wrapper col-12">
                            <div className=" table-config">
                                <div className="action-btns">
                                    <Button className="button-icon" tooltip="Export" tooltipOptions={{ position: "top" }} onClick={() => onDataExport()}>
                                        <FaDownload className="icon" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTableComponent
                    rows={gridData}
                    cols={columns}
                    paginator={true}
                    dataKeyId="Sl No"
                    title={``}
                    pageDetails={pageDetails}
                    pageIndex={pageIndex}
                    onPageChanged={onPageChanged}
                    onSortData={onSort}
                    showCustomSearchCmp
                />
            </div>
            {showUserModal &&
                <UserModal
                    showModal={showUserModal}
                    users={users}
                    selectedUsers={selectedUsers}
                    onHide={(users) => {
                        setKey("");
                        setShowUserModal(false);
                        setSelectedUsers(users)
                    }}
                />
            }
            {showEntityModal &&
                <EntityModal
                    showModal={showEntityModal}
                    entities={entities}
                    selectedEntities={selectedEntities}
                    onHide={(entities) => {
                        setKey("");
                        setShowEntityModal(false);
                        setSelectedEntities(entities)
                    }}
                />
            }
            {showActivityModal &&
                <ActivityModal
                    showModal={showActivityModal}
                    activities={activities}
                    selectedActivities={selectedActivities}
                    onHide={(activities) => {
                        setKey("");
                        setShowActivityModal(false);
                        setSelectedActivities(activities)
                    }}
                />
            }
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={(flag) => {
                    setDisplayInfoModal(flag);
                    setKey("");
                }}
                message={message} />
        </div>
    )
}

export default memo(Logbook)