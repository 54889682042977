import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "../../redux/hooks";
import { createReportPreviewSession, setReportPreviewSession } from "../../redux/pages/Administration/Site/siteSlice";
import { Button } from 'primereact/button';
import { ListGroup } from 'react-bootstrap';
//icons
import { BsArrowRight } from "react-icons/bs";
import { RiFileExcel2Line } from 'react-icons/ri';
import { onExportReport } from 'redux/common/report/customizeReportSlice';

const ExpiredContractsReport = () => {
	const [reportName, setReportName] = useState<any>(null);
	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
	const navigate = useNavigate();

	const dispatch = useDispatch<any>();
	useEffect(() => {
		if (reportPreviewSession === "") {
            const reportParameters = {
					reportParameters: {	},
					reportType: "EXPIRED_CONTRACTS"
			};
			dispatch(createReportPreviewSession(reportParameters));
		}

		return(()=>{
			dispatch(setReportPreviewSession(""));
		})
	},[])

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

  const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: { },
			exportType: "EXPIRED_CONTRACTS"
		};
		dispatch(onExportReport(reportParameters, "Expired Contracts"));
	};

  return (
	
   <div className='site-reports'>
	 <section className="d-flex align-items-center">
        <Breadcrumb
          title="Reports"
          classNames="report-header"
          handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
        />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
          <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
          <span>Expired Contracts</span>
        </div>
      </section>
	
      <div className="report-sort-field pt-2 pb-2">
        <div className="report-export-main d-flex">
          <div className="row px-2"></div>
          <div className="report-buttons-main d-flex">
            <div className='export-report-buttons'>
              <ListGroup horizontal>
                <span className='expor-title'>Export to</span>
                <ListGroup.Item>
                  <Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
                </ListGroup.Item> */}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>

	<ReportViewer reportId={reportName}/>
   </div>
  )
}

export default ExpiredContractsReport;