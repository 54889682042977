import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
//icons
import { BsExclamationCircleFill, BsFillPencilFill, BsTrashFill } from "react-icons/bs";
//primereact
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { classNames } from "primereact/utils";
//components
import PopOver from "components/common/PopOver/PopOver";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
//redux
import { getMailDomainList, onAddMailDomain, onDeleteMailDomain, onEditMailDomain } from "redux/common/mailDomainSlice";
import { useAppSelector } from "redux/hooks";


interface Props { }
export const MailDomains: React.FC<Props> = () => {
  type FormData = {
    mailDomain: string;
  };

  const mailDomainList = useAppSelector((state) => state.mailDomain.mailDomainList);

  const dispatch = useDispatch<any>();
  const addMailDomainForm: any = useRef();
  const editMailDomainForm: any = useRef();
  const scrollRef: any = useRef();
  const mailpopref = React.createRef<OverlayPanel>();

  const [showEditMailDomainModal, setShowEditMailDomainModal] = useState(false);
  const [showAddWindow, setShowAddWindow] = useState(false);
  const [currentMailDomain, setCurrentMailDomain] = useState<any>();
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      mailDomain: "",
    },
  });

  const {
    control: editControl,
    handleSubmit: editSubmit,
    setValue: setEditValue,
    formState: { errors: editErrors },
  } = useForm<FormData>({
    defaultValues: {
      mailDomain: "",
    },
  });

  useEffect(() => {
    dispatch(getMailDomainList());
  }, [])

  const formSubmitHandler = (data: any) => {
    addMailDomainForm.current.requestSubmit();
  };

  const onAddFormSubmit = (data: any) => {
    dispatch(onAddMailDomain(data, reset, setDisplayInfoModal, setMessage))
  };

  const editFormSubmitHandler = (data: any) => {
    editMailDomainForm.current.requestSubmit();
  };

  const onEditFormSubmit = (data: any) => {
    dispatch(onEditMailDomain(data, currentMailDomain?.accountMailDomainId, setDisplayInfoModal, setMessage, setShowEditMailDomainModal))
  };

  const onMoreMenuClick = (e: any, item: any) => {
    mailpopref.current?.toggle(e);
    setCurrentMailDomain(item);
  };

  const onEditMailDomaiinClick = () => {
    mailpopref.current?.hide();
    scrollRef.current?.refresh();
    if (currentMailDomain?.isMailDomainInUse) {
      setDisplayInfoModal(true)
      setMessage("Selected Mail Domain is in use, cannot be edited")
    } else {
      setShowEditMailDomainModal(true);
      setShowAddWindow(false);
      if (currentMailDomain) {
        setEditValue("mailDomain", currentMailDomain.mailDomain)
      }
    }
  }

  const onDeleteMailDomaiinClick = () => {
    mailpopref.current?.hide();
    if (currentMailDomain?.isMailDomainInUse ) {
      setDisplayInfoModal(true)
      setMessage("Selected Mail Domain is in use, cannot be deleted")
    } else {
    setEditValue("mailDomain", "");
    setMessage("Are you sure you want to delete the selected Mail Domain?");
    setDisplayConfirmModal(true);
    }
  }

  const onConfirmClick = () => {
    setDisplayConfirmModal(false);
    dispatch(onDeleteMailDomain(currentMailDomain?.accountMailDomainId, setDisplayInfoModal, setMessage));
  }

  const onError = (errors: any, e: any) => { };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const getEditFormErrorMessage = (name) => {
    return (
      editErrors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {editErrors[name].message}
        </span>
      )
    );
  };

  return (

    <div className="main-maildomains">
      <div className="main-maildomain">
        <p className="Pagetitle mb-0">
          <b>Mail Domains</b>
        </p>
        <div className="domain-list-add pt-3 pb-3">
          {showAddWindow && (
            <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addMailDomainForm}>
              <div className="add-main-maildomain d-flex flex-column justify-content-center align-items-center pt-2 pb-3
             mb-3">
                <div className="add-domain">
                  <p className="mandatory">Mail Domain</p>
                  <div className="add-domain-field">
                    <Controller
                      name="mailDomain"
                      control={control}
                      rules={{
                        required: "This field is required.",
                        pattern: {
                          value: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
                          message: "Enter a valid Domain",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText id="mailDomain" {...field} className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })} />
                      )}
                    />
                    {getFormErrorMessage("mailDomain")}
                  </div>
                  <div className="pt-3 text-right">
                    <Button
                      label="Cancel"
                      className="cancel-btn"
                      onClick={() => setShowAddWindow(false)}
                    />
                    <Button
                      label="Save"
                      className="save-btn"
                      onClick={() => formSubmitHandler}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
          {showEditMailDomainModal && (
            <form onSubmit={editSubmit(onEditFormSubmit, onError)} ref={editMailDomainForm}>
              <div className="add-main-maildomain d-flex flex-column justify-content-center align-items-center pt-2 pb-3
             mb-3">
                <div className="add-domain">
                  <p className="label  mandatory">Mail Domain</p>
                  <div className="add-domain-field">
                    <Controller
                      name="mailDomain"
                      control={editControl}
                      rules={{
                        required: "This field is required.",
                        pattern: {
                          value: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
                          message: "Enter a valid Domain",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText id="mailDomain" {...field} className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })} />
                      )}
                    />
                    {getEditFormErrorMessage("mailDomain")}
                  </div>
                  <div className="pt-3 text-right">
                    <Button
                      label="Cancel"
                      className="cancel-btn"
                      onClick={() => setShowEditMailDomainModal(false)}
                    />
                    <Button
                      label="Save Changes"
                      className="save-btn"
                      onClick={() => editFormSubmitHandler}
                    />
                  </div>
                </div>

              </div>
            </form>
          )}

          {!showAddWindow && (
            <div className="domain-add d-flex justify-content-end mb-2">
              <div className="new-mail-domain" onClick={() => { setShowAddWindow(true); setShowEditMailDomainModal(false); setValue("mailDomain", "") }}>
                <i className="pi pi-plus"></i>
                <span className="ml-1">New Mail Domain</span>
              </div>
            </div>
          )}
          
            <ScrollPanel style={{ width: "100%", height: "250px" }}>
            <div className="mail-domain-list">
              <div className="row">
                {mailDomainList && mailDomainList.length
                  ? mailDomainList.map((item: any, key: any) => (
                    <div key={key} className="col-3">
                      <Card>
                        <div className="icon">
                          <span>
                            <i className="pi pi-globe"></i>
                          </span>
                        </div>
                        <div className="domain-name ml-3">{item.mailDomain}</div>
                        <div className="option-icon">
                          <i
                            className="pi pi-ellipsis-v"
                            onClick={(e) => onMoreMenuClick(e, item)}
                          ></i>
                        </div>
                      </Card>
                    </div>
                  )):<span>No Data Found</span>}
              </div></div>
            </ScrollPanel>
          
        </div>
      </div>
      <PopOver ref={mailpopref}>
        <ul className="maildomain-icon-list">
          <li onClick={onEditMailDomaiinClick}>
            <span>
              {" "}
              <BsFillPencilFill className="icon" />
            </span>
            Edit
          </li>
          <li onClick={onDeleteMailDomaiinClick}>
            <span>
              <BsTrashFill className="icon" />
            </span>
            Delete
          </li>
        </ul>
      </PopOver>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={onConfirmClick}
        message={message}
      />
    </div>

  );
};
export default MailDomains;
