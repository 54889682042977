import React, { memo, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTableComponent } from "components/Table/DataTable";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { setSapImportStatusDetails } from "redux/pages/Manage/Tools/ToolSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
}

export const ImportSapDetails: React.FC<Props> = (props) => {
    const { showModal, setShowModal = () => { } } = props;

    const [pageIndex, setPageIndex] = useState(0);
    const [gridData, setGridData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [pageDetail, setPageDetail] = useState(null);

    const dispatch = useDispatch();
    const sapImportDetails = useAppSelector((state) => state.tool.sapImportStatusDetails);

    useEffect(() => {
        if (sapImportDetails) {
            const columns = getEquipmentColumn(sapImportDetails?.generalProperties)
            setColumnData(columns);
            setGridData(sapImportDetails?.data);
            setPageDetail(sapImportDetails?.pageDetails)
            dispatch(setSapImportStatusDetails(""));
        }
    }, [sapImportDetails])

    const onPageChanged = (options: any) => {
        if (options !== pageIndex) {
            setPageIndex(options);
        }
    };

    const getEquipmentColumn = (generalProperties) => {
        const columnInfo: any = [];
        if (generalProperties?.length) {
            for (var i = 0; i < generalProperties?.length; i++) {
                if (generalProperties[i]?.propertyType !== "INTERNAL") {
                    let data: any = {
                        field: generalProperties[i]?.name,
                        header: generalProperties[i]?.alias,
                        style: { flex: '1 0 200px' },
                        sortable: true,
                    }                   
                    columnInfo.push(data);
                }
            }
        }
        return columnInfo;
    }
    return (
        <>
            <Dialog
                header="Import Details"
                visible={showModal}
                style={{ width: "70vw", height: "70vh" }}
                onHide={() => setShowModal(false)}
                className={`s-modal sap-import-details`}
            >
                {columnData?.length > 0 &&
                    <DataTableComponent
                        rows={gridData}
                        cols={columnData}
                        paginator={true}
                        dataKeyId="id"
                        title={` `}
                        pageDetails={pageDetail}
                        pageIndex={pageIndex}
                        onPageChanged={onPageChanged}
                        showCustomSearchCmp
                    />
                }
            </Dialog>
        </>
    );
};

export default memo(ImportSapDetails);
