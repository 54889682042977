import React, { useEffect, useState } from 'react'
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { Button } from 'primereact/button';
import { ListGroup } from 'react-bootstrap';
//icons
import { BsArrowRight } from "react-icons/bs";
import { RiFileExcel2Line } from 'react-icons/ri';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { createReportPreviewSession, setReportPreviewSession } from 'redux/pages/Administration/Site/siteSlice';
import { onExportReport, setIsAppliedDone } from 'redux/common/report/customizeReportSlice';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import CustomizeReport from './CustomizeReport/CustomizeReport';

const FloorReport = () => {
  const [reportName, setReportName] = useState<any>(null);
  const [showCustomizeReport, setShowCustomizeReport] = useState(false);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [customizationType, setCustomizationType] = useState('user');

  const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
  const isAppliedDone = useAppSelector((state) => state.customizeReport.isAppliedDone);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const viewFloorReport = () => {
    const reportParameters = {
      reportParameters: {
        customizationType: customizationType
      },
      reportType: "Floor Report"
    };
    dispatch(createReportPreviewSession(reportParameters));
  };

  useEffect(() => {
    viewFloorReport();

    return (() => {
      dispatch(setReportPreviewSession(""));
    });
  }, []);

  useEffect(() => {
    if (isAppliedDone) viewFloorReport();
  }, [isAppliedDone]);

  useEffect(() => {
    if (reportPreviewSession) {
      setReportName(reportPreviewSession.previewSessionId);
    }
  }, [reportPreviewSession]);

  useEffect(() => {
		if (isAppliedDone) {
			dispatch(setIsAppliedDone(false));
			setShowCustomizeReport(false);
			setMessage("Report settings saved");
			setDisplayInfoModal(true);
		}
	}, [isAppliedDone]);


  const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: { 
        customizationType: customizationType
      },
			exportType: "Floor Report"
		};
		dispatch(onExportReport(reportParameters, "Floors"));
	};

  return (

    <div className='site-reports'>
      <section className="d-flex align-items-center">
        <Breadcrumb
          title="Reports"
          classNames="report-header"
          handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
        />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
          <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
          <span>Floor Report</span>
        </div>
      </section>

      <div className="report-sort-field pt-2 pb-2">
        <div className="report-export-main d-flex">
          <div className="row px-2"></div>
          <div className="report-buttons-main d-flex">
            <div className="report-buttons">
              <ListGroup horizontal>
                <ListGroup.Item>
                  <Button label="Customize" className="report-btn" onClick={() => setShowCustomizeReport(true)} />
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className='export-report-buttons'>
              <ListGroup horizontal>
                <span className='expor-title'>Export to</span>
                <ListGroup.Item>
                  <Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
                </ListGroup.Item> */}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>

      <ReportViewer reportId={reportName} />

      <InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={message}
			/>
      {showCustomizeReport &&
				<CustomizeReport
					reportType="FLOOR_REPORT"
					showModal={showCustomizeReport}
					onHideModal={() => setShowCustomizeReport(false)}
          customizationType={customizationType}
					setCustomizationType={setCustomizationType} />
			}
    </div>

  )
}

export default FloorReport;