import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useAppSelector } from "redux/hooks";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    addLegend: (data: any) => void;
    selectedEquipment: any;
    commonEquipmentList: any;
    cancelAction?: () => void,
}
type FormData = {
    searchtext: any;
};
export const TextSearch: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    addLegend = () => { },
    cancelAction = () => { },
    selectedEquipment = [],
    commonEquipmentList = []
}) => {

    const {
        handleSubmit,
    } = useForm<FormData>({
        defaultValues: {
            searchtext: null,
        },
    });
    const addForm: any = useRef();
    const equipmentTypeList = useAppSelector((state) => state.inspectionDrawing.equipmentTypeList);
    const renderFooter = () => {
        return (
            <>
                <Button label="OK" onClick={() => { cancelAction(); setShowModal(false) }} className="cancel btn" />
                <Button label="Add Legend" className="update btn" autoFocus onClick={addLegend} />
            </>
        );
    };

    const onError = (errors: any, e: any) => { };
    let selectedEquipments: any = [];
    equipmentTypeList?.map((data: any, index: any) => {
        let selectedCount: any = 1;
        let totalCount: any = 0;
        let selected: any = "";
        commonEquipmentList.data?.map((datas: any) => {
            if (datas["Equipment Type"] == data.text) {
                totalCount++;
            }
        })
        selectedEquipment[0]?.map((equipment: any) => {
            if (data.text == equipment["Equipment Type"]) {
                selected = { equipmentType: data.text, selectedCount: selectedCount++, totalCount: totalCount };
            }
        });
        if (selected != "") {
            selectedEquipments.push(selected);
        }
    });

    return (
        <>
            <Dialog
                header="Totalize"
                visible={showModal}
                style={{ width: "30vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings selectsite"
            >
                <form
                    onSubmit={handleSubmit(onError)}
                    ref={addForm}
                >

                    <div className="field col-12 md:col-4 mt-2 pt-3">
                        <span className="p-float-label">
                            <b> Equipment: Selected (Floor Total)</b> <br></br> <br></br>
                            {selectedEquipments.map((item: any) => <><b> {item.equipmentType}  : {item.selectedCount} ( {item.totalCount} )</b><br></br> </>)} <br></br>
                            <b>Total No. of Equipment : {selectedEquipment[0]?.length} ( {commonEquipmentList.data?.length} ) </b>

                        </span>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default TextSearch;
