import React, { memo, useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useAppSelector } from "redux/hooks";

import InspectionSummary from "./TabList/InspectionSummary/InspectionSummary";
import PerformaceIndicator from "./TabList/PerformanceIndicator/PerformanceIndicator";
import { showUI } from './../../service/GeneralUtility';
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";

interface PropType {}
const Dashboard: React.FC<PropType> = (props: any) => {

	const [defaultSite, setDefaultSite] = useState<any>(null);

	const navigate = useNavigate();
	const { state } = useLocation() as any;
    const from = state?.from || 0; 

	const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
	const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

	const onTabChange = (index: any) => {
		navigate(`/${APP_ROUTES.DASHBOARD}`, {
			state: {
				from: index
			}
		})

	}

	useEffect(() => {
		if (loggedInUserData) {
			if (loggedInUserData.userDefaults && loggedInUserData.userDefaults.uniqueSiteId) {
				setDefaultSite(loggedInUserData.userDefaults);
			} else {
				setDefaultSite(null);
			}
		}
	}, [loggedInUserData]);

	return (
		<div className="dashboard-container">
			<TabView activeIndex={from} onTabChange={(indexChangeEvent)=>{onTabChange(indexChangeEvent.index)}}>
				<TabPanel header="Inspection Summary" className="inspection-summary-tab">
					<InspectionSummary defaultSite={defaultSite} />
				</TabPanel>
				 {showUI(userPrivileges,"SIETAP_DASHBOARD_KEY_PERFORMANCE_INDICATOR") &&
					<TabPanel header="Key Performance Indicator" className="performance-indicator-tab">
						<PerformaceIndicator defaultSite={defaultSite} />
					</TabPanel>
				}
			</TabView>
		</div>
	);
};

export default memo(Dashboard);
