import React, { forwardRef, memo, useEffect, useRef,useState} from "react";
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import { ContextMenu } from 'primereact/contextmenu';
import { ProgressSpinner } from 'primereact/progressspinner';
import {Layers} from "pages/AsBuilts/ManageDrawings/MangeDialogModel/Layers"
import { Popover } from "react-bootstrap";

interface IDrawing {
	cadPlanId: any;
	openHandler: Function | any;
	bIsResizeDrawing: boolean;	
	revisionNo:any;
	drawingId:any;
	equipmentData?:any;
}
var objiWhizApi: any;
var areaRatio: any;


const Drawing = forwardRef<any, IDrawing>((props, ref) => {
	const bIsSpace = false;

	const contextMenuItems = [		
		{
			label: "De-Hatch",
			command: () => onDehatchClick()
		},
		{
		   label:'Zoom',
		   items:[
			  {
				 label:'In',
				 command: () => onZoomInClick()
			  },
			  {
				 label:'Out',
				 command: () => onZoomOutClick()
			  },
			  {
				 label: 'Window',
				 command: () => onZoomWindowClick()
			  },
			  {
				 label:'Extents',
				 command: () => onZoomExtentsClick()
			  }
		   ]
		},
		{
			label: "Pan",
			command: () => onPanClick()
		},
		{
			label: "Layers",
			command: () =>addLayers((result:any)=>{})
		}
	 ];

	const contextMenuRef: any = useRef<any>(null);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] =useState(false);
	const [drawingLoaded, setDrawingLoaded] = useState(false);
	const [layerLookUpArray,setLayerLookUpArray] = useState<any>([]);
	const [showLayers, setShowLayers] = useState(false);
	const [currentToolTipHandler, setCurrentToolTipHandler] = useState<any>();
	const [showEquipmentTooltip, setShowEquipmentTooltip] = useState(false);
	const [currentEquipmentData, setCurrentEquipmentData] = useState<any>();
	

	const rowDelimiter = '§';
	const columnDelimiter='µ';
	
	useEffect(() => {
		objiWhizApi = new (window as any).iWhizAPI();
		const height = document.getElementById("drawingCanvas")?.offsetHeight;
		const width = document.getElementById("drawingCanvas")?.offsetWidth;
		const topOffset = height != undefined ? window.innerHeight - height : 0;

		if (height != undefined && width != undefined) {
			objiWhizApi.initWebViewer("#drawingCanvas", width, height, topOffset, function (retCode: any) {
				if (retCode == 0) {
					initEvents();
					objiWhizApi.openDrawing(props.cadPlanId, "255,255,255", "",props.revisionNo,props.drawingId, function (retCode: any) {
						if (retCode == 0 || retCode == 75 || retCode == 74) {
							setDrawingLoaded(true);
							initialSettings(function () {
								props.openHandler && props.openHandler(objiWhizApi);
								objiWhizApi.zoomExtents(function () {
									var areaRatioTemp = [0];
									objiWhizApi.clientDWGAreaRatio(areaRatioTemp);
									areaRatio = areaRatioTemp[0];
								});
							});
						} else if (retCode === 2) {
							setMessage("Drawing file not found");
							setDisplayInfoModal(true);
						}
					});
				}
			});
		}

		return () => {
			if(objiWhizApi)
			objiWhizApi.close(function () {});
		};
	}, [props.cadPlanId]);

	
	useEffect(() => {
		if(objiWhizApi)
		{
			const height = document.getElementById("drawingCanvas")?.offsetHeight;
			const width = document.getElementById("drawingCanvas")?.offsetWidth;
			objiWhizApi.resize(width, height);
		}
	},[props.bIsResizeDrawing]);

	

	const onZoomInClick = () => {
		objiWhizApi.zoomIn();
	}

	const onZoomOutClick = () => {
		objiWhizApi.zoomOut();
	}

	const onZoomWindowClick = () => {
		objiWhizApi.zoomWindow();
	}

	const onZoomExtentsClick = () => {
		objiWhizApi.zoomExtents(function(){});
	}

	const onPanClick = () => {
		objiWhizApi.pan();
	}
	const addLayers = (resCallback) => {
		objiWhizApi.getAllLayers(function (returnCode, layers) {
				let allLayersArray = [];
				let layerArray:any=[];
				allLayersArray = layers.split(rowDelimiter);
				allLayersArray.pop();
				allLayersArray.sort();
				for (let index = 0; allLayersArray.length > index; index++) {
					let isVisible = [0];
					let layerName = allLayersArray[index];
					let returnCode = objiWhizApi.getLayerVisibility(layerName, isVisible)
					if (returnCode != 0) {
					}
					if (isVisible[0]) {
					layerArray.push({ id: index, siteName: layerName,isVisible:true,checked:true,disabled:false,selected:false });
					}
					else
					layerArray.push({ id: index, siteName: layerName,isVisible:false,checked:false,disabled:false,selected:false });
					
				}
				setLayerLookUpArray(layerArray)
				resCallback(0);
			});
		
	}
	useEffect(() => {
		if(layerLookUpArray.length>0)
		{
			setShowLayers(true);
		}
	},[layerLookUpArray]);
	const onDehatchClick = () => {
		objiWhizApi.deleteLayer("$HatchBlockRef", function (retCode) { //de-hatch functionality
            objiWhizApi.deleteLayer("$Measure", function (retCode) {//remove measure area/measure distance Layer
                objiWhizApi.removeBlinkers();//remove blinkers
            });
        });
	}
	const layersSubmit = (data: any) => {
		if(data&& data.length>0){
			let layerStatus="";
			
		for (let item of data) {
			
            if (item.isVisible) {
                layerStatus += item.layerName + columnDelimiter + "true" +rowDelimiter;
               
            }
            else
                layerStatus += item.layerName +columnDelimiter + "false" + rowDelimiter;

        }
        objiWhizApi.setCursor(0);
		objiWhizApi.setLayersVisibility(layerStatus, function () {
			objiWhizApi.display(function () {
                objiWhizApi.setCursor(1);
            });
        });
       setShowLayers(false);
	}  
    }			 
	const initEvents = () => {
		objiWhizApi.addEvent("tooltipHandler", function (outputs: any) {
			console.log("tooltipHandler" + outputs[0]);
			setShowEquipmentTooltip(false); 
			setCurrentToolTipHandler(outputs);
		});
			objiWhizApi.addEvent("mouseMove", function (outputs: any) {
				console.log("mouseMove" + outputs[0]);
				setShowEquipmentTooltip(false); 

			});
		objiWhizApi.addEvent("mouseDown", function (outputs: any) {
			console.log("mouseDown " + outputs[0]);
			setShowEquipmentTooltip(false)
				
			if (outputs[2] == 2) {
				//right click
				contextMenuRef.current.show(outputs[3]);
			} else if (outputs[2] == 0) {
				//left click
			}
		});
		objiWhizApi.addEvent("mouseDoubleClick", function (outputs: any) {
			console.log("mouseDoubleClick " + outputs[0]);
			setShowEquipmentTooltip(false)
				
		});
	};
	const initialSettings = (callBack: any) => {
		objiWhizApi.initialLoadSettings(6, bIsSpace, function (retCode: any) {
			callBack();
		});
	};

	useEffect(() => {
		if (currentToolTipHandler && props.equipmentData) {
			for(let i=0;i<props.equipmentData.length;i++){
				if(props.equipmentData[i].BlockRefHandle===currentToolTipHandler[0]){
					setShowEquipmentTooltip(true); 
					setCurrentEquipmentData(props.equipmentData[i])
					return;
				}
				else{
					setShowEquipmentTooltip(false)
				}
			}
		}
	}, [props.equipmentData, currentToolTipHandler]);

	useEffect(() => {
		if (showEquipmentTooltip === true) {
			$("#drawingTooltip").css({ left: currentToolTipHandler[1], top: currentToolTipHandler[2] });
		}
	}, [showEquipmentTooltip]);
	

	return (
	<>
		
			<div className="drawing-area" id="drawing-area">
				<ContextMenu model={contextMenuItems} ref={contextMenuRef}></ContextMenu>
				<canvas id="drawingCanvas" onContextMenu={(e) => e.preventDefault()} />
				{!drawingLoaded ? (
					<div className="drawing-spinner">
						<ProgressSpinner strokeWidth="3" />
					</div>
				)
					: null}
				{showEquipmentTooltip && currentEquipmentData && <Popover show={showEquipmentTooltip} id="drawingTooltip" className="drawing-tooltip" >
					<div className="tooltip-area">
						<h6>Address : <span>{currentEquipmentData?.Address}</span></h6>
						<h6>Barcode : <span>{currentEquipmentData?.Barcode}</span></h6>
						<h6>Building : <span>{currentEquipmentData?.Building}</span></h6>
						<h6>Comment : <span>{currentEquipmentData?.Comment}</span></h6>
						<h6>Dev Reading : <span>{currentEquipmentData?.["Dev Reading"]}</span></h6>
						<h6>Dev Reading Date : <span>{currentEquipmentData?.["Dev Reading Date"]}</span></h6>
						<h6>DPM : <span>{currentEquipmentData?.DPM}</span></h6>
						<h6>Factory Setting : <span>{currentEquipmentData?.["Factory Setting"]}</span></h6>
						<h6>Floor : <span>{currentEquipmentData?.Floor}</span></h6>
						<h6>Health Classification : <span>{currentEquipmentData?.["Health Classification"]}</span></h6>
						<h6>Location : <span>{currentEquipmentData?.Location}</span></h6>
						<h6>Make/Model : <span>{currentEquipmentData?.["Make/Model"]}</span></h6>
						<h6>NFPA Classification : <span>{currentEquipmentData?.DPM}</span></h6>
						<h6>Note : <span>{currentEquipmentData?.Note}</span></h6>
						<h6>Safety Values : <span>{currentEquipmentData?.["Safety Values"]}</span></h6>
						<h6>Test Date : <span>{currentEquipmentData?.["Test Date"]}</span></h6>
						<h6>Test Method : <span>{currentEquipmentData?.["Test Method"]}</span></h6>
						<h6>Test Result : <span>{currentEquipmentData?.["Test Result"]}</span></h6>
						<h6>XML Type : <span>{currentEquipmentData?.["XML Type"]}</span></h6>
						<h6>XML Usage : <span>{currentEquipmentData?.["XML Usage"]}</span></h6>
					</div>

				</Popover>}
			</div>
		<Layers setShowModal={setShowLayers} showModal={showLayers} selectedRows={layerLookUpArray} layersSubmit={layersSubmit}></Layers>
		<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal}  message={message} />		
	</>	
	);
});

export default memo(Drawing);
