import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    inspectionTask: any
    onQuitTask: (data: any) => void;
}

export const QuitTask: React.FC<Props> = ({ 
    showModal = false, 
    setShowModal = () => {}, 
    selectedRows = [],
    inspectionTask = {},  
    onQuitTask = () => {}
}) => {

    const quitTaskForm: any = useRef();
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    
    const {
        handleSubmit,
    } = useForm<any>({});


    const formSubmitHandler = (data: any) => {       
        quitTaskForm.current.requestSubmit();
    };

    const displayConfirmationModal = () => {
        setDisplayConfirmModal(true);
    }

    const onQuitTaskFormSubmit = () => {
        if (inspectionTask && inspectionTask.inspectionTaskId) {
            let taskData = {
                uniqueUserId: selectedRows[0]?.uniqueUserId, 
                inspectionTaskId: inspectionTask.inspectionTaskId
            }
            onQuitTask(taskData);
        }
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() => setShowModal(false)} className="cancel btn" />
                <Button label="Quit Task" className="update btn" autoFocus onClick={formSubmitHandler} />
            </>
        );
    };

    const onError = (errors: any, e: any) => {};

    return (
        <>
            <Dialog
                header="Task Details "
                visible={showModal}
                style={{ width: "40vw" }}
                onHide={() => setShowModal(false)}
                footer={inspectionTask?.inspectionTaskId ? renderFooter() : <></>}
                className="display-settings personal selectsite"
            >
                {(inspectionTask && inspectionTask.inspectionTaskId) ?
                    <form onSubmit={handleSubmit(displayConfirmationModal, onError)} ref={quitTaskForm}>
                        <div className="task-details-wrp field ">
                            <div className="text-container">
                                <div className="name">Site </div>
                                <div className="text-value"> { inspectionTask?.siteName } </div>
                            </div>

                            <div className="text-container">
                                <div className="name">Customer Name </div>
                                <span className="text-value"> { inspectionTask?.customerName } </span>
                            </div>

                            <div className="text-container">
                                <div className="name">Contract Name </div>
                                <span className="text-value"> { inspectionTask?.contractName } </span>
                            </div>

                            <div className="text-container">
                                <div className="name">Task Name </div>
                                <span className="text-value"> { inspectionTask?.inspectionTaskName } </span>
                            </div>
                        </div>
                    </form>
                    : 
                    <label>
                        Selected User is not assigned to any active Inspection Task
                    </label>
                }
            </Dialog>

            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onQuitTaskFormSubmit}
                message={
                    (selectedRows.length && selectedRows[0]?.userName) ? 
                    `Unsynched data from Tap app will be lost. Are you sure want to quit technician (${selectedRows[0]?.userName})?`
                    :
                    `Unsynched data from Tap app will be lost. Are you sure want to quit technician?`
                }
            />
        </>
    );
};

export default QuitTask;
