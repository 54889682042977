import { InputText } from "primereact/inputtext";
import React, { memo, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
//icons
import { BsExclamationCircleFill } from "react-icons/bs";
//components
import AddModal from "components/Table/AddModal/AddModal";
//redux
import { useDispatch } from "react-redux";
import { getFieldTypes, getInputTypes, setFieldTypes } from "redux/pages/Manage/InspectionSettings/InspectionFields/InspectionFieldSlice";
import { useAppSelector } from "redux/hooks";
import { blockedNonKeyboardCharacters } from "service/GeneralUtility";

interface AddInspectionFieldPropType {
    showModal: boolean;
    selectedNode?: any;
    parentItem?: any;
    onHideModal?: () => void;
    onSubmitInspection?: (body) => void;
    type?: 'CONTRACT' | 'SYSTEM' | 'QUESTION';
}
const AddInspectionField: React.FC<AddInspectionFieldPropType> = (props) => {
    const { selectedNode, showModal, parentItem, onHideModal = () => { }, onSubmitInspection = () => { }, type } = props;

    const dispatch = useDispatch<any>();
    const inputTypes = useAppSelector((state) => state.InspectionField.inputTypes);
    const fieldTypes = useAppSelector((state) => state.InspectionField.fieldTypes);

    const addForm = useRef<any>();
    const [selectedType, setSelectedType] = useState("dataItem");
    const [selectedAlignment, setSelectedAlignment] = useState("vertical");
    const [typesOfInput, setTypesOfInput] = useState({ inputType: "", fieldType: "" });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            dataItemName: "",
            inputType: "",
            FieldType: "",
            datasetName: "",
            datafieldName: "",
            isMandatory:false,
            isForwardData: false,
            isClearDefaultData: false,
            maxCharacters: "",
            isValidated: false,
            dataGroupName: "",
        },
    });

    useEffect(() => {
        dispatch(getInputTypes());
    }, []);

    useEffect(() => {
        onDataReset();
    }, [selectedType]);

    const onDataReset = () => {
        reset();
        setSelectedAlignment("vertical");
        setTypesOfInput({ inputType: "", fieldType: "" });
    };

    const onInputTypeChanged = (e) => {
        dispatch(setFieldTypes([]));
        dispatch(getFieldTypes(e.value));
        setTypesOfInput((prev) => ({ ...prev, ["inputType"]: e.originalEvent.target.ariaLabel }));
        if ([5, 6].includes(e.value))
            setValue("isValidated", true);
    };

    const onFieldChanged = (e) => {
        setTypesOfInput((prev) => ({ ...prev, ["fieldType"]: e.originalEvent.target.ariaLabel }));
    };

    const formSubmitHandler = () => {
        addForm.current.requestSubmit();
    };

    const textValidate = (value) => {
        if (value) {
            let invalidCharacters: any = blockedNonKeyboardCharacters(value);
            if (invalidCharacters != null && invalidCharacters.length > 0) {
                return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
            } else {
                return true;
            }
        }
    }

    const onSubmit = (data) => {
        const body = {
            "dataFieldName": data?.datafieldName?.trim(),
            "parentGroupId": parentItem?.uniqueDataFieldId,
            "uniqueDataFieldId": null,
            "datasetName": data?.datasetName ? data?.datasetName?.trim() : "",
            "fieldTypeId": data?.FieldType ? data?.FieldType : 0,
            "forwardData": data?.isForwardData,
            "isMandatory":data?.isMandatory,
            "clearDefaultData":data?.isClearDefaultData,
            "nodeId": selectedNode?.key,
            "inputTypeId": data?.inputType ? data?.inputType : 0,
            "isHorizontal": selectedAlignment === "horizontal" ? true : false,
            "isSection": selectedType === "dataGroup" ? true : false,
            "isVertical": selectedAlignment === "vertical" ? true : false,
            "isValidated": data?.isValidated,
            "isValidMultiple": (typesOfInput?.inputType === "Check Box(s)" && data?.isValidated) ? true : false,
            "isValidSingle": typesOfInput?.inputType === "Radio Buttons" ? true : false,
            "maxChars": data?.maxCharacters ? data?.maxCharacters : 0,           
            "Name": selectedType === "dataItem" ? data?.dataItemName?.trim() : data?.dataGroupName?.trim(),
            "nodeName": selectedNode?.label,
        }
        onSubmitInspection(body);

    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    return (
        <AddModal
            header={"Add Inspection Field"}
            showModal={showModal}
            setShowModal={onHideModal}
            OnSaveClick={formSubmitHandler}
            isAdd={true}
            style={{ width: "38vw", maxHeight: "100%" }}
        >

            <div className="field-header">
                <span>
                    <div className="field-radiobutton">
                        <RadioButton
                            value="dataItem"
                            inputId="dataItem"
                            name="Data Item"
                            onChange={(e) => setSelectedType(e.value)}
                            checked={selectedType === "dataItem"}
                        />
                        <label htmlFor="dataItem">Data Item</label>
                    </div>
                </span>
                <span>
                    <div className="field-radiobutton">
                        <RadioButton
                            value="dataGroup"
                            name="Data Group"
                            onChange={(e) => setSelectedType(e.value)}
                            checked={selectedType === "dataGroup"}
                        />
                        <label htmlFor="dataGroup">Data Group</label>
                    </div>
                </span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid" ref={addForm}>
                {selectedType === "dataItem" ? (
                    <>
                        <div className="field col-12 md:col-4 pt-0">
                            <span className="p-float-label">
                                <Controller
                                    name="dataItemName"
                                    control={control}
                                    rules={{
                                        required: "Enter Name",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="dataItemName"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            {...field}
                                            maxLength={1000}
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dataItemName">
                                    Name
                                </label>
                                {getFormErrorMessage("dataItemName")}
                            </span>
                        </div>
                        <div className="field col-12 md:col-4 pt-0">
                            <span className="p-float-label">
                                <Controller
                                    name="inputType"
                                    control={control}
                                    rules={{ required: "Select an Input Type" }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="inputType"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                onInputTypeChanged(e);
                                            }}
                                            options={inputTypes}
                                            optionLabel="inputTypeName"
                                            optionValue="inputTypeId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="inputType">
                                    Input Type
                                </label>
                                {getFormErrorMessage("inputType")}
                            </span>
                        </div>
                        {typesOfInput.inputType === "Check Box(s)" && watch("isValidated") === false ? (
                            <div className="field col-12 md:col-4 ">
                                <span className="p-float-label">
                                    <label>Single Line Text</label>
                                </span>
                            </div>
                        ) : (
                            <div className="field col-12 md:col-4 pt-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="FieldType"
                                        control={control}
                                        rules={{ required: "Select a Field Type" }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="FieldType"
                                                className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                    onFieldChanged(e);
                                                }}
                                                options={fieldTypes}
                                                optionLabel="fieldTypeName"
                                                optionValue="fieldTypeId"
                                            />
                                        )}
                                    />
                                    <label className="mandatory" htmlFor="FieldType">
                                        Field Type
                                    </label>
                                    {getFormErrorMessage("FieldType")}
                                </span>
                            </div>
                        )}

                        {((typesOfInput.inputType === "Text Area") || ((typesOfInput.inputType === "Text Box") && (typesOfInput.fieldType === "Single Line Text"))) && (
                            <div className="field col-12 md:col-4 pt-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="maxCharacters"
                                        control={control}
                                        rules={{
                                            required: "Enter Max Characters Allowed",
                                            pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: "Enter a valid Number" },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id="maxCharacters"
                                                className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <label className="mandatory" htmlFor="maxCharacters">
                                        Max Characters
                                    </label>
                                    {getFormErrorMessage("maxCharacters")}
                                </span>
                            </div>
                        )}
                        {typesOfInput.inputType === "Radio Buttons" || typesOfInput.inputType === "Check Box(s)" ? (
                            <>
                                <div className="field col-12 md:col-4 pt-0">
                                    <div className="checkbox-section d-flex align-items-center test 1">
                                        <div className="field-checkbox mb-0">
                                            <span>
                                                <Controller
                                                    name="isValidated"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <Checkbox
                                                            disabled={typesOfInput.inputType === "Radio Buttons" || typesOfInput.inputType === "Check Box(s)" ? true : false}
                                                            inputId="isValidated"
                                                            checked={field.value}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                                <label htmlFor="isValidated">Validated</label>
                                            </span>
                                        </div>

                                        <div className="field-radiobutton mb-0">
                                            <span>
                                                <RadioButton
                                                    disabled
                                                    value="single"
                                                    inputId="single"
                                                    name="Single"
                                                    checked={typesOfInput.inputType === "Radio Buttons"}
                                                />
                                                <label htmlFor="single">Single</label>
                                            </span>
                                        </div>

                                        <div className="field-radiobutton mb-0">
                                            <span>
                                                <RadioButton
                                                    disabled
                                                    value="multiple"
                                                    inputId="multiple"
                                                    name="Multiple"
                                                    checked={typesOfInput.inputType === "Check Box(s)" && watch("isValidated") === true}
                                                />
                                                <label htmlFor="multiple">Multiple</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {watch("isValidated") && (
                                    <div className="field col-12 md:col-4 pt-0">
                                        <div className="checkbox-section d-flex align-items-center">
                                            <div className="field-radiobutton mb-0">
                                                <span>
                                                    <RadioButton
                                                        value="horizontal"
                                                        inputId="horizontal"
                                                        name="Horizontal"
                                                        onChange={(e) => setSelectedAlignment(e.value)}
                                                        checked={selectedAlignment === "horizontal"}
                                                    />
                                                    <label htmlFor="horizontal">Horizontal</label>
                                                </span>
                                            </div>

                                            <div className="field-radiobutton mb-0">
                                                <span>
                                                    <RadioButton
                                                        value="vertical"
                                                        inputId="vertical"
                                                        name="Vertical"
                                                        onChange={(e) => setSelectedAlignment(e.value)}
                                                        checked={selectedAlignment === "vertical"}
                                                    />
                                                    <label htmlFor="vertical">Vertical</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : null}
                        <div className="field col-12 md:col-4 pt-0">
                            <span className="p-float-label">
                                <Controller
                                    name="datasetName"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                        validate: (value) => {
                                            return ((value.trim().length <= 0) ? "This field is required." : textValidate(value))
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="datasetName"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            {...field}
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="datasetName">
                                    Dataset Name
                                </label>
                                {getFormErrorMessage("datasetName")}
                            </span>
                        </div>
                        <div className="field col-12 md:col-4 pt-0">
                            <span className="p-float-label">
                                <Controller
                                    name="datafieldName"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="datafieldName"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            {...field}
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="datafieldName">
                                    Datafield Name
                                </label>
                                {getFormErrorMessage("datafieldName")}
                            </span>
                        </div>
                        
                        <div className="field-checkbox">
                            <div className="field-checkbox pl-2">
                                <Controller
                                    name="isMandatory"
                                    control={control}
                                    render={({ field, fieldState }) => <Checkbox inputId="isMandatory" checked={field.value} {...field} />}
                                />
                                <label htmlFor="isMandatory">Mandatory</label>
                            </div>
                        </div>

                        {type === 'QUESTION' &&
                            (
                                <div className="field col-12 md:col-4 pt-0">
                                    <div className="checkbox-section d-flex align-items-center pt-2">
                                        <div className="field-checkbox">
                                            <Controller
                                                name="isForwardData"
                                                control={control}
                                                render={({ field, fieldState }) => <Checkbox inputId="isForwardData" checked={field.value} {...field} />}
                                            />
                                            <label htmlFor="isForwardData">Forward Data to New Task</label>
                                        </div>

                                        <div className="field-checkbox pl-2">
                                            <Controller
                                                name="isClearDefaultData"
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <Checkbox inputId="isClearDefaultData" checked={field.value} {...field} />
                                                )}
                                            />
                                            <label htmlFor="isClearDefaultData">Clear Default Data</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <>
                        <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                                <Controller
                                    name="dataGroupName"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                        validate: (value) => {
                                            return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                        },
                                        maxLength: {
                                            value : 250,
                                            message : "Maximum 250 characters allowed"
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="dataGroupName"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            {...field}
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dataGroupName">
                                    Name
                                </label>
                                {getFormErrorMessage("dataGroupName")}
                            </span>
                        </div>
                        {type === 'QUESTION' &&
                            (
                                <div className="field col-12 md:col-4 pt-0">
                                    <div className="radio-section d-flex align-items-center pt-2">
                                        <div className="field-radiobutton mb-0">
                                            <span className="field-checkbox mb-0">
                                                <Controller
                                                    name="isClearDefaultData"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <Checkbox inputId="isClearDefaultData" checked={field.value} {...field} />
                                                    )}
                                                />
                                                <label htmlFor="isClearDefaultData">Clear Default Data</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )}
            </form>
        </AddModal>
    );
};

export default memo(AddInspectionField);
