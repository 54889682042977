import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getEquipmentTypeList, getMasterDataAttachmentList, getMasterdataList, getSystemPanelList,setEquipmentPanelList,setMasterDataList} from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";

import { useForm, Controller } from "react-hook-form";

import DataTableComponent from "components/Table/DataTable";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

import { getMasterDataListGridColumns } from "./utility";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { BsPaperclip } from "react-icons/bs";
import { FaChevronDown, FaCog } from "react-icons/fa";
import { OverlayPanel } from "primereact/overlaypanel";
import AttachmentList from "./Attachments/AttachmentList";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
}

type FormData = {
    panelId: any;
    uniqueEquipmentClassId: any;
};

export const MasterData: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => {},
    selectedRows = [],
}) => {

    const dispatch = useDispatch<any>();
    const panels = useAppSelector((state) => state.equipment.equipmentPanelList);
    const equipmentTypes = useAppSelector((state) => state.equipment.equipmentTypeList);
    const masterDataList = useAppSelector((state) => state.equipment.masterDataList);
    const masterDataPageDetails = useAppSelector((state) => state.equipment.masterDataPageDetails);
    
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [gridData, setGridData] = useState<any>([]);
    const [columns, setColumns] = useState<any>([]);
    const settingRef = React.createRef<OverlayPanel>();
    const [selectedPanel, setSelectedPanel] = useState('');
    const [selectedEquipment, setSelectedEquipment] = useState('All');
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [pageDetails, setPageDetails] = useState<any>();
    const [pageIndex, setPageIndex] = useState(0);
    const [currentStart, setCurrentStart] = useState(1);
    const [showAttachmentListModal, setShowAttachmentListModal] = useState(false);
    const isPanel = useRef<any>(true);

    const {
        control,
        setValue,
    } = useForm<FormData>({
    defaultValues: {
        panelId: '',
        uniqueEquipmentClassId: 'All'
    },
});

    useEffect(() => {
        if (selectedRows && selectedRows.length) {
            dispatch(getSystemPanelList(selectedRows[0].uniqueSystemId));
            dispatch(getEquipmentTypeList());         
        }
    }, [selectedRows]);
   
    useEffect(() => {
        if(panels && panels.length){
            setValue('panelId', panels[0].equipmentId)
            setSelectedPanel(panels[0].equipmentId)
        }
        if(!isPanel.current && panels.length === 0){     
            setGridData([]);
            setMessage("No Equipment Group data exists");
            setDisplayInfoModal(true);
        }
        return()=>{
            isPanel.current = false;
        };
    }, [panels]);

    useEffect(() => {
        let equipment = (selectedEquipment === 'All') ? null : selectedEquipment;
        if (selectedPanel) {
            dispatch(getMasterdataList(selectedRows[0].uniqueSystemId,
                { UniquePanelId: selectedPanel, UniqueEquipmentClassId: equipment },
                setMessage,
                setDisplayInfoModal,
                currentStart,
                sortField,
                sortOrder
            ));
        }
    }, [selectedPanel, selectedEquipment])

    useEffect(() => {
        if (masterDataList) {
            const column = getMasterDataListGridColumns(masterDataList?.generalProperties);
            setColumns([...column]);
            setGridData([...masterDataList?.data]);
            setPageDetails(masterDataList?.pageDetails);
        }
    }, [masterDataList]);   
    
    const onPageChanged = (options: any) => {
        if (options !== pageIndex) {
            let equipment = (selectedEquipment === 'All') ? null : selectedEquipment;
            let currentStart = options * masterDataPageDetails?.currentLength + 1;
            setCurrentStart(currentStart)
            setPageIndex(options);
            dispatch(getMasterdataList(selectedRows[0].uniqueSystemId, 
                {UniquePanelId: selectedPanel, UniqueEquipmentClassId: equipment}, 
                setMessage, 
                setDisplayInfoModal,
                currentStart,
                sortField, 
                sortOrder
            ));
        }
      };

    const onSort = (event) => {
        let equipment = (selectedEquipment === 'All') ? null : selectedEquipment;
        let sortDirection: string;
        if (event.sortOrder === 1) 
            sortDirection = "ASC"
        else
            sortDirection = "DESC";            
            setSortField(event.sortField)
            setSortOrder(sortDirection)
            dispatch(getMasterdataList(selectedRows[0].uniqueSystemId, 
            {UniquePanelId: selectedPanel, UniqueEquipmentClassId: equipment}, 
            setMessage, 
            setDisplayInfoModal,
            currentStart,
            event?.sortField, 
            sortDirection
        ));
    }

    const handleRowSelect = useCallback((data: any) => {
        setSelectedItems([...data]);
    }, []);

    const showAttachmentHandler=()=>{
        if (selectedItems.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
          } else if (selectedItems.length < 1) {
            setMessage("Select a equipment");
            setDisplayInfoModal(true);
          } else {
            dispatch(getMasterDataAttachmentList(selectedRows[0]?.uniqueSystemId,selectedItems[0]?.EquipmentId,setMessage,setDisplayInfoModal,setShowAttachmentListModal))            
          }
    }

    const onCloseMasterData=()=>{
        dispatch(setEquipmentPanelList(""));
        dispatch(setMasterDataList(""));
        setSelectedPanel("");
        setGridData([]);
        setShowModal(false);
    }

    return (
        <>
            <Dialog
                showHeader={false}
                visible={showModal}
                onHide={()=> setShowModal(false)}
                className="master-data"
            >
                <div className="custom-header">
                    <div className="row">
                        <div className="col-6 box py-1 pr-0 pl-1">
                            <div className="title">System Master Equipment Data</div>
                        </div>

                        <div className="col-6 box py-1 pl-0 align-items-end pr-1">
                            <i className="pi pi-times" onClick= {() => onCloseMasterData()}></i>
                        </div>
                    </div>
                </div>

                <div className="container-fluid ">
                    <div className="second-row row">
                        <div className="select-box pb-0 pt-0 col-5">
                            <div className="action-section row custom-float-field mb-0">
                                <div className="col-6 field mb-0">
                                    <span className="p-float-label">
                                        <Controller
                                            name="panelId"
                                            control={control}
                                            rules={{ required: "System Type is required." }}
                                            render={({ field, fieldState }) =>(
                                                <Dropdown
                                                    inputId="equipmentId" 
                                                    className={classNames("w-100 error-tooltip")}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {field.onChange(e.value);setSelectedPanel(e.value)}}
                                                    options={panels}
                                                    optionLabel="name"
                                                    optionValue="equipmentId"                                            
                                                />
                                            )}
                                        />
                                        <label className="mandatory" htmlFor="dropdown">
                                            Equipment Group
                                        </label>
                                    </span>
                                </div>
                                <div className="col-6 field mb-0">
                                    <span className="p-float-label">
                                        <Controller
                                            name="uniqueEquipmentClassId"
                                            control={control}
                                            rules={{ required: "System Type is required." }}
                                            render={({ field, fieldState }) => (
                                                <Dropdown
                                                    inputId="uniqueEquipmentClassId" 
                                                    className={classNames("w-100 error-tooltip")}
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e) => {field.onChange(e.value);setSelectedEquipment(e.value)}}
                                                    options={equipmentTypes}
                                                    optionLabel="equipmentClassName"
                                                    optionValue="uniqueEquipmentClassId"
                                                />
                                            )}
                                        />
                                        <label className="mandatory" htmlFor="dropdown">
                                            Equipment Type
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="icon-wrapper col-7">
                            <div className=" table-config">
                                <div className="action-btns">
                                    <Button className="button-icon" tooltip="Attachments" tooltipOptions={{ position: "top" }} onClick={showAttachmentHandler} disabled={gridData && gridData?.length > 0 ? false : true}>
                                        <BsPaperclip className="icon" />
                                    </Button>
                                    <Button className="button-icon" tooltip="More" tooltipOptions={{ position: "top" }}
                                        onClick={(e) => settingRef.current?.toggle(e)}
                                    >
									    <FaCog className="icon" />
									    <FaChevronDown className="p-button-text caret-icon" />
								</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DataTableComponent
                    rows={gridData}
                    cols={columns}
                    paginator={true}
                    dataKeyId="EquipmentId"
                    title={``}
                    pageDetails={pageDetails}
                    pageIndex={pageIndex}
                    onPageChanged={onPageChanged}
                    showCustomSearchCmp
                    getSelectedRowData={handleRowSelect}
                    settingRef={settingRef}
                    settingListItems={[]}
                    onSortData={onSort}

                />
            </Dialog>

            {showAttachmentListModal ? (
                <AttachmentList
                    showModal={showAttachmentListModal}
                    setShowModal={setShowAttachmentListModal}
                    selectedRows={selectedRows}
                    selectedItems={selectedItems}
                />
            ) : null}

            <InformationBox 
                showInfoModal={displayInfoModal} 
                setShowInfoModal={setDisplayInfoModal} 
                message={message} 
            />
        </>
    );
};

export default MasterData;
