import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "react-bootstrap";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";

//components
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//utility
import { APP_ROUTES } from "constants/AppRoutes";
//icons
import { BsArrowRight } from "react-icons/bs";
//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { onDataEntrySettingsUpdate, onGetDataEntrySettings, onGetNfpaClassifications, setUpdatedDataEntrySettings } from "redux/pages/Manage/Customization/CustomizationSlice";
import { getEquipmentTypeList } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { getCountryList } from "redux/pages/Administration/administrationSlice";

interface UserData {
    CustomHeaderCmp?: any;
    showCustomHeader?: boolean;
    showCountries?: boolean;
}

const ManageUserDataSettings: React.FC<UserData> = (props) => {
    const { CustomHeaderCmp = null, showCustomHeader = false, } = props;
    const navigate = useNavigate();

    const [selectedFields, setSelectedFields] = useState({ countryId:"", equipmentTypeId: "", classificationId:""});
    const [datafields, setDatafields] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [countryLookup, setCountryLookup] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>("");

    const dispatch = useDispatch<any>();
    const equipmentSpecificLists = useAppSelector((state) => state.equipment.equipmentSpecificLists);
    const nfpaClassifications = useAppSelector((state) => state.customization.nfpaClassifications);
    const dataEntrySettings = useAppSelector((state) => state.customization.dataEntrySettings);
    const updatedDataEntrySettings = useAppSelector((state) => state.customization.updatedDataEntrySettings);
    const countryList = useAppSelector((state) => state.administration.countryList);

    useEffect(() => {
        dispatch(getEquipmentTypeList(false));
    }, [dispatch,countryList]);

    useEffect(() => {
        dispatch(getCountryList());
      }, [dispatch]);

    useEffect(() => {
        if (countryList && countryList.countries.length > 0 && countryList.countries !== undefined) {
          let country: any = [];
          countryList.countries.map((countryItem: any, key: any) => {
            country.push({ "code": countryItem.countryId, "name": countryItem.countryName.trim() })
          });
          setSelectedFields({ countryId:country[0].code, equipmentTypeId: "",classificationId:"" })
          setCountryLookup(country);
          setSelectedCountry(country[0]);
        }
      }, [countryList]);

    useEffect(() => {
        if (equipmentSpecificLists) {
            const equipmentClass=equipmentSpecificLists?.find((item) => item?.equipmentClassName === "Devices");
            setSelectedFields((prev) => ({ ...prev, ["equipmentTypeId"]: equipmentClass?.uniqueEquipmentClassId }))
        }
    }, [equipmentSpecificLists]);

    useEffect(() => {
        if (nfpaClassifications) {
            setSelectedFields((prev) => ({ ...prev, ["classificationId"]: nfpaClassifications[0]?.uniqueNFPAClassificationId }))
        }
    }, [nfpaClassifications])

    useEffect(() => {
        if (selectedFields?.equipmentTypeId && selectedFields?.countryId) {
            dispatch(onGetNfpaClassifications(selectedFields?.countryId, selectedFields?.equipmentTypeId));
            dispatch(onGetDataEntrySettings(selectedFields?.countryId, selectedFields?.equipmentTypeId,0))
        }
    }, [selectedFields?.equipmentTypeId])

    useEffect(() => {
        if (selectedFields?.classificationId && selectedFields?.equipmentTypeId && selectedFields?.countryId) {
            dispatch(onGetDataEntrySettings(selectedFields?.countryId, selectedFields?.equipmentTypeId, selectedFields?.classificationId))
        }
    }, [selectedFields?.classificationId])

    useEffect(() => {
        if (selectedFields?.countryId && selectedFields?.equipmentTypeId && selectedFields?.classificationId ) {
            dispatch(onGetDataEntrySettings(selectedFields?.countryId, selectedFields?.equipmentTypeId,selectedFields?.classificationId))
        }
    }, [selectedFields?.countryId])

    useEffect(() => {
        if (dataEntrySettings?.length) {
            let convData = JSON.parse(JSON.stringify(dataEntrySettings));
            setDatafields(convData);
        }
        else
        {
            setDatafields([]);
        }
    }, [dataEntrySettings])

    useEffect(() => {
        if (updatedDataEntrySettings) {
            setDisplayInfoModal(true);
            setMessage("Optional Fields updated");
            dispatch(setUpdatedDataEntrySettings(""));
        }
    }, [updatedDataEntrySettings])

    const onCountryChange = (data:any) => {
        setSelectedFields((prev) => ({ ...prev, ["countryId"]: data.code }))
        if (selectedFields?.equipmentTypeId) {
            dispatch(onGetNfpaClassifications(data.code, selectedFields?.equipmentTypeId));
            dispatch(onGetDataEntrySettings(data.code, selectedFields?.equipmentTypeId,0));
        }
    }

    const onDataEntryCheckBoxSelected = (e, details) => {
        datafields[details.rowIndex].isSelectedInDataEntry = e.checked;
        setDatafields([...datafields]);
    }

    const onTapAppCheckBoxSelected = (e, details) => {
        datafields[details.rowIndex].isSelectedInTapApp = e.checked;
        setDatafields([...datafields]);
    }

    const onUpdate = () => {
        if (!datafields?.length) {
            return;
        }
        const removedDataSets = datafields?.map(({ isDisabledInDataEntry, isDisabledInTapApp, position, dataFieldName, ...rest }: any) => {
            return rest;
        });
        const body = {
            dataEntryFields: removedDataSets
        }
        dispatch(onDataEntrySettingsUpdate(selectedFields?.countryId,
            selectedFields?.equipmentTypeId,
            selectedFields?.classificationId === "0" ? null : selectedFields?.classificationId,
            body));
    }

    const onGetAllChecked = (type) => {
        let checked = false;
        if (type === "DATA_ENTRY") {
            const filterItem = datafields?.filter((item) => item.isSelectedInDataEntry);
            if (filterItem?.length === datafields?.length) checked = true;
            else checked = false;
        } else if ("TAP_APP") {
            const filterItem = datafields?.filter((item) => item.isSelectedInTapApp);
            if (filterItem?.length === datafields?.length) checked = true;
            else checked = false;
        }
        return checked
    }

    const onHeaderCheckBoxChanged = (e, type) => {
        if (type === "DATA_ENTRY") {
            datafields.forEach(element => {
                if (!element?.isDisabledInDataEntry) {
                    element.isSelectedInDataEntry = e.checked;
                }
            });
        } else if ("TAP_APP") {
            datafields.forEach(element => {
                if (!element?.isDisabledInTapApp) {
                    element.isSelectedInTapApp = e.checked;
                }
            });
        }
        setDatafields([...datafields])
    }

    const dataEntryTemplate = (rowData: any, columnDetails: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <Checkbox
                    className="check-box mr-2"
                    id={rowData.uniqueDataFieldId}
                    checked={rowData.isSelectedInDataEntry}
                    disabled={rowData.isDisabledInDataEntry}
                    onChange={(e) => onDataEntryCheckBoxSelected(e, columnDetails)}
                />
            </div>
        );
    };

    const tapAppTemplate = (rowData: any, columnDetails: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <Checkbox
                    className="check-box mr-2"
                    id={rowData.uniqueDataFieldId}
                    checked={rowData.isSelectedInTapApp}
                    disabled={rowData.isDisabledInTapApp}
                    onChange={(e) => onTapAppCheckBoxSelected(e, columnDetails)}
                />
            </div>
        );
    };

    const fieldNameTemplate = (rowData: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <div>{rowData.dataFieldName}</div>
            </div>
        );
    };

    const dataEntryHeaderTemplate = () => {

        return (
            <div>
                <Checkbox
                    inputId="dataEntryHead"
                    className="check-box mr-2"
                    id="dataEntryHead"
                    checked={onGetAllChecked("DATA_ENTRY")}
                    onChange={(e) => onHeaderCheckBoxChanged(e, "DATA_ENTRY")}
                />
                <label className="mandatory" htmlFor="dataEntryHead">
                    Show in Data Entry
                </label>
            </div>
        )
    }

    const tapAppHeaderTemplate = () => {

        return (
            <div>
                <Checkbox
                    inputId="dataEntryHead"
                    className="check-box mr-2"
                    id="dataEntryHead"
                    checked={onGetAllChecked("TAP_APP")}
                    onChange={(e) => onHeaderCheckBoxChanged(e, "TAP_APP")}
                />
                <label className="mandatory" htmlFor="dataEntryHead">
                    Show in TAP App
                </label>
            </div>
        )
    }

    return (
        <div className="manage_display_setting">
            <section className="d-flex align-items-center">
                <Breadcrumb title="Customization" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>Data Entry Settings</span>
                </div>
            </section>
            <div className="manage-display-table">
                <div className="custom-table">
                    {showCustomHeader ? <CustomHeaderCmp /> : <div className="heading">Data Entry Settings</div>}
                    <div className="search-section d-flex align-items-center pt-2 pb-2">
                    <div className="attribute-dropdown d-flex">
                            <span className="p-float-label mr-3">
                            <Dropdown
                                    inputId="country"
                                    className={classNames("w-100")}
                                    value={selectedCountry}
                                    onChange={(e) =>{  setSelectedCountry(e.value);onCountryChange(e.value)}}
                                    options={countryLookup}
                                     optionLabel="name" 
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Country
                                </label>
                            </span>
                        </div>
                        <div className="attribute-dropdown d-flex">
                            <span className="p-float-label mr-3">
                                <Dropdown
                                    inputId="leadTech"
                                    className={classNames("w-100")}
                                    value={selectedFields?.equipmentTypeId}
                                    onChange={(e) => setSelectedFields((prev) => ({ ...prev, ["equipmentTypeId"]: e.value, ["classificationId"]: "0" }))}
                                    options={equipmentSpecificLists}
                                    optionLabel="equipmentClassName"
                                    optionValue="uniqueEquipmentClassId"
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Equipment Type
                                </label>
                            </span>
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="leadTech"
                                    className={classNames("w-100")}
                                    value={selectedFields?.classificationId}
                                    onChange={(e) => setSelectedFields((prev) => ({ ...prev, ["classificationId"]: e.value }))}
                                    options={nfpaClassifications}
                                    optionLabel="nfpaClassificationName"
                                    optionValue="uniqueNFPAClassificationId"
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    NFPA Classification
                                </label>
                            </span>
                        </div>
                    </div>
                    <DataTable dataKey="id" value={datafields}>
                        <Column header="Field Name" body={fieldNameTemplate} />
                        <Column header={dataEntryHeaderTemplate} body={dataEntryTemplate} />
                        <Column header={tapAppHeaderTemplate} body={tapAppTemplate} />
                    </DataTable>
                </div>
                <div className=" p-dialog-footer d-flex ">
                    <Button disabled={datafields?.length ? false : true} className="save-btn mr-0" onClick={onUpdate}> Save  Changes</Button>
                </div>
            </div>
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
        </div>
    );
};

export default ManageUserDataSettings;
