import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    selectedDrawing: any;
    selectedBuilding: any;
    onRevisionEdit: (data: any) => void;
}

type FormData = {
    description: string;
};

export const EditListRevisions: React.FC<Props> = ({ 
    showModal = false,
    setShowModal = () => {}, 
    selectedRows = [], 
    selectedDrawing = [],
    selectedBuilding = [] , 
    onRevisionEdit = () => {}, 
}) => {
    const editForm: any = useRef();
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            description: "",
        },
    });

    useEffect(() => {
        if (selectedRows &&selectedRows.length) {
            setValue("description", selectedRows[0]?.description)
        }
    }, [selectedRows])


    const formSubmitHandler = (data: any) => {
        editForm.current.requestSubmit();
    };

    const onEditFormSubmit = (data: any) => {
        let payload = {
            description: data.description.trim(),
            RevisionNo: selectedRows[0]?.revisions
        }
        onRevisionEdit && onRevisionEdit(payload);
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" className="cancel btn" autoFocus onClick={() => setShowModal(false)}/>
                <Button label="Save Changes" className="Upload btn" autoFocus onClick={formSubmitHandler}/>
            </>
        );
    };

    const onError = (errors: any, e: any) => {};

    return (
        <>
            <Dialog
                header="Edit Description"
                visible={showModal}
                style={{ width: "32vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className={`s-modal`}
            >
            <form
                    onSubmit={handleSubmit(onEditFormSubmit, onError)}
                    ref={editForm}
                >
                <div className="row">
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Site</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedDrawing[0]?.siteName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label>Building</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedDrawing[0]?.buildingName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Floor</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedDrawing[0]?.floorName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">File Name</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedDrawing[0]?.dwgFileName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Category</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedDrawing[0]?.category}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">	Drawing Type</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedDrawing[0]?.type}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Revision No.</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{selectedRows[0]?.revisions}</span>
                            </span>
                        </div>
                    </div>
                    <div className="field col-12">
                        <span className="p-float-label">
                        <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: 'Description is required.',
                                    validate: (value) => {
                                        return value.trim().length <= 0
                                            ? "Description is required." : true
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <InputTextarea
                                        id="Textarea"
                                        className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                        maxLength={4000}
                                        {...field}
                                    />
                                )}
                            />
                            <label className='mandatory' htmlFor="inputtext">Description</label>
                            {getFormErrorMessage("description")}
                        </span>
                        <span className="textarea-txt-count">Max 4000 Chars</span>
                    </div>
                </div>
                </form>
            </Dialog>
        </>
    );
};

export default EditListRevisions;