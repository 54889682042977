import React, { useEffect, useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import ReportViewer from "components/common/Report/ReportViewer";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import CustomizeReport from "pages/Reports/CustomizeReport/CustomizeReport";
import { setIsAppliedDone } from 'redux/common/report/customizeReportSlice';
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    activeSiteId: any;
    reportFlag: string;
    selectedRows: any;
    sortByColumns: any;
    searchKeyWord: string;
}

type FormData = {
    sortBy: string;
};

export const SiteReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    activeSiteId = '',
    reportFlag = '',
    selectedRows = [],
    sortByColumns = [],
    searchKeyWord = '',
}) => {

    const {
        control,
    } = useForm<FormData>({
        defaultValues: {
            sortBy: "",
        },
    });

    const [sortByLookup, setSortByLookup] = useState([{ field: "", header: "" }]);
    const [sortColumn, setSortColumn] = useState<any>("");
    const [reportId, setReportId] = useState<any>(null);
    const [showCustomizeReport, setShowCustomizeReport] = useState(false);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [customizationType, setCustomizationType] = useState('user');
    
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const isAppliedDone = useAppSelector((state) => state.customizeReport.isAppliedDone);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (sortByColumns?.length) {
            let sortBy: { field: string; header: string }[] = [];
            sortByColumns?.map((item: any) => {
                if(item.field === 'siteNumber' || item.field === 'siteName' || item.field === 'status' || item.field === 'buildingCount') {
                    return sortBy.push({ field: item.field, header: item.header });
                }
            });
            setSortByLookup(sortBy);
        }
    }, [sortByColumns]);

    const getSelectedSitesId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueSiteId);
        });
        return params;
    };

    const viewSiteGridReport = () => {
        let params;
        if (reportFlag === 'all') {
            params = ""
        } else {
            params = getSelectedSitesId();
        }
        const reportParameters = {
            reportParameters: {
                uniqueSiteId: params,
                search: searchKeyWord,
                sortColumn: sortColumn,
                customizationType: customizationType
            },
            reportType: "SiteGridReport"
        };
        dispatch(createReportPreviewSession(reportParameters));
    };

    useEffect(() => {
        viewSiteGridReport();

        return (() => {
            dispatch(setReportPreviewSession(""));
        })
    }, [sortColumn]);

    useEffect(() => {
        if (isAppliedDone) viewSiteGridReport();
    }, [isAppliedDone]);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    useEffect(() => {
        if (isAppliedDone) {
            dispatch(setIsAppliedDone(false));
            setShowCustomizeReport(false);
            setMessage("Report settings saved");
            setDisplayInfoModal(true);
        }
    }, [isAppliedDone]);

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                <div className="report-field">
                        <div className="row px-2">
                            <div className="field col-12 md:col-2 mb-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="sortBy"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="dropdown"
                                                className={classNames("w-100 error-tooltip", {
                                                    error: fieldState.invalid,
                                                })}
                                                {...field}
                                                value={sortColumn}
                                                onChange={(e) => {
                                                    setSortColumn(e.value);
                                                }}
                                                options={sortByLookup}
                                                optionLabel="header"
                                                optionValue="field"
                                            />
                                        )}
                                    />
                                    <label htmlFor="dropdown">Sort by</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="report-buttons-main d-flex">
                        <div className="report-buttons">
                            <ListGroup horizontal>
                                <ListGroup.Item><Button label="Customize" className="report-btn" onClick={() => setShowCustomizeReport(true)} /></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>

                <ReportViewer reportId={reportId} />
                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                />
                {showCustomizeReport &&
                    <CustomizeReport
                        reportType="Sites"
                        showModal={showCustomizeReport}
                        onHideModal={() => setShowCustomizeReport(false)}
                        customizationType={customizationType}
                        setCustomizationType={setCustomizationType} />
                }
            </Dialog>
        </>
    );
};

export default SiteReport;
