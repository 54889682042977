import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import ListGroup from "react-bootstrap/ListGroup";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  pendingReportNames: any;
  message?:string;
}

type FormData = {};

export const PendingReports: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  pendingReportNames = [],
  message = "",
}) => {
  const addUserForm: any = useRef();

  const {
  } = useForm<FormData>({
    defaultValues: {},
  });

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
      </>
    );
  };

  return (
    <>
      <Dialog
        header="Reports pending to an Inspection Task"
        visible={showModal}
        className="display-settings personal report-pending"
        style={{ width: "32vw" }}
        footer={renderFooter()}
        onHide={() => setShowModal(false)}
      >
        <div className="header">
          <span className="text-sm">{message}.{" "}</span>
        </div>
        <form
          ref={addUserForm}
        >
          <div className="row px-2 pt-3">
            <div className="field col-12 md:col-2 mb-0 p-0">
              <div className="reports-pending-title">
                <span>Inspection Report Name</span>
              </div>
              <ScrollPanel style={{ width: "100%", height: "180px" }}>
                <div className="report-pending-list">
                  <ListGroup>
                    {pendingReportNames.length > 0 &&
                      pendingReportNames.map((item) => {
                        return <ListGroup.Item>{item}</ListGroup.Item>;
                      })}
                  </ListGroup>
                </div>
              </ScrollPanel>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default PendingReports;
